/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 20 August 2020 : Aman
    * M-0002982: Users list under User Management should have ID and Spyder ID column.
	M002 : 21 January 2020 : Nick
		* M-0003236: Update wordings in user management
**************************************************************************************************************
*/

/* eslint-disable import/prefer-default-export */
import React from 'react';
import { NavDropdown, MenuItem } from 'react-bootstrap';

import { getStandardDateTime } from '../../../utils/dateTime';

export const websiteUserLabels = (showConfirmWindow,showSpyderImportWindow) => [
  //====================M001=====[====================
  {
    width: '5%',
    key: 'iTempUser_ID',
    label: 'ID',
  },
  //====================M001=====[====================
  {
    width: '10%',
    key: 'sFull_Name',
    label: 'Name',
  },
  {
    width: '15%',
    key: 'sEmail',
    label: 'Email',
    render: (value, obj) => (
      <>
        <a href={`mailto:${String(value).toLowerCase()}`}>{String(value).toLowerCase()}</a>
      </>
    ),
    sortable: false,
  },
  {
    width: '10%',
    key: 'iTempUser_ID',
    label: 'Status',
    render: (value, obj) => {
      if (obj.bVerifiedEmail==0){
        return (<p className="admin-table-user-status">Email Not Verified</p>)
      } else if (obj.bActivated==0) {
        return (<p className="admin-table-user-status">Pending Activate</p>)
      } else {
        return (<p className="admin-table-user-status">Activated</p>)
      }
    },
  },
  {
    width: '8%',
    key: 'tLoginRecord_Time',
    label: 'Last Login',
    render: (value, obj) => <p>{obj.tLoingRecord_Time && getStandardDateTime(obj.tLoingRecord_Time)}</p>,
    sortable: true,
  },
  {
    width: '8%',
    key: 'tCreatedAt',
    label: 'Registered On',
    render: (value, obj) => <p>{obj.tCreatedAt && getStandardDateTime(obj.tCreatedAt)}</p>,
    sortable: true,
  },
  {
    width: '5%',
    render: (value, obj) => (
        <NavDropdown
          open={true}
          title={"Actions"}
          className={"admin-table-dropdown"}
        >
          <MenuItem onClick={()=>{showSpyderImportWindow(obj)}}>Import to Spyder</MenuItem>
          <MenuItem onClick={()=>{
            showConfirmWindow(`An email will be sent to ${obj.sEmail} with the updated password.`, obj, 'sendNewPassword')
          }}>
            Send New Password
          </MenuItem>
          <MenuItem 
            disabled={obj.bVerifiedEmail?1:0}
            onClick={()=>{
              return (
              obj.bVerifiedEmail?null:
                showConfirmWindow(`An email for verification will be sent to ${obj.sEmail}.`, obj, 'sendVerifyEmail'))}}>
              Re-Send Verify Email
          </MenuItem>
          <MenuItem 
          disabled={obj.bVerifiedEmail?0:1}
          onClick={()=>{
            return (
              obj.bVerifiedEmail ? showConfirmWindow(`${obj.sEmail} account will be ${obj.bActivated==0?'activated':'deactivated'}.`, obj, 'changeUserStatus'): null
              )
          }}>
              {obj.bActivated==0?'Activate':'Deactivated'}
          </MenuItem>
        </NavDropdown>
      ),
    sortable: false,
  },
];

export const spyderUserLabels = (showConfirmWindow) => [
  //====================M001=====[====================
  {
    width: '5%',
    key: 'iPSN_Id',
    label: 'ID',
  },
  //====================M001=====[====================
  {
    width: '10%',
    key: 'sPFN_FullName',
    label: 'Name',
  },
  {
    width: '10%',
    key: 'sCompanyName',
    label: 'Root Company',
  },
  {
    width: '15%',
    key: 'sNCY_FullNo',
    label: 'Email',
    render: (value, obj) => (
      <>
        <a href={`mailto:${String(value).toLowerCase()}`}>{String(value).toLowerCase()}</a>
      </>
    ),
    sortable: false,
  },
  {
    width: '10%',
    key: 'iTempUser_ID',
    label: 'Status',
    render: (value, obj) => {
      if (obj.bPassword==0){
        return (<p className="admin-table-user-status">No Password</p>)
      } else {
        return (<p className="admin-table-user-status">Activated</p>)
      }
    },
  },
  {
    width: '8%',
    key: 'tLoginRecord_Time',
    label: 'Last Login',
    render: (value, obj) => <p>{obj.tLoingRecord_Time && getStandardDateTime(obj.tLoingRecord_Time)}</p>,
    sortable: true,
  },
  {
    width: '5%',
    render: (value, obj) => (
        <NavDropdown
          open={true}
          title={"Actions"}
          className={"admin-table-dropdown"}
        >
          <MenuItem 
            onClick={()=>{
              showConfirmWindow(`An email will be sent to ${obj.sNCY_FullNo} with the updated password.`, obj, 'sendNewPassword')
            }}
          >
              Send New Password
          </MenuItem>
        </NavDropdown>
      ),
    sortable: false,
  },
];