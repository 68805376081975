const initialState = {
  success: false,
  loading: false,
  editProfileModal: false,
  error: null,
  ourPeople: {
    loading: false,
    data: [],
    error: null,
  },
  analyst: {
    loading: false,
    data: null,
    error: null,
  },
  analysts: {
    loading: false,
    data: [],
    error: null,
  },
  payload: {},
};

export default (state = initialState, { type, error, ourPeople, data, obj }) => {
  switch (type) {
    case 'GET_ANALYSTS':
      return {
        ...state,
        analysts: {
          ...state.analysts,
          loading: true,
        },
      };

    case 'GET_ANALYSTS_SUCCESS':
      return {
        ...state,
        analysts: {
          ...state.analysts,
          data,
          loading: false,
        },
      };

    case 'GET_ANALYSTS_FAIL':
      return {
        ...state,
        analysts: {
          ...state.analysts,
          error,
          loading: true,
        },
      };

    case 'GET_ANALYST':
      return {
        ...state,
        analyst: {
          ...state.analyst,
          loading: true,
        },
      };

    case 'GET_ANALYST_SUCCESS':
      return {
        ...state,
        analyst: {
          ...state.analyst,
          data,
          loading: false,
        },
      };

    case 'GET_ANALYST_FAIL':
      return {
        ...state,
        analyst: {
          ...state.analyst,
          error,
          loading: false,
        },
      };

    case 'GET_OUR_PEOPLE':
      return {
        ...state,
        ourPeople: {
          ...state.ourPeople,
          loading: true,
        },
      };

    case 'GET_OUR_PEOPLE_SUCCESS':
      return {
        ...state,
        ourPeople: {
          ...state.ourPeople,
          loading: false,
          data: ourPeople,
        },
      };

    case 'GET_OUR_PEOPLE_FAIL':
      return {
        ...state,
        ourPeople: {
          ...state.ourPeople,
          loading: false,
          error,
        },
      };

    case 'UPDATE_ANALYST':
      return {
        ...state,
        ourPeople: {
          ...state.ourPeople,
          loading: true,
        },
      };
    case 'UPDATE_ANALYST_SUCCESS':
      return {
        ...state,
        ourPeople: {
          ...state.ourPeople,
          data: state.ourPeople.data.map(item =>
            item.id === obj.id
              ? {
                  ...item,
                  ...obj,
                }
              : item
          ),
          loading: false,
          success: true,
        },
        editProfileModal: false,
      };

    case 'UPDATE_ANALYST_FAIL':
      return {
        ...state,
        ourPeople: {
          ...state.ourPeople,
          error,
          loading: false,
          success: false,
        },
        editProfileModal: false,
      };
    case 'SHOW_EDIT_PROFILE_MODAL':
      return {
        ...state,
        editProfileModal: true,
      };
    case 'HIDE_EDIT_PROFILE_MODAL':
      return {
        ...state,
        editProfileModal: false,
      };
    case 'UPDATE_OUR_TEAM':
      return {
        ...state,
        ourPeople: {
          ...state.ourPeople,
        },
      };
    case 'UPDATE_OUR_TEAM_SUCCESS':
      return {
        ...state,
        ourPeople: {
          ...state.ourPeople,
          data: state.ourPeople.data.map(item =>
            item.id === obj.id
              ? {
                  ...item,
                  ...obj,
                }
              : item
          ),
        },
      };

    case 'UPDATE_OUR_TEAM_FAIL':
      return {
        ...state,
        ourPeople: {
          ...state.ourPeople,
          error,
        },
      };
    default:
      return state;
  }
};
