/*
**************************************************************************************************************
            ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
            Amendments
========================================================================
  M001 : 03 04 2023 : Zeeshan
    * M-0004354: Clicking the X on a previously used search term under the Research search does not clear it
**************************************************************************************************************
*/

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Box from '../../../components/Box';
import FilterBox from '../../../components/FilterBox';
import TagsInput from '../../../components/TagsInput';

import * as analystActions from '../../../actionCreators/Analyst';
import * as countryActions from '../../../actionCreators/Country';
import * as researchActions from '../../../actionCreators/Research';
import * as sectorActions from '../../../actionCreators/Sector';

import { filterKeys } from '../config';
import { debounce } from 'lodash';

const Component = ({
  analysts,
  countries,
  sectors,
  filters,
  data,
  onChange,
  getSuggestions,
  getSuggestionsSuccess,
  suggestions,
  getAnalysts,
  getCountries,
  getSectors,
  extraTags,
  applyFilters,
  tagsclicked,
}) => {
  const [tags, setTags] = React.useState(extraTags);
  const [Htags, setHtags] = React.useState(tagsclicked);
  let showSuggestion = false;
  if (suggestions && suggestions.data && Object.keys(suggestions.data).length) {
    const keys = Object.keys(suggestions.data);

    for (let i = 0, len = keys.length; i < len; i += 1) {
      if (suggestions.data[keys[i]].length) {
        showSuggestion = true;
        break;
      }
    }
  }

  React.useEffect(() => {
    getAnalysts();
    getCountries();
    getSectors();
  }, [getAnalysts, getCountries, getSectors]);

  React.useEffect(() => {
    let data = sessionStorage.getItem('TagsInput');
    if (data) {
      let dataArray = data.split(',');
      if (dataArray[0] != '') {
        setTags(dataArray);
      }
    }
  }, [sessionStorage.getItem('TagsInput')]);

  const debouncedGetSuggestions = debounce(q => {
    getSuggestions({ q });
  }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)

  // Attach debouncedGetSuggestions to the onKeyPress event
  const handleKeyPress = q => {
    debouncedGetSuggestions(q);
  };

  // useEffect to clear the debounce timer when the component unmounts
  React.useEffect(() => {
    return () => {
      debouncedGetSuggestions.cancel();
    };
  }, []);

  React.useEffect(() => {
    console.log('data ===>', data);
    if (data) {
      if (data?.dStart && data?.dEnd) {
        const index = data.q.findIndex(item => item == '');
        if (index > -1) {
          data.q[index] = `Date Range: ${data?.dStart} - ${data?.dEnd}`;
        }
        console.log('empty index', index);
        setTags(data.q);
      }
    }
  }, [data, filters]);

  return (
    <Box className="filter-box-section">
      {/* Filter Options Box */}
      <FilterBox
        loading={analysts.loading || countries.loading || sectors.loading}
        onChange={val => {
          const updatedTags = [
            // exclude already inserted keys
            ...tags.filter(item => {
              if (item.includes(':')) {
                const label = item.split(':')[0];
                const filterLabel = filterKeys.find(item2 => item2.label === label);

                if (filterLabel) {
                  return false;
                }
              }

              return true;
            }),
            ...Object.keys(val).map(key => {
              const filterKey = filterKeys.find(item => item.key === key);
              return `${(filterKey && filterKey.label) || key}: ${val[key]}`;
            }),
          ];
          setTags(updatedTags);

          onChange({ q: updatedTags });
        }}
        filters={filters.map(filter =>
          Object.keys(data).find(k => k === filter.key)
            ? {
                ...filter,
                value: data[filter.key],
                data:
                  (filter.key === 'analyst' && analysts.data) ||
                  (filter.key === 'country' && countries.data) ||
                  (filter.key === 'sector' && sectors.data) ||
                  [],
              }
            : {
                ...filter,
                data:
                  (filter.key === 'analyst' && analysts.data) ||
                  (filter.key === 'country' && countries.data) ||
                  (filter.key === 'sector' && sectors.data) ||
                  [],
              }
        )}
      />
      {/* Filter Options Box */}

      {/* Tags Input Box */}
      <TagsInput
        value={tags.concat(Htags.filter(item => tags.indexOf(item) < 0))}
        onKeyPress={handleKeyPress}
        onChange={val => {
          setTags(val);
          onChange({ q: val });
        }}
        suggestionsList={suggestions.data}
        onSelect={val => {
          // const updatedTags = [...tags, val];
          // getSuggestionsSuccess();
          // setTags(updatedTags);
          // onChange({ q: updatedTags });
        }}
        onSelectFullValue={(val, onClick) => {
          const updatedTags = [...tags, val];

          getSuggestionsSuccess();
          setTags(updatedTags);
          onChange({ q: updatedTags, onClick });
        }}
        showSuggestion={showSuggestion}
        onRemoveTag={val => {
          if (Htags.length && Htags.includes(val)) {
            // const updatedTags = Htags;
            const indexOfTag = Htags.indexOf(val);
            Htags.splice(indexOfTag, 1); //M001
          } else if (tags.length && tags.includes(val)) {
            // const updatedTags = tags;
            const indexOfTag = tags.indexOf(val);
            tags.splice(indexOfTag, 1); //M001
          }
          let updatedTags = [];
          updatedTags = tags.concat(Htags.filter(item => tags.indexOf(item) < 0));
          if (updatedTags.length) {
            onChange({ q: updatedTags, onClick: true });
          } else {
            onChange({ q: undefined, onClick: true });
          }
          setTags([...updatedTags]); //M001
          applyFilters(val && val.split(':')[0]);
        }}
      />
      {/* Tags Input Box */}
    </Box>
  );
};
const mapStateToProps = ({
  analyst: { analysts },
  country: { countries },
  research: { suggestions },
  sector: { sectors },
}) => ({
  analysts,
  countries,
  sectors,
  suggestions,
});

const mapDispatchToProps = dispatch => ({
  getAnalysts: bindActionCreators(analystActions.getAll, dispatch),
  getCountries: bindActionCreators(countryActions.getAll, dispatch),
  getSectors: bindActionCreators(sectorActions.getAll, dispatch),
  getSuggestions: bindActionCreators(researchActions.getSuggestions, dispatch),
  getSuggestionsSuccess: bindActionCreators(researchActions.getSuggestionsSuccess, dispatch),
  applyFilters: bindActionCreators(researchActions.applyFilters, dispatch),
});

Component.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.object,
  extraTags: PropTypes.array,
  onChange: PropTypes.func,
  applyFilters: PropTypes.func,
  getSuggestions: PropTypes.func.isRequired,
  getSuggestionsSuccess: PropTypes.func.isRequired,
  suggestions: PropTypes.object,
  analysts: PropTypes.object.isRequired,
  countries: PropTypes.object.isRequired,
  sectors: PropTypes.object.isRequired,
  getAnalysts: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  getSectors: PropTypes.func.isRequired,
};

Component.defaultProps = {
  data: {},
  extraTags: [],
  onChange: () => {},
  suggestions: {},
  applyFilters: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
