/*
**************************************************************************************************************
										R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author:
	Date:
**************************************************************************************************************
											Usage Notes
==============================================================================================================

**************************************************************************************************************
											Amendments
==============================================================================================================
	M002 : 17 Oct 2022 : Nick Ng
        * M-4180: Mirgrate research portal to use public module api and deprecate Node.js
    M001 : 21 Sep 2022 : Nick Ng
        * M-3844: Allow video access without subscription checking
**************************************************************************************************************
*/

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AzureMP } from 'react-azure-mp';
import Box from '../../../components/Box';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as videoActions from '../../../actionCreators/Videos';
import { bindActionCreators } from 'redux';
import { Stack, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ConnectPlayer = ({
  LINK,
  setAzureMP,
  setCurrentTime,
  onPlayerLoaded,
  sConnectConfigJSON,
  subtitleHint,
}) => {
  useEffect(() => {
    window.addEventListener('message', event => {
      if (event.data.command === 'ping') {
        Array.from(document.querySelectorAll('iframe')).forEach(iframe =>
          iframe?.contentWindow?.postMessage({ command: 'pong' }, '*')
        );
      }
    });
  }, []);

  if (LINK.length < 1) {
    return <div></div>; //M001
  }
  //=========================M002=============[================
  let msg = '';
  if (LINK.msg) {
    msg = LINK.msg;
    return (
      <Box style={{ borderRadius: 5, textAlign: 'center' }}>
        <div
          dangerouslySetInnerHTML={{
            __html: msg,
          }}
        />
      </Box>
    );
  } else {
    // if (LINK.includes('http')) {
    return (
      <>
        <a href="/connect/videos">
          <Stack direction="row" alignItems="center" spacing={1} style={{ marginBottom: '10px' }}>
            <ArrowBackIcon />
            <Typography variant="body">Back to Replays</Typography>
          </Stack>
        </a>
        {sConnectConfigJSON.name == 'Azure' ? (
          <AzureMP
            options={{
              logo: { enabled: true },
              playbackSpeed: {
                enabled: true,
                speedLevels: [
                  { name: 'x2.0', value: 2.0 },
                  { name: 'x1.75', value: 1.75 },
                  { name: 'x1.5', value: 1.5 },
                  { name: 'x1.25', value: 1.25 },
                  { name: 'x1.0', value: 1.0 },
                  { name: 'x0.75', value: 0.75 },
                  { name: 'x0.5', value: 0.5 },
                ],
              },
            }}
            skin="amp-flush"
            src={[{ src: LINK, type: 'application/vnd.ms-sstr+xml' }]}
            onInstanceCreated={player => {
              setAzureMP(player);
              player.addEventListener('timeupdate', () => {
                setCurrentTime(player.currentTime());
              });
              player.addEventListener('loadeddata', () => {
                onPlayerLoaded(player);
              });
            }}
          />
        ) : (
          <>
            <iframe
              src={`https://showroom.one.blendvision.com/player?token=${LINK}`}
              width="800"
              height="450"
              allow="autoplay; encrypted-media; clipboard-write"
              frameborder="0"
              allowfullscreen
              title="iframe"
            />
            <div>
              <p style={{ textAlign: 'center' }}>{subtitleHint}</p>
            </div>
          </>
          // <PremiumPlayer source={source} />
        )}
        {/* <AzureMP
            options={{
              logo: { enabled: true },
              playbackSpeed: {
                enabled: true,
                speedLevels: [
                  { name: 'x2.0', value: 2.0 },
                  { name: 'x1.75', value: 1.75 },
                  { name: 'x1.5', value: 1.5 },
                  { name: 'x1.25', value: 1.25 },
                  { name: 'x1.0', value: 1.0 },
                  { name: 'x0.75', value: 0.75 },
                  { name: 'x0.5', value: 0.5 },
                ],
              },
            }}
            skin="amp-flush"
            src={[{ src: LINK, type: 'application/vnd.ms-sstr+xml' }]}
            onInstanceCreated={player => {
              setAzureMP(player);
              player.addEventListener('timeupdate', () => {
                setCurrentTime(player.currentTime());
              });
              player.addEventListener('loadeddata', () => {
                onPlayerLoaded(player);
              });
            }}
          /> */}
      </>
    );
    // }
  }
  //=========================M002=============]================
};

ConnectPlayer.propTypes = {
  LINK: PropTypes.string,
};

ConnectPlayer.defaultProps = {
  LINK: '',
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  setCurrentTime: bindActionCreators(videoActions.setCurrentVideoTime, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectPlayer);
