/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
  M001 : 24 November 2020 : Nick
    * M-0003042: Email verification and cookie to authenticate users for email based report links
  M002 : 3rd Feb 2021 : Aman
    * M-0003220: Allow users to save documents
**************************************************************************************************************
*/

export const getAll = obj => ({
  type: 'GET_ALL_RESEARCHES',
  obj,
});

export const getAllSuccess = (data, pagination, obj) => ({
  type: 'GET_ALL_RESEARCHES_SUCCESS',
  data,
  pagination,
  obj,
});

export const getAllFail = (error, obj) => ({
  type: 'GET_ALL_RESEARCHES_FAIL',
  error,
  obj,
});

export const getLatest = obj => ({
  type: 'GET_LATEST_RESEARCHES',
  obj,
});

export const getLatestSuccess = (data, pagination, obj) => ({
  type: 'GET_LATEST_RESEARCHES_SUCCESS',
  data,
  pagination,
  obj,
});

export const getLatestFail = (error, obj) => ({
  type: 'GET_LATEST_RESEARCHES_FAIL',
  error,
  obj,
});

export const getHomePageLatest = obj => ({
  type: 'GET_HOMEPAGE_LATEST_RESEARCHES',
  obj,
});

export const getHomePageLatestSuccess = (data, pagination, obj) => ({
  type: 'GET_HOMEPAGE_LATEST_RESEARCHES_SUCCESS',
  data,
  pagination,
  obj,
});

export const getHomePageLatestFail = (error, obj) => ({
  type: 'GET_HOMEPAGE_LATEST_RESEARCHES_FAIL',
  error,
  obj,
});

export const getRelatedReports = obj => ({
  type: 'GET_RELATED_RESEARCHES',
  obj,
});

export const getRelatedReportsSuccess = (data, pagination, obj) => ({
  type: 'GET_RELATED_RESEARCHES_SUCCESS',
  data,
  pagination,
  obj,
});

export const getRelatedReportsFail = (error, obj) => ({
  type: 'GET_RELATED_RESEARCHES_FAIL',
  error,
  obj,
});

export const getHighlighted = obj => ({
  type: 'GET_HIGHLIGHTED_RESEARCHES',
  obj,
});

export const getHighlightedSuccess = (data, pagination, obj) => ({
  type: 'GET_HIGHLIGHTED_RESEARCHES_SUCCESS',
  data,
  pagination,
  obj,
});

export const getHighlightedFail = (error, obj) => ({
  type: 'GET_HIGHLIGHTED_RESEARCHES_FAIL',
  error,
  obj,
});

export const getResearch = obj => ({
  type: 'GET_RESEARCH',
  obj,
});

export const getResearchSuccess = (payload, obj) => ({
  type: 'GET_RESEARCH_SUCCESS',
  payload,
  obj,
});

export const getResearchFail = (error, obj) => ({
  type: 'GET_RESEARCH_FAIL',
  error,
  obj,
});

export const highlightReport = obj => ({
  type: 'HIGHLIGHT_RESEARCH',
  obj,
});

export const highlightReportSuccess = (data, obj) => ({
  type: 'HIGHLIGHT_RESEARCH_SUCCESS',
  obj,
  data,
});

export const highlightReportFail = (error, obj) => ({
  type: 'HIGHLIGHT_RESEARCH_FAIL',
  error,
  obj,
});

export const tempReport = obj => ({
  type: 'TEMP_RESEARCH',
  obj,
});

export const tempReportSuccess = (data, obj) => ({
  type: 'TEMP_RESEARCH_SUCCESS',
  obj,
  data,
});

export const tempReportFail = (error, obj) => ({
  type: 'TEMP_RESEARCH_FAIL',
  error,
  obj,
});

export const getTempReports = obj => ({
  type: 'GET_TEMP_REPORTS',
  obj,
});

export const getTempReportsSuccess = (data, pagination, obj) => ({
  type: 'GET_TEMP_REPORTS_SUCCESS',
  data,
  pagination,
  obj,
});

export const getTempReportsFail = (error, obj) => ({
  type: 'GET_TEMP_REPORTS_FAIL',
  error,
  obj,
});

export const getSuggestions = obj => ({
  type: 'GET_SUGGESTIONS',
  obj,
});

export const getSuggestionsSuccess = data => ({
  type: 'SUGGESTIONS_SUCCESS',
  data,
});

export const getSuggestionsFailure = error => ({
  type: 'SUGGESTIONS_FAILURE',
  error,
});

export const getResearches = obj => ({
  type: 'GET_RESEARCHES',
  obj,
});

export const getResearchesSuccess = (payload, obj) => ({
  type: 'GET_RESEARCHES_SUCCESS',
  payload,
  obj,
});

export const getResearchesFail = (error, obj) => ({
  type: 'GET_RESEARCHES_FAIL',
  error,
  obj,
});

export const clear = () => ({
  type: 'CLEAR_RESEARCHES',
});

export const applyFilters = obj => ({
  type: 'APPLIED_FILTERS',
  obj,
});

export const clearFilters = () => ({
  type: 'CLEAR_APPLIED_FILTERS',
});

export const emptySuggestions = () => ({
  type: 'EMPTY_SUGGESTIONS',
});

export const fetchLatestDoc = obj => ({
  type: 'FETCH_LATEST_RESEARCHES',
  obj,
});

export const fetchLatestDocSuccess = (data, obj) => ({
  type: 'FETCH_LATEST_RESEARCHES_SUCCESS',
  data,
  obj,
});

export const fetchLatestDocFail = (error, obj) => ({
  type: 'FETCH_LATEST_RESEARCHES_FAIL',
  error,
  obj,
});
//====================M002=====]====================
export const SaveReport = obj => ({
  type: 'SAVE_REPORT',
  obj,
});

export const SaveReportSuccess = (data, obj) => ({
  type: 'SAVE_REPORT_SUCCESS',
  data,
  obj,
});

export const SaveReportFail = (error, obj) => ({
  type: 'SAVE_REPORT_FAILURE',
  error,
  obj,
});

export const GetSavedReports = obj => ({
  type: 'GET_SAVED_REPORTS',
  obj,
});

export const GetSavedReportsSuccess = (data, obj) => ({
  type: 'GET_SAVED_REPORTS_SUCCESS',
  data,
  obj,
});

export const GetSavedReportsFail = (error, obj) => ({
  type: 'GET_SAVED_REPORTS_FAILURE',
  error,
  obj,
});

export const GetBlogReports = obj => ({
  type: 'GET_BLOG_REPORTS',
  obj,
});

export const GetBlogReportsSuccess = (data, obj) => ({
  type: 'GET_BLOG_REPORTS_SUCCESS',
  data,
  obj,
});

export const GetBlogReportsFail = (error, obj) => ({
  type: 'GET_BLOG_REPORTS_FAILURE',
  error,
  obj,
});

export const GetMostViewedReports = obj => ({
  type: 'GET_MOST_VIEWED_REPORTS',
  obj,
});

export const GetMostViewedReportsSuccess = (data, obj) => ({
  type: 'GET_MOST_VIEWED_REPORTS_SUCCESS',
  data,
  obj,
});

export const GetMostViewedReportsFail = (error, obj) => ({
  type: 'GET_MOST_VIEWED_REPORTS_FAILURE',
  error,
  obj,
});
//====================M002=====]====================
//Get View Report Cookie
//====================M001=====]====================
export const getViewReportCookie = (obj, link) => ({
  type: 'GET_VIEWREPORTCOOKIE',
  obj,
  link,
});

export const getViewReportCookieSuccess = (data, obj) => ({
  type: 'GET_VIEWREPORTCOOKIE_SUCCESS',
  data,
  obj,
});

export const getViewReportCookieFail = (error, obj) => ({
  type: 'GET_VIEWREPORTCOOKIE_SUCCESS',
  error,
  obj,
});
//====================M001=====]====================
export const reportManagementGetAll = obj => ({
  type: 'REPORT_MANAGEMENT_GET_ALL_RESEARCHES',
  obj,
});

export const reportManagementGetAllSuccess = (data, pagination, obj) => ({
  type: 'REPORT_MANAGEMENT_GET_ALL_RESEARCHES_SUCCESS',
  data,
  pagination,
  obj,
});

export const reportManagementGetAllFail = (error, obj) => ({
  type: 'REPORT_MANAGEMENT_GET_ALL_RESEARCHES_FAIL',
  error,
  obj,
});
