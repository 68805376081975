import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Table, Row, Button, Col, FormControl, Glyphicon } from 'react-bootstrap';

import FilterBox from './FilterBox';
import Throbber from './Throbber';

const Component = ({
  loading,
  searchAble,
  searchUser,
  reportsSearch,
  filters,
  labels,
  data,
  title,
  onFilterChange,
  onOrderChange,
  columWidth,
  defaultText,
  sortCheckboxes
}) => {
  const [appliedFilters, setAppliedFilters] = React.useState({});
  // const [tableData, setTableData] = React.useState(data);
  const [showClear, setShowClear] = React.useState(false);
  const [orders, setOrders] = React.useState(labels.map(() => null));

  const updateFilters = (e, key) => {
    setAppliedFilters({
      appliedFilters: {
        ...appliedFilters,
        [key]: e.target.value,
      },
    });
  };

  React.useEffect(()=>{
    sortCheckboxes(data);
  }, [data])

  const updateOrder = (index, val) => {
    let newOrder;

    setOrders(
      orders.map((order, i) => {
        if (index === i) {
          newOrder = order === 'asc' ? 'desc' : 'asc';
          return newOrder;
        }

        return null;
      })
    );

    onOrderChange(labels[index].key, newOrder, val);
    sortCheckboxes(data);
  };
  const searchData = key => {
    const keyword = key.toLowerCase();
    const result = data.filter(
      val =>
        (val.name && val.name.toLowerCase().includes(keyword)) ||
        (val.email && val.email.toLowerCase().includes(keyword))
    );
    setTimeout(() => {
      // setTableData(result);
    }, 300);
  };
  const searchFromRedux = key => {
    setTimeout(() => {
      searchUser(key);
      if (key.length && !showClear) {
        setShowClear(true);
      } else if (showClear) {
        setShowClear(false);
      }
    }, 500);
  };
  const clearInput = () => {
    setShowClear(false);
    searchUser('');
    const input = document.getElementById('search-input');
    input.value = '';
  };
  return (
    <div>
      <div>
        <div className="table-header">
          <div className="table-header-text">{title}</div>
          {searchAble && (
            <div className="table-search-box">
              <FormControl
                className="table-header-searchBox"
                placeholder={reportsSearch ? 'Search Report' : 'Search User'}
                id="search-input"
                onChange={e => {
                  // searchData(e.target.value);
                  searchFromRedux(e.target.value && e.target.value.toLowerCase());
                  // onInputChange(e.target.value);
                }}
              />
              {showClear && (
                <Glyphicon
                  glyph="glyphicon glyphicon-remove"
                  className="search-box"
                  onClick={() => {
                    clearInput();
                  }}
                />
              )}
            </div>
          )}
        </div>
        {/* <div>{loader && <Throbber />}</div> */}
        <Table striped bordered condensed className="aletheia-table">
          <thead>
            <tr>
              {labels.map(({ key, label, props, sortable, width }, index) => (
                <th
                  {...props}
                  key={`table_header_${key}`}
                  onClick={sortable !== false ? () => updateOrder(index, data) : undefined}
                  style={{
                    // width: label === undefined ? '21%' : columWidth,
                    width: width ? width : columWidth,
                    textAlign: 'center',
                  }}
                >
                  {label}
                  {sortable !== false && (
                    <span className="pull-right" style={{ paddingLeft: 5 }}>
                      <Glyphicon
                        glyph={
                          orders[index]
                            ? orders[index] === 'desc'
                              ? 'sort-by-attributes-alt'
                              : 'sort-by-attributes'
                            : 'sort'
                        }
                      />
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length && !loading ? (
              data.map((item, i) => (
                <tr key={`table_tr_${i}`}>
                  {labels.map(({ key, render }, j) => (
                    <td
                      key={`table_td_${j}`}
                      className="user-table"
                      style={{ textAlign: 'center' }}
                    >
                      <span className="td-item">
                        {render ? render(get(item, key || ''), item, data) : get(item, key)}
                      </span>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="100">
                  {loading ? (
                    <Throbber />
                  ) : (
                    <p className="text-center">{defaultText ? defaultText : 'No Data'}</p>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

Component.propTypes = {
  loading: PropTypes.bool,
  searchAble: PropTypes.bool,

  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
    })
  ),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      render: PropTypes.func,
      sortable: PropTypes.bool,
      props: PropTypes.object,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  columWidth: PropTypes.string,

  searchUser: PropTypes.func,
  onFilterChange: PropTypes.func,
  onOrderChange: PropTypes.func,
  sortCheckboxes: PropTypes.func,
};

Component.defaultProps = {
  loading: false,
  searchAble: false,
  columWidth: 'auto',
  filters: [],
  data: [],
  title: null,
  sortCheckboxes: () => {},
  onFilterChange: () => {},
  onOrderChange: (c, o, data) =>
    data.sort((a, b) => {
      if (o === 'desc') {
        if (
          get(a, c) !== null &&
          String(get(a, c))
            .toLowerCase()
            .trim() <
            String(get(b, c))
              .toLowerCase()
              .trim()
        ) {
          return -1;
        }

        if (
          get(a, c) !== null &&
          String(get(a, c))
            .toLowerCase()
            .trim() >
            String(get(b, c))
              .toLowerCase()
              .trim()
        ) {
          return 1;
        }

        return 0;
      }

      if (
        get(a, c) !== null &&
        String(get(a, c))
          .toLowerCase()
          .trim() <
          String(get(b, c))
            .toLowerCase()
            .trim()
      ) {
        return 1;
      }

      if (
        get(a, c) !== null &&
        String(get(a, c))
          .toLowerCase()
          .trim() >
          String(get(b, c))
            .toLowerCase()
            .trim()
      ) {
        return -1;
      }

      return 0;
    }),
};

export default Component;
