import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter,useLocation } from 'react-router-dom';
import * as blogActions from '../../actionCreators/Blog';
import '../../assets/sass/blogs.scss';
import Header from '../../components/PageHeader';
import BlogLists from './components/BlogLists'
import FullPageLoader from '../../components/FullPageLoader'
import { Helmet } from 'react-helmet';

const BlogsLanding = ({
  posts,
  getPosts,
  selectedBlog,
  getBlogInfo,
}) => {
  let parameters = useLocation().pathname.split('/');
  var id = parameters[2];

  //GET POST
  React.useEffect(() => {
    //Get Blog Info if ID is not empty
    if (id!="" && id!=undefined && !(posts.loading||selectedBlog.loading)) {
      getBlogInfo({key:id});
      getPosts({key:id});
    } else {
      getBlogInfo();
      getPosts();
    }
  }, [id]);

  if (posts.loading||selectedBlog.loading) {
    return <FullPageLoader></FullPageLoader>
  }

  return (
      <>
        <Helmet>
            <title>{selectedBlog.data.topic}</title>
        </Helmet>
        <Header pageName={selectedBlog.data.topic} pageDesc={selectedBlog.data.subtitle} />
        <br />
        <BlogLists
          posts = {posts}
        />
      </>
    );
  };

const mapStateToProps = ({
  blog: {
      selectedBlog,
      posts
  }
}) => ({ posts, selectedBlog});

const mapDispatchToProps = dispatch => ({
  getBlogInfo: bindActionCreators(blogActions.getBlogInfo, dispatch),
  getPosts: bindActionCreators(blogActions.getPosts, dispatch),
});

BlogsLanding.propTypes = {
  
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BlogsLanding));
