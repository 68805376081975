/*
**************************************************************************************************************
											                      R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
==============================================================================================================

**************************************************************************************************************
					                                  	Amendments
==============================================================================================================
  M001 : 21 Sep 2022 : Nick
    * M-4165: Pressing back arrow when viewing a blog post does not do anything
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Grid, OverlayTrigger, Button, Tooltip, Glyphicon } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import Logo from '../../../assets/images/blue-logo.png'
import PostStat from './PostAdminData';

const PostGenericHeader = ({ title, subtitle, date, region, blogType, history, user }) => {
  const [moreinfo, setMoreinfo] = React.useState(false);
  const [showStat, setShowStat] = React.useState(false);

  const MoreInfoClick = () => {
    setMoreinfo(!moreinfo);
  };
  return (
    <Grid>
        {/* Header Section - 1 */}
        <div className='section-header'>
          <div>
            <Link className="back-button" title="Back" onClick={() => history.go(-1)}> {/*M001*/}
              <Glyphicon glyph="circle-arrow-left" />
            </Link>
          </div>
            {user.data && user.data.type=='admin' && (
              <div style={{ display: 'flex' }}>
                <span className="pull-right">
                  <div className="buttons-box">
                    <span className="pull-right" style={{ marginLeft: 10, marginRight: 5 }}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>View Statistics</Tooltip>}
                      >
                        <Button
                          bsSize="xs"
                          bsStyle="primary"
                          className="pull-right"
                          onClick={()=>{setShowStat(true)}}
                        >
                          <Glyphicon glyph="stats" />
                        </Button>
                      </OverlayTrigger>
                    </span>
                  </div>
                </span>
                <PostStat showModal={showStat} setShowModal={setShowStat}></PostStat>
              </div>
            )}

        </div>


        {/* Header Section - 1 */}

        {/* Header Section - 2 */}
        <Row>
            <Col xs={4}>
                <img src={Logo} style={{maxWidth:'150px'}}></img>
            </Col>
            <Col xs={8}>
                <div className="post-header1-text-div">
                    <p className="posts-header1-text">{date}</p>
                    <p className="posts-header1-strong-text">{title}</p>
                    <p className="posts-header1-text">{region}</p>
                    <p className="posts-header1-text">{blogType}</p>
                </div>
            </Col>
        </Row>
        {/* Header Section - 2 */}

        {/* Header Section - 3 */}
        <Row>
            <div className="post-header2-div">
                <h2 className="post-header2-title-text">{title}</h2>
                <h4 className="post-header2-subtitle-text">{subtitle}</h4>
            </div>
        </Row>
        {/* Header Section - 3 */}
    </Grid>
  );
};

PostGenericHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  date: PropTypes.string,
};

PostGenericHeader.defaultProps = {
    title: '',
    subtitle: '',
    date: '',
    region: '',
    postType: ''
};

export default PostGenericHeader;