/*
**************************************************************************************************************
											                      R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
==============================================================================================================

**************************************************************************************************************
						                                  Amendments
==============================================================================================================
    M001 : 05 Oct 2020 : Nick
      * M-0003058: Reports cannot be opened with safari and firefox
    M002 : 01 Dec 2020 : Nick
      * M-0003178: RFS proxy links are not recording the IP address of the recipient correctly
    M003 : 13 July 2021 : Aman
      * M-0003467: Fine-tuning of social media tiles from selected PDF text or Blog text
    M004 : 09 Aug 2022 : Nick Ng
      * M-4080: Download an excel file should not open an new window
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Glyphicon, ResponsiveEmbed, Button } from 'react-bootstrap';
import { PDFObject } from 'react-pdfobject';
import { Document, Page, pdfjs } from 'react-pdf';
import { Overlay, Popover } from 'react-bootstrap';
import SocialMediaForm from './SocialMediaForm';
import Pagination from '../../../components/Pagination'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
require('isomorphic-fetch');

const Component = ({
  type,
  path,
  onLoad,
  externalPdfView,
  fullScreenMode,
  title,
  role,
  research,
  SocialMediaViewer,
}) => {
  let normalTrimValue = 180;
  let externalPdfTrimValue = 140;
  let screenHieght = window.innerHeight - normalTrimValue;
  const [divHieght, setDivHieght] = React.useState(screenHieght);
  const [blobURL, setblobURL] = React.useState('');
  const [numPages, setNumPages] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedText, setSelectedText] = React.useState('');
  const [renderedHTMLArr, setRenderedHTMLArr] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);

  //Popup Definition
  const [popUpLeftPos, setPopUpLeftPos] = React.useState(0);
  const [popUpTopPos, setPopUpTopPos] = React.useState(0);
  const [showPopover, setShowPopover] = React.useState(false);

  React.useEffect( () => {
    if (numPages) {
      var page = [];
      for (var i = 1; i < numPages.numPages + 1; i++) {
        page.push(
          <Page
            wrap={false}
            width={window.innerWidth>1200?(window.innerWidth - (window.innerWidth/3.5)):(window.innerWidth - (window.innerWidth/10))}
            pageNumber={i}
            onMouseUp={selectText}
          ></Page>
        );
      }
      setRenderedHTMLArr(page);
      // page.push(<br />);
      // return page;
    }
  }, [numPages])

  // const loopPDFPages = pages => {
    
  // };

  const onDocumentLoadSuccess = numPages => {
    setNumPages(numPages);
  };

  const handleResize = () => {
    if (externalPdfView) {
      screenHieght = window.innerHeight - externalPdfTrimValue;
    } else {
      screenHieght = window.innerHeight - normalTrimValue;
    }
    if (divHieght !== screenHieght) {
      setDivHieght(screenHieght);
    }
  };

  const ifSafariOrFirefox = () => {
    // Detecting Browser by duck typing
    let isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    if (isSafari) {
      if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
        isSafari = false;
      }
    }
    const isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox || isSafari) {
      return true;
    }
    return false;
  };

  const isIEorEdge = () => {
    let isIEorEdge = document.documentMode || /Edge/.test(navigator.userAgent);
    if (isIEorEdge) {
      return true;
    }
    return false;
  };

  const popOverClickHandler = () => {
    setShowModal(true);
  };
  //M003
  const selectText = () => {
    var spans = document.getElementById('socialpdf').getElementsByTagName('span');
    for (var i = 0, l = spans.length; i < l; i++) {
      try {
        var spanText = spans[i].textContent || spans[i].innerText;
      } catch (e) {
        continue;
      }
      if (spanText[spanText.length - 1] != ' ') {
        spans[i].textContent = spans[i].textContent + ' ';
        spans[i].innerText = spans[i].innerText + ' ';
      }
    }
    var selectedText = window.getSelection().toString();
    if (selectedText) {
      var selection = window.getSelection(); // get the selection then
      var range = selection.getRangeAt(0); // the range at first selection group
      var rect = range.getBoundingClientRect(); // and convert this to useful data
      var scrolly = window.scrollY;
      var marginleft = rect.x;
      var marginTop = scrolly + rect.y - 30;
      setPopUpLeftPos(marginleft);
      setPopUpTopPos(marginTop);
      setShowPopover(true);
      setSelectedText(selectedText);
    } else {
      setShowPopover(false);
    }
  };
  //M003
  if (fullScreenMode) {
    normalTrimValue = 94;
    externalPdfTrimValue = 70;
    handleResize();
  }

  const keyEvent = e => {
    if (e.ctrlKey && e.keyCode === 80) {
      window.open(path);
    }
  };
  window.addEventListener('keydown', keyEvent);
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  React.useEffect(() => {
    if (type === 'pdf' && !ifSafariOrFirefox() && !isIEorEdge()) {
      fetch(path)
        .then(res => {
          return res.blob();
        })
        .then(blob => {
          var url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
          setblobURL(url);
        });
    }
  }, []);

  setInterval(() => {
    handleResize();
  }, 1000);
  handleResize();

  //M001
  if (type === 'pdf' && ifSafariOrFirefox()) {
    path = path.replace('#view=FitH', '');
    return (
      <div className="external-pdf text-center">
        <div>Click here to view the Report</div>
        <br></br>
        <Button
          onClick={() => {
            fetch(path)
              .then(res => {
                return res.blob();
              })
              .then(blob => {
                var url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
                var anchor = document.createElement('a');
                anchor.href = url;
                anchor.click();
              });
            // }
          }}
        >
          View Report
        </Button>
      </div>
    );
  }
  //M001

  if (type === 'pdf' && isIEorEdge()) {
    path = path.replace('#view=FitH', '');
    return (
      <div className="external-pdf text-center">
        <div>Click here to view the Report</div>
        <br></br>
        <Button
          onClick={() => {
            // ===============M002========[========
            // if (path.indexOf('http://')>=0 || path.indexOf('https://')>=0 ) {
            //   window.open(path)
            // } else {
            var ua = window.navigator.userAgent;
            var msie = ua.indexOf('MSIE ');
            if (!(msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))) {
              fetch(path)
                .then(res => {
                  return res.blob();
                })
                .then(blob => {
                  var fileName = title + '.pdf';
                  fileName = fileName.replace(/[/\\?%*:|"<>]/g, '');
                  navigator.msSaveBlob(blob, fileName);
                });
            } else {
              var data = path.replace('data:application/pdf;base64,', '');
              var fileName = title + '.pdf';
              fileName = fileName.replace(/[/\\?%*:|"<>]/g, '');
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                var byteCharacters = window.atob(data);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var blob = new Blob([byteArray], { type: 'application/pdf' });
                window.navigator.msSaveOrOpenBlob(blob, fileName);
              }
            }
          }}
        >
          View Report
        </Button>
      </div>
    );
  }

  if (type === 'pdf') {
    if (role == 'admin' && SocialMediaViewer) {
      return (
        <>
          <div id="socialpdf">
            <Overlay show={showPopover} placement="top">
              <Popover
                className="ReportSelectedPopover"
                style={{
                  zIndex: 600000,
                  marginTop: popUpTopPos + 'px',
                  marginLeft: popUpLeftPos + 'px',
                  cursor:'pointer'
                }}
                onClick={popOverClickHandler}
              >
                Click for Social Media Tiles
              </Popover>
            </Overlay>
            <SocialMediaForm
              showModal={showModal}
              setShowModal={setShowModal}
              selectedText={selectedText}
            ></SocialMediaForm>
            <Document file={`${path}`} onLoadSuccess={onDocumentLoadSuccess} renderMode="canvas">
                {renderedHTMLArr.length > 10?
                  <>
                    {renderedHTMLArr[currentPage]}
                    <Pagination
                      className="centrePagination"
                      current={currentPage + 1}
                      total={renderedHTMLArr.length}
                      onClick={p => setCurrentPage(p-1)}
                    />
                  </>
                  :
                  renderedHTMLArr
                }
            </Document>
          </div>
        </>
      );
    } else {
      if (blobURL) {
        return (
          <div className="text-center pdf-div" id="socialpdf" style={{ height: divHieght }}>
            <PDFObject
              url={blobURL}
              width="100%"
              height="100%"
              className="pdfObject-view"
              id="printf"
            />
          </div>
        );
      }
    }
  }

  //====================M004=======[================
  //XLSX
  if (type === 'xlsx' || type === 'xls') {
    path = path.replace('#view=FitH', '');
    return (
      <div className="external-pdf text-center">
        <div>Click here to view the Report</div>
        <br></br>
        <Button
          onClick={() => {
            fetch(path)
              .then(res => {
                return res.blob();
              })
              .then(blob => {
                var url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
                var anchor = document.createElement('a');
                anchor.download = `report.xlsx`;
                anchor.href = url;
                anchor.click();
              });
          }}
        >
          View Report
        </Button>
      </div>
    );
  }
  //====================M004=======]================

  onLoad();

  if (type === 'htm') {
    path = path.replace('#view=FitH', '');
    return (
      <div className="text-center" style={{ padding: '3%' }}>
        <p>Please visit the following link to view this content:</p>
        <h4>
          <a href={path} target="_blank" rel="noopener noreferrer">
            <Glyphicon glyph="new-window" /> View Video
          </a>
        </h4>
      </div>
    );
  }

  if (type === 'mp3') {
    return (
      <div className="text-center">
        <audio style={{ marginTop: 50, width: '60%' }} controls autoPlay>
          <source src={path} type="audio/mpeg" />
          <track kind="captions" />
          Your browser does not support the audio element.
        </audio>
      </div>
    );
  }

  if (type === 'video' || type === 'mp4' || type === 'mpg' || type === 'mpeg' || type === 'ogg') {
    return (
      <ResponsiveEmbed a16by9>
        <video style={{ padding: 20 }} controls autoPlay>
          <source type={`video/${type}`} src={path} />
          <track kind="captions" />
          Your browser does not support video playback.
        </video>
      </ResponsiveEmbed>
    );
  }

  if (type === 'youtube') {
    return (
      <ResponsiveEmbed a16by9>
        <iframe
          title="viewreport"
          src={`${path}?autoplay=1`}
          style={{ paddingTop: 20 }}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </ResponsiveEmbed>
    );
  }

  return (
    <ResponsiveEmbed a4by3>
      <iframe src={path} title="viewreport" />
    </ResponsiveEmbed>
  );
};

Component.propTypes = {
  type: PropTypes.string,
  path: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  externalPdfView: PropTypes.bool.isRequired,
  fullScreenMode: PropTypes.bool.isRequired,
};

Component.defaultProps = {
  type: null,
  onLoad: () => {},
};

export default Component;
