import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

const Component = ({ current, total, onClick, loading, ...props }) => {
  if (current && total) {
    if (total > 6) {
      const pages = [];
      let ellipsis = true;

      for (let i = 1; i <= total; i += 1) {
        if (
          i === 1 ||
          i === total ||
          current === i - 2 ||
          current === i - 1 ||
          current === i ||
          current === i + 1 ||
          current === i + 2
        ) {
          ellipsis = true;
          pages.push(
            <Pagination.Item
              onClick={() => onClick(i)}
              key={`page_${i + 1}`}
              active={i === current}
              disabled={loading}
            >
              {i}
            </Pagination.Item>
          );
        } else if (ellipsis) {
          ellipsis = false;
          pages.push(<Pagination.Ellipsis key={`ellipsis_${i}`} disabled />);
        }
      }

      return <Pagination {...props}>{pages}</Pagination>;
    }

    return (
      <Pagination {...props}>
        {Array.from({ length: total }).map((v, i) => (
          <Pagination.Item
            // eslint-disable-next-line
            key={`page_${i}`}
            active={i + 1 === current}
            onClick={() => onClick(i + 1)}
            disabled={loading}
          >
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    );
  }

  return null;
};

Component.propTypes = {
  loading: PropTypes.bool,
  current: PropTypes.number,
  total: PropTypes.number,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  loading: false,
  current: 1,
  total: 1,
  onClick: () => {},
};

export default Component;
