/* eslint-disable react/no-danger */
import React from 'react';
import Box from '../../../components/Box';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Modal, FormControl } from 'react-bootstrap'
import * as countryActions from '../../../actionCreators/Country';

const Component = ({ companies, setFilteredCompaines, countries, getCountries }) => {

    React.useEffect(() => {
        getCountries()
    }, []);

    const [showModal, setShowModal] = React.useState(false);

    const filterCompaniesByName = (value) => {
        if (value == '') {
            value = "A";
        }
        var filterdCompaines = []
        companies.forEach(company => {
            if(company.sName.includes(value) || (company.sBBG_Code!=null && company.sBBG_Code.includes(value))) {
                filterdCompaines.push(company);
            }
        });
        setFilteredCompaines(filterdCompaines);
    }

    const filterCompaniesByCountry = (value) => {
        console.log(value);
        if (value == '-' || !value) {
            value = "A";
        }
        var filterdCompaines = []
        companies.forEach(company => {
            if(company.iCountryId == value) {
                filterdCompaines.push(company);
            }
        });
        setFilteredCompaines(filterdCompaines);
    }

    const renderCountriesSelect = () => {
        return (
            <FormControl
                componentClass="select"
                onChange={e => {
                    filterCompaniesByCountry(e.target.value)
                }}
            >
                <option>-</option>
                {countries.data &&
                countries.data.map((item, index) => (
                    <option key={`filterbox_dropdown_option_${index}`} value={item.id}>
                    {item.sName}
                    </option>
                ))}
            </FormControl>
        )
    }
    
    return (
        <Box xs={12} style={{margin:'1%'}} className="filter-box-section filter-box-section box">
            <div className="filter-box-button">
                <Button className="filter-icon" onClick={() => setShowModal(true)} />
                <p>Filter</p>
            </div>
            <div className="react-tagsinput">
                <span>
                    <input
                        onChange={event=>{
                            filterCompaniesByName(event.target.value)
                        }}
                        className="react-tagsinput-input"
                        type="text"
                        id="inputField"
                        autoComplete="off"
                        placeholder="Search for company..."
                    />
                </span>
            </div>
            <Modal bsSize="lg" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Filter By</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="filter-box-table">
                    <div>
                        <br></br>
                        <p>Countries:</p>
                        <br></br>
                        {renderCountriesSelect()}
                    </div>
                    <br></br>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <Button type="submit" bsStyle="primary" onClick={()=> setShowModal(false)} block>
                    Apply Filters
                </Button>
                </Modal.Footer>
            </Modal>
        </Box>
    )
};

const mapStateToProps = ({
    country: { countries },
}) => ({
    countries,
});

const mapDispatchToProps = dispatch => ({
    getCountries: bindActionCreators(countryActions.getAll, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);