/*
**************************************************************************************************************
            ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
            Amendments
========================================================================
  M002 : 03 04 2023 : Zeeshan
    * M-0004354: Clicking the X on a previously used search term under the Research search does not clear it
  M001 : 07 September 2020 : Aman
    * M-0002985: Search function does not retain criteria after clicking the back button after reading a report.
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';

import SuggestionsBox from './SuggestionsBox';

const uniq = arr => {
  const out = [];

  for (let i = 0; i < arr.length; i++) {
    if (out.indexOf(arr[i]) === -1) {
      out.push(arr[i]);
    }
  }

  return out;
};

/* istanbul ignore next */
const getClipboardData = e => {
  if (window.clipboardData) {
    return window.clipboardData.getData('Text');
  }

  if (e.clipboardData) {
    return e.clipboardData.getData('text/plain');
  }

  return '';
};

const defaultRenderTag = props => {
  const {
    tag,
    key,
    disabled,
    onRemove,
    onRemoveTag,
    classNameRemove,
    getTagDisplayValue,
    ...other
  } = props;

  // tag.includes(':') && /Analyst|Company|Geography|Sector/.test(tag)
  // ? tag.split(':')[1].trim()
  // : tag

  return (
    <span key={key} {...other}>
      {getTagDisplayValue(tag)}
      {!disabled && (
        <a
          href="/"
          className={classNameRemove}
          onClick={e => {
            e.preventDefault();
            onRemove(key);
            onRemoveTag(tag);
          }}
        >
          <span />
        </a>
      )}
    </span>
  );
};

defaultRenderTag.propTypes = {
  key: PropTypes.number.isRequired,
  tag: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  classNameRemove: PropTypes.string.isRequired,
  getTagDisplayValue: PropTypes.func.isRequired,
};

function defaultRenderLayout(tagComponents, inputComponent) {
  return (
    <span>
      {tagComponents}
      {inputComponent}
    </span>
  );
}

function defaultPasteSplit(data) {
  return data.split(' ').map(d => d.trim());
}

const defaultInputProps = {
  className: 'react-tagsinput-input',
  placeholder: 'Search...',
};

class Component extends React.Component {
  state = { tag: '', isFocused: false };

  _getTagDisplayValue = tag => {
    const { tagDisplayProp } = this.props;

    if (tagDisplayProp) {
      return tag[tagDisplayProp];
    }

    return tag;
  };

  _makeTag = tag => {
    const { tagDisplayProp } = this.props;

    if (tagDisplayProp) {
      return {
        [tagDisplayProp]: tag,
      };
    }

    return tag;
  };

  removetag = index => {
    let value = this.props.value.concat([]);
    if (index > -1 && index < value.length) {
      let changed = value.splice(index, 1);

      //====================M001=====[====================
      if (value.length) {
        //M002
        this.props.onChange(value, changed, [index]);
        sessionStorage.setItem('TagsInput', value);
      } else sessionStorage.removeItem('TagsInput'); //M002
      //====================M001=====[====================
    }
  };

  _clearInput = () => {
    if (this.hasControlledInput()) {
      this.props.onChangeInput('');
    } else {
      this.setState({ tag: '' });
    }
  };

  _tag = () => {
    const { tag } = this.state;
    const { inputValue } = this.props;

    if (this.hasControlledInput()) {
      return inputValue;
    }

    return tag;
  };

  _addTags = tags => {
    const { validationRegex, onChange, onValidationReject, onlyUnique, maxTags, value } =
      this.props;
    if (onlyUnique) {
      tags = uniq(tags);
      tags = tags.filter(tag =>
        value.every(
          currentTag => this._getTagDisplayValue(currentTag) !== this._getTagDisplayValue(tag)
        )
      );
    }

    const rejectedTags = tags.filter(tag => !validationRegex.test(this._getTagDisplayValue(tag)));
    tags = tags.filter(tag => validationRegex.test(this._getTagDisplayValue(tag)));
    tags = tags.filter(tag => {
      const tagDisplayValue = this._getTagDisplayValue(tag);
      if (typeof tagDisplayValue.trim === 'function') {
        return tagDisplayValue.trim().length > 0;
      }

      return tagDisplayValue;
    });

    if (maxTags >= 0) {
      const remainingLimit = Math.max(maxTags - value.length, 0);
      tags = tags.slice(0, remainingLimit);
    }

    if (onValidationReject && rejectedTags.length > 0) {
      onValidationReject(rejectedTags);
    }

    if (tags.length > 0) {
      let newValue = value.concat(tags);
      let indexes = [];
      for (let i = 0; i < tags.length; i++) {
        indexes.push(value.length + i);
      }
      onChange(newValue, tags, indexes);
      this._clearInput();
      //====================M001=====[====================
      sessionStorage.setItem('TagsInput', newValue);
      //====================M001=====[====================
      return true;
    }

    if (rejectedTags.length > 0) {
      return false;
    }

    this._clearInput();
    return false;
  };

  shouldPreventDefaultEventOnAdd = (added, empty, keyCode) => {
    if (added) {
      return true;
    }

    if (keyCode === 13) {
      const { preventSubmit } = this.props;

      return preventSubmit || (!preventSubmit && !empty);
    }

    return false;
  };

  focus = () => {
    if (this.input && typeof this.input.focus === 'function') {
      this.input.focus();
    }

    this.handleOnFocus();
  };

  blur = () => {
    if (this.input && typeof this.input.blur === 'function') {
      this.input.blur();
    }

    this.handleOnBlur();
  };

  accept = () => {
    let tag = this._tag();

    if (tag !== '') {
      tag = this._makeTag(tag);
      return this._addTags([tag]);
    }

    return false;
  };

  addTag = tag => this._addTags([tag]);

  clearInput = () => this._clearInput();

  handlePaste = e => {
    let { addOnPaste, pasteSplit } = this.props;

    if (!addOnPaste) {
      return;
    }

    e.preventDefault();

    let data = getClipboardData(e);
    let tags = pasteSplit(data).map(tag => this._makeTag(tag));

    this._addTags(tags);
  };

  handleKeyDown = e => {
    if (e.defaultPrevented) {
      return;
    }

    const { value, removeKeys, addKeys } = this.props;
    const tag = this._tag();
    const empty = tag === '';
    const keyCode = e.keyCode;
    const key = e.key;
    const add = addKeys.indexOf(keyCode) !== -1 || addKeys.indexOf(key) !== -1;
    const remove = removeKeys.indexOf(keyCode) !== -1 || removeKeys.indexOf(key) !== -1;

    if (add) {
      const added = this.accept();
      if (this.shouldPreventDefaultEventOnAdd(added, empty, keyCode)) {
        e.preventDefault();
      }
    }

    if (remove && value.length > 0 && empty) {
      e.preventDefault();
      this.removetag(value.length - 1);
    }
  };

  handleClick = e => {
    if (e.target === this.div) {
      this.focus();
    }
  };

  handleChange = e => {
    const { onChangeInput, inputProps } = this.props;
    const { onChange } = inputProps;
    const tag = e.target.value;
    if (onChange) {
      onChange(e);
    }

    if (this.hasControlledInput()) {
      onChangeInput(tag);
    } else {
      this.setState({ tag });
    }
  };

  handleOnFocus = e => {
    const { onFocus } = this.props.inputProps;

    if (onFocus) {
      onFocus(e);
    }

    this.setState({ isFocused: true });
  };

  handleOnBlur = e => {
    const { onBlur } = this.props.inputProps;

    this.setState({ isFocused: false });

    if (e == null) {
      return;
    }

    if (onBlur) {
      onBlur(e);
    }

    if (this.props.addOnBlur) {
      const tag = this._makeTag(e.target.value);
      this._addTags([tag]);
    }
  };

  handleRemove = tag => this.removetag(tag);

  inputProps = () => {
    // eslint-disable-next-line
    let { onChange, onFocus, onBlur, ...otherInputProps } = this.props.inputProps;
    const { disabled } = this.props;

    const props = {
      ...defaultInputProps,
      ...otherInputProps,
    };

    if (disabled) {
      props.disabled = true;
    }

    return props;
  };

  inputValue = props => props.currentValue || props.inputValue || '';

  hasControlledInput = () => {
    const { inputValue, onChangeInput } = this.props;

    return typeof onChangeInput === 'function' && typeof inputValue === 'string';
  };

  componentDidMount() {
    if (this.hasControlledInput()) {
      return;
    }

    this.setState({
      tag: this.inputValue(this.props),
    });
    //====================M001=====[====================
    let data = sessionStorage.getItem('TagsInput');
    if (data != null || data != undefined) {
      let dataArray = data.split(',');
      if (dataArray[0] != '') {
        this.props.onChange(dataArray);
      }
    }
    //====================M001=====[====================
  }

  componentWillReceiveProps(nextProps) {
    /* istanbul ignore next */
    if (this.hasControlledInput()) {
      return;
    }

    if (!this.inputValue(nextProps)) {
      return;
    }

    this.setState({
      tag: this.inputValue(nextProps),
    });
  }

  renderInput = ({ addTag, ...props }) => {
    // const { showSuggestion } = this.state;
    const {
      onChange,
      value,
      onKeyPress,
      onSelect,
      onSelectFullValue,
      suggestionsList,
      suggestionsLoading,
      showSuggestion,
      ...other
    } = props;

    return (
      <>
        <input
          type="text"
          onChange={onChange}
          value={value}
          id="inputField"
          onKeyUp={e => onKeyPress(value)}
          autoComplete="off"
          {...other}
        />
        <SuggestionsBox
          list={suggestionsList}
          show={showSuggestion}
          onSelect={v => {
            this._clearInput();
            this.focus();
            onSelect(v);
          }}
          onSelectValue={(val, onClick) => {
            onSelectFullValue(val, onClick);
          }}
        />
      </>
    );
  };

  render() {
    const { isFocused } = this.state;
    const {
      value,
      // onChange,
      tagProps,
      renderLayout,
      renderTag,
      // addKeys,
      // removeKeys,
      focusedClassName,
      // addOnBlur,
      // addOnPaste,
      inputProps,
      // pasteSplit,
      // onlyUnique,
      // maxTags,
      // validationRegex,
      disabled,
      onRemoveTag,
      // tagDisplayProp,
      // inputValue,
      // onChangeInput,
      onKeyPress,
      onSelect,
      onSelectFullValue,
      suggestionsList,
      showSuggestion,
    } = this.props;
    let { className } = this.props;

    if (isFocused) {
      className += ' ' + focusedClassName;
    }
    const tagComponents = value.map((tag, index) => {
      return renderTag({
        key: index,
        tag,
        onRemove: this.handleRemove,
        onRemoveTag,
        disabled,
        getTagDisplayValue: this._getTagDisplayValue,
        ...tagProps,
      });
    });

    const inputComponent = this.renderInput({
      ref: r => {
        this.input = r;
      },
      value: this._tag(),
      onPaste: this.handlePaste,
      onKeyDown: this.handleKeyDown,
      onChange: this.handleChange,
      onKeyPress,
      onSelect,
      onSelectFullValue,
      onFocus: this.handleOnFocus,
      onBlur: this.handleOnBlur,
      addTag: this.addTag,
      suggestionsList,
      showSuggestion,
      ...this.inputProps(),
    });

    return (
      <div
        role="main"
        ref={r => {
          this.div = r;
        }}
        onClick={this.handleClick}
        className={className}
      >
        {renderLayout(tagComponents, inputComponent)}
      </div>
    );
  }
}

Component.propTypes = {
  className: PropTypes.string,
  focusedClassName: PropTypes.string,
  addKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  addOnBlur: PropTypes.bool,
  addOnPaste: PropTypes.bool,
  currentValue: PropTypes.string,
  inputValue: PropTypes.string,
  inputProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func,
  removeKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  renderTag: PropTypes.func,
  renderLayout: PropTypes.func,
  pasteSplit: PropTypes.func,
  tagProps: PropTypes.object,
  onlyUnique: PropTypes.bool,
  value: PropTypes.array.isRequired,
  maxTags: PropTypes.number,
  validationRegex: PropTypes.instanceOf(RegExp),
  disabled: PropTypes.bool,
  tagDisplayProp: PropTypes.string,
  preventSubmit: PropTypes.bool,
  suggestionsList: PropTypes.object,
};

Component.defaultProps = {
  className: 'react-tagsinput',
  focusedClassName: 'react-tagsinput--focused',
  addKeys: [9, 13],
  addOnBlur: false,
  addOnPaste: false,
  currentValue: null,
  inputValue: null,
  inputProps: {},
  onChangeInput: () => {},
  removeKeys: [8],
  renderTag: defaultRenderTag,
  renderLayout: defaultRenderLayout,
  pasteSplit: defaultPasteSplit,
  tagProps: { className: 'react-tagsinput-tag', classNameRemove: 'react-tagsinput-remove' },
  onlyUnique: false,
  maxTags: -1,
  validationRegex: /.*/,
  disabled: false,
  tagDisplayProp: null,
  preventSubmit: true,
  suggestionsList: {},
};

export default Component;
