/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
  M001 : 21 April 2021 : Aman
		* M-0003347: Allow upload of Our People photos to be uploaded via Team Management page
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AnalystCard from './components/AnalystCard';
import ReportsContainer from '../../containers/Reports';
import { notify } from 'react-notify-toast';
import * as analystActions from '../../actionCreators/Analyst';
import * as configActions from '../../actionCreators/Config';

const Container = ({
  location: { pathname },
  analyst,
  getAnalyst,
  currentUser,
  uploadAnalystImage,
  analystImage,
  editBiography,
  getParameter,
  parameters,
}) => {
  const id = pathname.split('/')[2] && pathname.split('/')[2].split('_')[0];
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    getParameter('rw_CNTBkTalkToThe,bEnableConnectBookings');
  }, []);

  React.useEffect(() => {
    getAnalyst({ id });
  }, [getAnalyst, id]);
  //====================M001==========================
  React.useEffect(() => {
    getAnalyst({ id });
  }, [analystImage]);

  const onChangeHandler = event => {
    const file = event.target.files[0];

    if (file.type.match('image/jpeg|image/png')) {
      const img = document.createElement('img');
      img.src = URL.createObjectURL(file);
      // eslint-disable-next-line func-names
      img.onload = function () {
        uploadAnalystImage({ imageFile: file, ID: id });
        setError(false);
      };
    } else {
      setError(true);
    }
  };

  const editBioGraphyHandler = data => {
    editBiography({ iDescription_AttachToID: id, sDescription: data, cDescription_Entity: 'U' });
    const popUpColor = { background: '#337ab7', text: '#FFFFFF' };
    notify.show('Your changes has been saved successfully!', 'custom', 5000, popUpColor);
  };
  //====================M001==========================
  return (
    <>
      {/* //====================M001========================== */}
      <AnalystCard
        error={error}
        user={currentUser.data}
        FileUploadHandler={onChangeHandler}
        data={analyst.data}
        loading={analyst.loading}
        style={{ margin: '20px 0px', borderRadius: '10px' }}
        onEditBiography={editBioGraphyHandler}
        parameters={parameters}
      />
      {/* //====================M001========================== */}
      {currentUser.data && <ReportsContainer analystId={id} />}
    </>
  );
};

const mapStateToProps = ({
  analyst: { analyst },
  user: { currentUser },
  Config: { analystImage, parameters },
}) => ({
  analyst,
  currentUser,
  analystImage,
  parameters,
});

const mapDispatchToProps = dispatch => ({
  getAnalyst: bindActionCreators(analystActions.getAnalyst, dispatch),
  uploadAnalystImage: bindActionCreators(configActions.uploadAnalystImage, dispatch),
  editBiography: bindActionCreators(configActions.editBiography, dispatch),
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
});

Container.propTypes = {
  analystImage: PropTypes.object.isRequired,
  getAnalyst: PropTypes.func.isRequired,
  editBiography: PropTypes.func.isRequired,
  uploadAnalystImage: PropTypes.func.isRequired,
  analyst: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  currentUser: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
