/*
**************************************************************************************************************
                        R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
  Author	:	Nick Ng
  Date	:	10 Oct 2022
**************************************************************************************************************
                        Usage Notes
**************************************************************************************************************

**************************************************************************************************************
                        Amendments
**************************************************************************************************************
 M001 : 25 July 2023 : Zeeshan
    * M-3874: Email verification should contain a link to take user back to the page they tried to visit
**************************************************************************************************************

How to Run
=> Install node-js version 18.16.0
=> run npm install --save --legacy-peer-deps
=> run npm start
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/sass/index.scss';

import DefaultLayout from './layouts/Default';
import MainLayout from './layouts/Main';
import SecondaryLayout from './layouts/Secondary';

import getRouter from './router';

import * as userActions from './actionCreators/User';
import * as configActions from './actionCreators/Config';
import * as researchActions from './actionCreators/Research';

import FullPageLoader from './components/FullPageLoader/index';

const App = props => {
  const { path, meta, component: Component } = getRouter(props);
  const { history, location, getCurrentUser, currentUser, setComponentLayout } = props;
  const [isSignupClicked, setIsSignupClicked] = React.useState(false);

  React.useEffect(() => {
    var redUrl;
    if (meta) {
      redUrl =
        meta.auth === true ? `${location.pathname}${location.search || ''}` : location.pathname;
    } else {
      redUrl = location.pathname;
    }
    getCurrentUser(null, history, redUrl);
  }, []);

  //====================M001=====[====================
  // After Login
  React.useEffect(() => {
    let url = JSON.parse(localStorage.getItem('prevUrl'));
    if (currentUser?.data?.returnUrl === '/') history.push(url ? url : '/');
  }, [currentUser?.data?.returnUrl]);

  //Before login
  React.useEffect(() => {
    if (location.pathname === '/signup' || location.pathname === '/login') setIsSignupClicked(true);
  }, [location.pathname]);

  React.useEffect(() => {
    if (
      location.pathname !== '/signup' &&
      location.pathname !== '/login' &&
      location.pathname !== '/verify'
    )
      localStorage.setItem('prevUrl', JSON.stringify(location.pathname));
  }, [!isSignupClicked && location.pathname]);
  //====================M001=====[====================

  if (currentUser.loading) {
    return <FullPageLoader></FullPageLoader>;
  }

  if (meta && meta.auth === true && !currentUser.data) {
    return (
      <Redirect
        to={{
          pathname: '/signup',
          state: { redirectUrl: `${location.pathname}${location.search || ''}` },
        }}
      />
    );
  }

  const reportViewerPage = location.pathname.includes('reports/');
  const externalPdfViewerPage = location.pathname.includes('rfs.htm');
  if (reportViewerPage || externalPdfViewerPage) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
    setComponentLayout(false);
    const mobileWidth = window.innerWidth;
    const mainBody = document.getElementById('main-body');
    if (mobileWidth > 770) {
      if (mainBody) {
        mainBody.style.margin = '80px 0px';
      }
    } else if (mainBody) {
      // mainBody.style.margin = '55px 0px';
    }
  }
  if (path && String(location.pathname).toLowerCase() !== String(path).toLowerCase()) {
    return <Redirect to={path} />;
  }

  let Layout;

  if (meta) {
    if (meta.layout === null) {
      Layout = null;
    } else if (meta.layout === 'main') {
      Layout = MainLayout;
    } else if (meta.layout === 'secondary') {
      Layout = SecondaryLayout;
    } else {
      Layout = DefaultLayout;
    }
  }

  //This is used to force the componenet wait till the current user is loaded (either error or with user data)
  if (currentUser.loaded) {
    if (Layout) {
      return (
        <Layout>
          <Component />
        </Layout>
      );
    }
    return (
      <>
        <Component />
      </>
    );
  } else {
    return <FullPageLoader></FullPageLoader>;
  }
};

const mapStateToProps = ({ user: { currentUser }, Config: { fullScreenMode, suggestionBox } }) => ({
  currentUser,
  fullScreenMode,
  suggestionBox,
});

const mapDispatchToProps = dispatch => ({
  getCurrentUser: bindActionCreators(userActions.getCurrentUser, dispatch),
  setComponentLayout: bindActionCreators(configActions.setComponentLayout, dispatch),
  showSuggestionBox: bindActionCreators(configActions.showSuggestionBoxDropDown, dispatch),
  emptySuggestions: bindActionCreators(researchActions.emptySuggestions, dispatch),
});

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  showSuggestionBox: PropTypes.func.isRequired,
  emptySuggestions: PropTypes.func.isRequired,
  suggestionBox: PropTypes.bool.isRequired,
  setComponentLayout: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.object,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
