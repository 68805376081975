/*
**************************************************************************************************************
												R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	:	Nick Ng
	Date	:	10 Oct 2022
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************
	M002 : 02 Nov 2022 : Nick Ng : Core
		 * M-4240: Incorrect time for CONNECT event registration
	M001 : 10 Oct 2022 : Nick Ng : Core
		 * M-4180: Mirgrate research portal to use public module api and deprecate Node.js
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as connectAction from '../../../actionCreators/Connect';
import { connect } from 'react-redux';
import { Image, Row, Col, Button, ControlLabel, Modal } from 'react-bootstrap';
import FullPageLoader from '../../../components/FullPageLoader';
import { getStandardDateTime } from '../../../utils/dateTime';
import { doPost } from '../../../utils/request';

const SignupIndividualEvts = ({
    user,
    evtId,
    getEvent,
    event,
    loading,
    registerEvents
}) => {

    const [showConfirm, setShowConfirm] = React.useState(false);
    const [hackLoading, setHackLoading] = React.useState(false);

    React.useEffect(()=>{
        getEvent(evtId);
    }, [evtId])

    const submitEvt = () => {
        registerEvents({submitEvts:event});
        setShowConfirm(false);
        setHackLoading(true);
        setTimeout(function(){
            setHackLoading(false);
            getEvent(evtId);
        }, 2500);
    }

    if (!event){
        return <></>
    }

    if (!event.length){
        return <></>
    }

    if (loading||hackLoading) {
        return <FullPageLoader></FullPageLoader>
    }

    return (
        <>
            <Modal show={showConfirm} className="container-fake connect-confirm-modal">
                <Modal.Body>
                    <div style={{ paddingLeft: '3%', fontSize: 12, marginTop: '5%', paddingRight: '3%', fontSize: 14}}>
                        <p>Thank you very much for registering for the event.</p>
                        <p>We look forward to your participation in the event.</p>
                        <p>Our team will get back to you shortly with your meeting schedule.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="primary" onClick={()=>{submitEvt()}}>
                        Confirm
                    </Button>
                    <Button onClick={()=>{setShowConfirm(false)}}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>



            {/* IMAGE */}
            <div className="connect-invitation-image">
                <Image className="connect-invitation-image-mobile" onError={(e)=>{e.target.onerror = null; e.target.src="https://aletheiastorage.blob.core.windows.net/aletheia-images/campaign-default.png"}} width={'70%'} src={`https://aletheiastorage.blob.core.windows.net/aletheia-images/campaign-${evtId}.png`}></Image>
            </div>
            {user.data.type=="admin"?(
                <div className="connect-invitation-image">
                    <label className="custom-input-connect-banner" style={{ content:'Replace the banner !important', width: '200px !important' }}>
                        <input type="file" onChange={event => {
                            const file = event.target.files[0];
                            if (file.type.match('image/png') || file.type.match('image/jpg') || file.type.match('image/jpeg')) {
                              const img = document.createElement('img');
                              img.src = URL.createObjectURL(file);
                              // eslint-disable-next-line func-names
                              img.onload = function() {
                                const data = new FormData();
                                data.append('file', file);
                                //==============M001==========[============
                                doPost('/upload?type=campaign&id='+evtId, data).then(()=>{
                                    alert("Banner Replaced");
                                    window.location.reload(false);
                                })
                                //==============M001==========]============
                              };
                            } else {
                                alert("Please upload a PNG image.");
                            }
                        }} />
                    </label>
                </div>
            ):null}
            {/* IMAGE */}

            {/* DETAILS */}
            <Row style={{marginTop:50, marginBottom:50}} >
                <Col sm={2} xs={0}></Col>
                    <Col sm={8} xs={12} style={{borderTop: '1px solid rgb(210, 210, 210)', borderBottom: '1px solid rgb(210, 210, 210)', paddingTop:20, paddingBottom:20 }}>
                        <Col sm={4} xs={12} style={{textAlign:'center'}}>
                            <ControlLabel style={{paddingTop:5}}>Topic: </ControlLabel>
                        </Col>
                        <Col sm={8} xs={12}>
                            <ControlLabel style={{paddingTop:5}}>{event[0].sEvtName}</ControlLabel>
                        </Col>
                        <Col sm={4} xs={12} style={{textAlign:'center'}}>
                            <ControlLabel style={{paddingTop:5}}>Universal/Local Time: </ControlLabel> {/* M002 */}
                        </Col>
                        <Col sm={8} xs={12}>
                            <ControlLabel style={{paddingTop:5}}>
                                {getStandardDateTime(event[0].dUTC_EvtDate,{format:'DD MMMM YYYY (ddd) HH:mm '})} / {getStandardDateTime(event[0].dLocale_EvtDate,{format:'DD MMMM YYYY (ddd) HH:mm '})} {/* M002 */}
                            </ControlLabel>
                        </Col>
                    </Col>
                <Col sm={2} xs={0}></Col>
            </Row>
            {/* DETAILS */}

            {/* SUBMIT BUTTON */}
            <Row style={{textAlign:'center', marginBottom:50 }}>
                {event[0].bRegistered?
                    (
                        <div>
                            <b style={{fontSize:16}}>
                                You have registered for this event. Thank you.
                            </b>
                        </div>
                    ):
                    (
                        <Button bsStyle="primary" type="submit" onClick={()=>{setShowConfirm(true)}}>
                            Register
                        </Button>
                    )
                }
                
            </Row>
            {/* SUBMIT BUTTON */}
        </>
    )

}

function mapStateToProps(state) {
    const user = state.user.currentUser
    const event = state.connect.event;
    const loading = state.connect.loading
    return {event, user, loading};
}

const mapDispatchToProps = dispatch => ({
    getEvent:           bindActionCreators(connectAction.getEvent, dispatch),
    registerEvents:     bindActionCreators(connectAction.registerEvents, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupIndividualEvts));