/*
**************************************************************************************************************
												R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	:	Nick Ng
	Date	:	10 Oct 2022
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************
	M001 : 10 Oct 2022 : Nick Ng : Core
		 * M-4180: Mirgrate research portal to use public module api and deprecate Node.js
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, FormControl, Modal } from 'react-bootstrap';
import DateRangePicker from 'react-daterange-picker';

import * as researchActions from '../../actionCreators/Research';

import Autocomplete from '../Autosuggest';

import 'react-daterange-picker/dist/css/react-calendar.css';

import { getStandardDateTime } from '../../utils/dateTime';

const Component = ({ onChange, filters, filtersState, clearFilters }) => {
  // const object = Object.fromEntries(
  //   filtersState.length && filtersState.map(item => [item.split(':')[0], item.split(':')[1]])
  // );

  const [show, setShow] = React.useState(false);
  const [appliedFilters, setAppliedFilters] = React.useState({});
  const { key } = filtersState;
  const [daterange, setDaterange] = React.useState({});
  if (key) {
    let keyToRemove = key.toLowerCase();

    if (keyToRemove === 'geography') {
      keyToRemove = 'country';
    }
    if (keyToRemove === 'range') {
      keyToRemove = 'daterange';
    }
    const itemToRemove = appliedFilters[keyToRemove];
    if (Object.keys(appliedFilters).length !== 0 && itemToRemove) {
      let filtersObj = appliedFilters;
      delete filtersObj[keyToRemove];
      clearFilters();
    }
  }

  const updateFilters = (key, value) => {
    setAppliedFilters({
      ...appliedFilters,
      [key]: value,
    });
  };

  // HOC for rendering input
  const renderInput = filter => {
    if (filter.type === 'autocomplete') {
      return (
        <Autocomplete
          suggestions={filter.data}
          onSelect={val => updateFilters(filter.key, val.name)}
          placeholder={filter.label || filter.key}
          defaultValue={filter.value || ''}
        />
      );
    }

    if (filter.type === 'dropdown') {
      return (
        <FormControl
          componentClass="select"
          onChange={e => {
            if (e.target.value && e.target.value !== '-') {
              updateFilters(filter.key, e.target.value);
            }
          }}
        >
          <option>-</option>
          {filter.data &&
            filter.data.map((item, index) => (
              <option key={`filterbox_dropdown_option_${index}`} value={item.sName}>
                {item.sName}
              </option>
            ))}
        </FormControl>
      );
    }
  
    //==================M001=======[===============
    if (filter.type === 'daterange') {
      return (
        <DateRangePicker
          value={(filter.value && filter.unmap(filter.value)) || daterange[filter.key]}
          onSelect={v => {
            setDaterange({
              ...daterange,
              [filter.key]: v,
            });
            updateFilters(
              filter.key,
              typeof filter.map === 'function'
                ? filter.map([
                    getStandardDateTime(v.start, {
                      format: 'DD MMM Y',
                    }),
                    getStandardDateTime(v.end, {
                      format: 'DD MMM Y',
                    }),
                  ])
                : [
                    getStandardDateTime(v.start, {
                      format: 'DD MMM Y',
                    }),
                    getStandardDateTime(v.end, {
                      format: 'DD MMM Y',
                    }),
                  ]
            );
          }}
          singleDateRange
        />
      );
    }
    //==================M001=======]===============

    return (
      <FormControl
        type="text"
        placeholder={filter.label || filter.key}
        defaultValue={filter.value || ''}
        onChange={e => {
          updateFilters(
            filter.key,
            typeof filter.map === 'function' ? filter.map(e.target.value) : e.target.value
          );
        }}
      />
    );
  };

  const handleApplyFilters = () => {
    const tempFilters = {};
    Object.keys(appliedFilters) // get all keys present in the applied filters
      .forEach(key => {
        if (appliedFilters[key]) {
          tempFilters[key] = appliedFilters[key]; // traverse on the filtered keys and appednd them to the fitlered object
        }
      });

    setShow(false);
    onChange(tempFilters);
  };

  return (
    <>
      <div className="filter-box-button">
        <Button className="filter-icon" onClick={() => setShow(true)} />
        <p>Filter</p>
      </div>

      <Modal bsSize="sm" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filter By</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-box-table">
            {filters.map(filter => (
              <div key={`filter_heading_${filter.key}`}>
                <p>{filter.label || filter.key}</p>
                {renderInput(filter)}
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" bsStyle="primary" onClick={handleApplyFilters} block>
            Apply Filters
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ research: { filtersState } }) => ({
  filtersState,
});

const mapDispatchToProps = dispatch => ({
  clearFilters: bindActionCreators(researchActions.clearFilters, dispatch),
});

Component.propTypes = {
  onChange: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
    })
  ).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
