/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
  M001 : 3rd Feb 2021 : Aman
    * M-0003220: Allow users to save documents
**************************************************************************************************************
*/
//====================M001=====[====================
import React from 'react';
import { Helmet } from 'react-helmet';

const ReportsContainer = React.lazy(() => import('../../containers/Reports'));

const Container = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Saved Researches</title>
      <meta name="description" content="Your Saved Researches" />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
    <React.Suspense fallback={<></>}>
      <ReportsContainer showSavedReports showHighlighted />
    </React.Suspense>
  </>
);

export default Container;
//====================M001=====[====================
