/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 19 April 2021 : Nick Ng
    * M-0003367: cannot access to topics management page if no. of topics is zero
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Row, Col, Glyphicon, Tab, Nav, NavItem } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Box from '../../components/Box';
import BlogEditor from '../../pages/BlogEditor';
import Throbber from '../../components/Throbber';
import '../../assets/sass/admin-panel.scss';

import * as configActions from '../../actionCreators/Config';
import * as reportActions from '../../actionCreators/Research';
import ComponentKeyword from './components/blogAndKeyword';
import Confirm from '../../components/Confirm';

const Container = ({
  getFavourites,
  loading,
  favourites,
  setFavourite,
  currentUpdate,
  deleteKeyword,
  BlogReports,
  GetBlogReports,
  updateBlogsList,
}) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [key, setKey] = React.useState(null);
  const [activeKey, setActiveKey] = React.useState('');
  const [activeTopic, setActiveTopic] = React.useState('');
  const [newBlog, setNewBlog] = React.useState(false);
  const [blogsList, setBlogsList] = React.useState([]);
  
  React.useEffect(() => {
    getFavourites();
  }, []);

  React.useEffect(() => {
    if (favourites && favourites.length > 0) {
      if (activeKey === '') {
        setActiveKey(favourites[0].key);
        setActiveTopic(favourites[0].topic);
      }
      setBlogsList(favourites);
    }
  }, [favourites]);

  const addNewKeyword = () => {
    var key = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
    let favouriteArray = favourites;
    if (favourites && favourites.length) {
      favouriteArray.push({ key, keyword: [] });
      setFavourite(JSON.parse(JSON.stringify(favouriteArray)));
    } else {
      favouriteArray = [];
      favouriteArray.push({ key: 'keyword1', keyword: [] });
      setFavourite(JSON.parse(JSON.stringify(favouriteArray)));
    }
    setActiveKey(key);
    setActiveTopic('');
    setNewBlog(true);
  };

  const RenderBlogsList = (item, index) => {
    return (
      <Draggable draggableId={index + ''} index={index} key={index}>
        {provided => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div className="tab-name">
              <div className="tab-indexes">{item.topic ? item.topic : `Keyword ${index + 1}`}</div>
              <Glyphicon
                glyph="glyphicon glyphicon-trash"
                className="tab-delete-icon"
                style={{ top: '2px' }}
                onClick={() => {
                  setKey(item.key);
                  setShowConfirm(true);
                }}
              />
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  const onDragEnd = result => {
    const { destination, source, reason } = result;

    if (!destination || reason === 'CANCEL') {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const blogs = Object.assign([], blogsList);
    const droppedBlog = blogs[source.index];
    blogs.splice(source.index, 1);
    blogs.splice(destination.index, 0, droppedBlog);
    setBlogsList(blogs);
    updateBlogsList(blogs);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs Management</title>
      </Helmet>
      <Confirm
        show={showConfirm}
        message="Are you sure you want to delete this blog?"
        subHeading={null}
        onConfirm={() => {
          setShowConfirm(false);
          deleteKeyword({ key });
        }}
        onCancel={() => setShowConfirm(false)}
      />
      <Row className="box-favourites-settings" style={{ marginTop: '3%', marginBottom: '3%' }}>
        <Col lg={10} mdOffset={1} md={10}>
          <Box className="box-upload-image">
            <h4 style={{ paddingBottom: '2%' }}> Blog Settings </h4>
            {loading ? (
              <Throbber style={{ position: 'relative', top: '40%' }} />
            ) : (
              <>
                <>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey={(currentUpdate && currentUpdate) || 0}
                  >
                    <Row>
                      <Col sm={4}>
                        <Droppable droppableId="dp1">
                          {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              <Nav bsStyle="pills" stacked>
                                {blogsList != null &&
                                  blogsList.map((item, index) => (
                                    <NavItem
                                      eventKey={index}
                                      style={{
                                        textAlign: 'left',
                                      }}
                                      onClick={() => {
                                        setActiveKey(item.key);
                                        setActiveTopic(item.topic);
                                        setNewBlog(false);
                                      }}
                                    >
                                      {RenderBlogsList(item, index)}
                                    </NavItem>
                                  ))}
                                <NavItem
                                  eventKey={favourites && favourites.length}
                                  style={{ textAlign: 'left' }}
                                  onClick={(e) => {
                                    e.currentTarget.blur()
                                    addNewKeyword();
                                  }}
                                >
                                  <div className="add-new-keyword">
                                    Add New{' '}
                                    <Glyphicon
                                      glyph="glyphicon glyphicon-plus"
                                      className="slider-delete-icon"
                                      style={{ float: 'right', top: '1px' }}
                                    />
                                  </div>
                                </NavItem>
                              </Nav>
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </Col>
                      <Col sm={8}>
                        <ComponentKeyword
                          blogreports={BlogReports.data.researches}
                          uniqueKey={activeKey}
                          topicName={activeTopic}
                          newBlog={newBlog}
                        />
                      </Col>
                    </Row>
                  </Tab.Container>
                </>
              </>
            )}
          </Box>
        </Col>
      </Row>
    </DragDropContext>
  );
};

const mapStateToProps = ({
  research: { BlogReports },
  Config: {
    favourites: {
      loading,
      data: { favourites, currentUpdate },
    },
  },
}) => ({ loading, favourites, currentUpdate, BlogReports });

const mapDispatchToProps = dispatch => ({
  saveFavourites: bindActionCreators(configActions.saveFavourites, dispatch),
  getFavourites: bindActionCreators(configActions.getFavourites, dispatch),
  setFavourite: bindActionCreators(configActions.setFavourite, dispatch),
  updateBlogsList: bindActionCreators(configActions.updateBlogsList, dispatch),
  deleteKeyword: bindActionCreators(configActions.deleteKeyword, dispatch),
  getPosts: bindActionCreators(configActions.getPosts, dispatch),
  GetBlogReports: bindActionCreators(reportActions.GetBlogReports, dispatch),
});

Container.propTypes = {
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  currentTab: PropTypes.string,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
