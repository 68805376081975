const initialState = {
  sector: {
    loading: false,
    error: null,
    data: null,
  },
  sectors: {
    loading: false,
    error: null,
    data: [],
  },
};

export default (state = initialState, { type, error, data }) => {
  switch (type) {
    case 'GET_SECTOR':
      return {
        ...state,
        sector: {
          ...state.sector,
          loading: true,
        },
      };

    case 'GET_SECTOR_SUCCESS':
      return {
        ...state,
        sector: {
          ...state.sector,
          loading: false,
          data,
        },
      };

    case 'GET_SECTOR_FAIL':
      return {
        ...state,
        sector: {
          ...state.sector,
          loading: false,
          data,
        },
      };

    case 'GET_SECTORS':
      return {
        ...state,
        sectors: {
          ...state.sectors,
          loading: true,
        },
      };

    case 'GET_SECTORS_SUCCESS':
      return {
        ...state,
        sectors: {
          ...state.sectors,
          loading: false,
          data,
        },
      };

    case 'GET_SECTORS_FAIL':
      return {
        ...state,
        sectors: {
          ...state.sectors,
          loading: false,
          error,
        },
      };

    default:
      return state;
  }
};
