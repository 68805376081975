/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 17 September 2020 : Aman
    * M-0003018: Animated logo image for when home page is loading.
  M002 : 17 September 2020 : Aman
    * M-0003012: Refresh of the homepage causes a strange box and a zero to appear for a short time before the page loads.
    
**************************************************************************************************************
*/

//====================M001=====[====================
//====================M002=====[====================
import React from 'react';
import Loader from '../../assets/images/aletheia-loader-2c-animated.gif';
import '../../assets/sass/index.scss';

const FullPageLoader = () => {
  return (
    <div className="fp-container">
      <img src={Loader} className="fp-loader" alt="loading" />
    </div>
  );
};

export default FullPageLoader;
//====================M002=====[====================
//====================M001=====[====================
