export const getAll = obj => ({
  type: 'GET_COUNTRIES',
  obj,
});

export const getAllSuccess = (data, obj) => ({
  type: 'GET_COUNTRIES_SUCCESS',
  data,
  obj,
});

export const getAllFail = (error, obj) => ({
  type: 'GET_COUNTRIES_FAILURE',
  error,
  obj,
});
