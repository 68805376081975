/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 27 Apr 2021 : Nick
    * M-0003382: Website UI Improve - Aletheia Connect
**************************************************************************************************************
*/	

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as videoActions from '../../actionCreators/Videos';
import HeaderConnect from '../../components/PageHeader';
import VideoList from './components/videosLists';
import { Helmet } from 'react-helmet';

const AletheiaConnect = ({ videos, getAllVideos }) => {
  
  return (
    <>
      <Helmet>
          <title>Aletheia CONNECT</title>
      </Helmet>
      <HeaderConnect pageName="CONNECT" pageDesc="Your Direct Access To Experts" />
      <br />
      <VideoList />
    </>
  );
};
const mapStateToProps = ({ videos: { videos } }) => ({ videos });
const mapDispatchToProps = dispatch => ({
  getAllVideos: bindActionCreators(videoActions.getAllVideos, dispatch),
});
AletheiaConnect.propTypes = {
  getAllVideos: PropTypes.func.isRequired,
  videos: PropTypes.object.isRequired,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AletheiaConnect));
