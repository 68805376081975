export const swiperOptions = {
    speed: 650,
    slidesPerView: 'auto',
    spaceBetween: 0,
    loop: true,
    // loopedSlides: 10,
    loopFillGroupWithBlank: false,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
    breakpoints: {
      2560: {
        slidesPerView: 9,
        spaceBetween: 20,
      },
      2160: {
        slidesPerView: 8,
        spaceBetween: 20,
      },
      1920: {
        slidesPerView: 7,
        spaceBetween: 20,
      },
      1440: {
        slidesPerView: 6,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      960: {
        slidesPerView: 3,
        spaceBetween: 25,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      468: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
    // scrollbar: {
    //   el: '.swiper-scrollbar',
    //   hide: false,
    //   direction: 'horizontal',
    //   freemode: true,
    //   draggable: true,
    //   snapOnRelease: true,
    // },
  }