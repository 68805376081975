export const signUp = (obj, history) => ({
  type: 'SIGNUP',
  obj,
  history,
});

export const signUpSuccess = data => ({
  type: 'SIGNUP_SUCCESS',
  data,
});

export const signUpFail = error => ({
  type: 'SIGNUP_FAIL',
  error,
});

export const logIn = (obj, history, redirectUrl) => ({
  type: 'LOGIN',
  obj,
  history,
  redirectUrl,
});

export const resetState = (obj) => ({
  type: 'RESET_STATE',
  obj
});

export const logInSuccess = payload => ({
  type: 'LOGIN_SUCCESS',
  payload,
  data: payload,
});

export const logInFail = error => ({
  type: 'LOGIN_FAIL',
  error,
});

export const logOut = () => ({
  type: 'LOGOUT',
});

export const logOutSuccess = () => ({
  type: 'LOGOUT_SUCCESS',
});

export const logOutFail = error => ({
  type: 'LOGOUT_FAIL',
  error,
});

export const changePassword = obj => ({
  type: 'CHANGE_USER_PASSWORD',
  obj,
});

export const changePasswordSuccess = obj => ({
  type: 'CHANGE_PASSWORD_SUCCESS',
  obj,
});

export const changePasswordFail = (obj, error) => ({
  type: 'CHANGE_PASSWORD_FAIL',
  obj,
  error,
});

export const getCurrentUser = (obj, history, redirectUrl) => ({
  type: 'GET_CURRENT_USER',
  obj,
  history,
  redirectUrl,
});

export const getCurrentUserSuccess = data => ({
  type: 'GET_CURRENT_USER_SUCCESS',
  data,
});

export const getCurrentUserFail = error => ({
  type: 'GET_CURRENT_USER_FAIL',
  error,
});

export const removeUser = obj => ({
  type: 'REMOVE_USER',
  obj,
});

export const removeUserSuccess = obj => ({
  type: 'REMOVE_USER_SUCCESS',
  obj,
});

export const removeUserFail = (obj, error) => ({
  type: 'REMOVE_USER_FAIL',
  obj,
  error,
});

export const userDeleteModal = obj => ({
  type: 'USER_DELETE_MODAL',
  obj,
});

export const setLoader = obj => ({
  type: 'SET_LOADER',
  obj,
});

export const ResetPassword = (obj, history, redirectUrl) => ({
  type: 'RESET_PASSWORD',
  obj,
  history,
  redirectUrl,
});