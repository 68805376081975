import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import AccessProjects from '../../../assets/images/access-projects.png';

const Section2 = () => {
  return (
    <div style={{ marginBottom: '50px' }}>
      <Stack justifyContent="center" alignItems="center">
        <div style={{ width: '65%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Stack className="section2-item">
                <Typography fontSize={16}>
                  Strong track record in Customised Advisory Consulting services with solid pedigree
                </Typography>
              </Stack>
              <Stack className="section2-item">
                <Typography fontSize={16}>
                  Advisory across all asset classes: listed equities, private credit, private
                  companies:
                  <ul>
                    <li>Customised research</li>
                    <li>Customised investment valuation</li>
                  </ul>
                </Typography>
              </Stack>
              <Stack className="section2-item">
                <Typography fontSize={16}>
                  Wide range of sector capabilities: Fintech, Financials, Technology, Consumer,
                  Consumer Tech, Sports, Conglomerates, Private Credit
                </Typography>
              </Stack>
              <Stack className="section2-item">
                <Typography fontSize={16}>
                  Global coverage – the US, EMEA, North Asia, ASEAN, Middle East
                </Typography>
              </Stack>
              <Stack className="section2-item">
                <Typography fontSize={16}>
                  Strong compliance and research review process to ensure objectivity and no
                  conflicts of interest
                </Typography>
              </Stack>
              <Stack className="section2-item">
                <Typography fontSize={16}>
                  Truly global distribution: product marketing / sales team covering key geographies
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Stack
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: '50px', marginBottom: '50px' }}
              >
                <Typography
                  fontSize={26}
                  color="#036ca6"
                  style={{ fontFamily: 'open_sanssemibold' }}
                >
                  Recent Projects
                </Typography>
              </Stack>
              <Stack justifyContent="center" alignItems="center">
                <img src={AccessProjects} alt="" />
              </Stack>
            </Grid>
          </Grid>
        </div>
      </Stack>
    </div>
  );
};

export default Section2;
