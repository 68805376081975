import React from 'react';
import { Tab, ButtonToolbar, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
import UserTable from './usersTable';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as adminActions from '../../../actionCreators/Admin';

const Spyder = ({getAllUsers, users}) => {
    React.useEffect(()=>{
        getAllUsers('spyder', '')
    },[])

    return (
        <>
            <UserTable userType={'spyder'} userData={users.data} loading={users.loading}></UserTable>
        </>
    )
};

const mapStateToProps = state => {
    const {
        admin: { users }
    } = state;
    return { users };
};

const mapDispatchToProps = dispatch => ({
    getAllUsers: bindActionCreators(adminActions.getAllUsers, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Spyder));