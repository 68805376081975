import { all, put, takeEvery } from 'redux-saga/effects';
import qs from 'querystring';

import {
  getStockInfoSuccess,
  getStockInfoFail,
  getCompanySuccess,
  getCompanyFail,
} from '../actionCreators/Company';
import { doGet } from '../utils/request';

function* getStockInfo(action) {
  try {
    const response = yield doGet(
      `https://www.alphavantage.co/query?function=GLOBAL_QUOTE&apikey=XCL6M383CHZ4FDFG?${qs.stringify(
        action.obj
      )}`
    );

    yield put(getStockInfoSuccess(response.data));
  } catch (err) {
    console.error(err);
    yield put(getStockInfoFail(err.message));
  }
}

function* getCompany(action) {
  try {
    const response = yield doGet(`/companies?${qs.stringify(action.obj)}`);
    yield put(getCompanySuccess(response.data));
  } catch (err) {
    yield put(getCompanyFail(err.message));
  }
}

export default function* watcher() {
  yield all([
    takeEvery('GET_COMPANY_STOCK_INFO', getStockInfo),
    takeEvery('GET_COMPANY', getCompany),
  ]);
}
