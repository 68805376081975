import React from 'react';
import { Helmet } from 'react-helmet';

const Container = () => (
  <>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontSize: '7rem', fontWeight: 'bold' }}>404</div>
      <p style={{ fontSize: '2rem' }}>Page not found</p>
    </div>
  </>
);

export default Container;
