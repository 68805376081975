import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Grid } from 'react-bootstrap';

const PostGenericFooter = ({ footerHTML }) => {
  
  return (
    <Grid>
        <Row>
            <Col xs={12} className="post-footer">
                <div dangerouslySetInnerHTML={{__html: footerHTML}}></div>
            </Col>
        </Row>
    </Grid>
  );
};

PostGenericFooter.propTypes = {
    footerHTML: PropTypes.string
};

PostGenericFooter.defaultProps = {
    footerHTML: ``
};

export default PostGenericFooter;