/*
**************************************************************************************************************
												R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	:	Nick Ng
	Date	:	10 Oct 2022
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************

**************************************************************************************************************
*/

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'core-js/es/array';
import 'core-js/es/object';
import 'core-js/es/string';
import 'url-search-params-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './config/store';

const urlParams = new URLSearchParams(window.location.search);
let rfsPdf = urlParams.get('rfsUrl');

if (rfsPdf) {
  const splitData = rfsPdf.split('://');
  rfsPdf = `https://${splitData[1]}`;
  window.location.href = rfsPdf;
} else {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

