export function getStockInfo(obj) {
  return {
    type: 'GET_COMPANY_STOCK_INFO',
    obj,
  };
}

export function getStockInfoSuccess(payload) {
  return {
    type: 'GET_COMPANY_STOCK_INFO_SUCCESS',
    payload,
  };
}

export function getStockInfoFail(error) {
  return {
    type: 'GET_COMPANY_STOCK_INFO_FAIL',
    error,
  };
}

export const getCompany = obj => ({
  type: 'GET_COMPANY',
  obj,
});

export const getCompanySuccess = payload => ({
  type: 'GET_COMPANY_SUCCESS',
  payload,
});

export const getCompanyFail = error => ({
  type: 'GET_COMPANY_FAIL',
  error,
});
