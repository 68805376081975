/*
**************************************************************************************************************
            ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
            Amendments
========================================================================
  M001 : 2 Sep 2020 : Nicholas
    * M-3008: Increase the number of homepage banners
  M002 : 24 Nov 2020 : Aman
    * M-3069: Allow arranging the order of the homepage sliders
  M003 : 3 Dec 2020 : Aman
    * M-3143: Allow input mailto:xxx@xx.com link for banner
  M004 : 19 May 2022 : Adil
    * M-3944: Deleting a homepage slider sometimes deletes 2 instead of the one selected
  M005 : 27 Jul 2022 : Nick Ng
    * M-4060: Research portal should allow ,gif upload for the homepage banner

**************************************************************************************************************
*/

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
//====================M002==========================
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
//====================M002==========================
import { Helmet } from 'react-helmet';
import Notifications, { notify } from 'react-notify-toast';
import {
  Row,
  Image,
  Col,
  FormGroup,
  Button,
  Well,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  NavItem,
  Tab,
  Nav,
  Glyphicon,
  FormControl,
  Jumbotron,
  ControlLabel,
  Form,
} from 'react-bootstrap';

import Box from '../../components/Box';
import Confirm from '../../components/Confirm';
import Throbber from '../../components/Throbber';
import '../../assets/sass/admin-panel.scss';

import * as configActions from '../../actionCreators/Config';

const HEADING_LENGTH = 75;
const SUB_HEADING_LENGTH = 100;
const VIDEO_SIZE = 15;
const Container = ({
  homePageSliders,
  uploadHeaderImage,
  getImage,
  loading,
  history,
  saveSliderSettings,
  //====================M002==========================
  saveSliderOrder,
  //====================M002==========================
  //====================M001==========================
  setSlider,
  //====================M001==========================
  setSliderState,
  sliderSettingsTab,
  disableSaveButton,
  deleteDisabled, // M004
  leavingPage,
}) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [error, setError] = React.useState();
  const [warning, setWarning] = React.useState();
  const [sizeError, setSizeError] = React.useState();
  const [componentError, setComponentError] = React.useState([]);
  //====================M002==========================
  const [slide, setSlide] = React.useState([]);
  const [firstTimeSlides, setFirstTimeSlides] = React.useState([]);
  //====================M002==========================
  const [selectedSlide, SetSelectedSlide] = React.useState(0);
  //====================M001==========================
  const addNewSlide = (key, name) => {
    let slideArray = homePageSliders;
    if (homePageSliders && homePageSliders.length) {
      slideArray.push({ key, name, type: 'image' }); // M004
      setSlider(JSON.parse(JSON.stringify(slideArray)));
    } else {
      slideArray = [];
      slideArray.push({ key: 'slider1', name: 'Slider 1', type: 'image' });
      setSlider(JSON.parse(JSON.stringify(slideArray)));
    }
  };
  //====================M001==========================
  React.useEffect(() => {
    getImage({ key: 'HomePageSlider' });
  }, [getImage]);

  //====================M002==========================
  React.useEffect(() => {
    setFirstTimeSlides(homePageSliders);
  }, [homePageSliders]);

  React.useEffect(() => {
    if (firstTimeSlides != null && firstTimeSlides.length > 0) {
      var slideArr = firstTimeSlides;
      for (var i = 0; i < slideArr.length; i++) {
        slideArr[i].key = 'slide' + (i + 1);
        slideArr[i].name = 'slide ' + (i + 1);
      }
      setSlide(slideArr);
    }
  }, [firstTimeSlides]);
  //====================M002==========================
  const validUrl = value => {
    if (
      //====================M003==========================
      !/^https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9](\.|\:)?[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9](\.|\:)?[^\s]{2,}|mailto:([^\?]*)$/i.test(
        value
        //====================M003==========================
      )
    ) {
      if (value.length === 0) {
        return false;
      }
      return true;
    }
    return false;
  };

  const saveSettings = () => {
    // Sanitize the object of video if no image/video is selected
    const sanitizedSlidersDataV1 = slide.map(obj =>
      (obj.type === 'image' && obj.tempImage === null) ||
      (obj.type === 'video' && obj.tempVideo === null)
        ? { ...obj, active: false, type: null }
        : obj
    );
    // Sanitize the object of PDF URL if no url heading
    const sanitizedSlidersDataV2 = sanitizedSlidersDataV1.map(obj =>
      obj.type === 'url' && !obj.reportUrl && !obj.heading && !obj.subHeading
        ? { ...obj, active: false, type: null }
        : obj
    );
    saveSliderSettings(sanitizedSlidersDataV2);
    const popUpColor = { background: '#337ab7', text: '#FFFFFF' };
    notify.show('Settings has been saved successfully!', 'custom', 5000, popUpColor);
  };

  const errorHandler = (method, index, type, message) => {
    if (method === 'setError') {
      const indexOfError = componentError.filter(x => x.index === index && x.type === type);
      if (indexOfError.length === 0) {
        const errorData = componentError;
        errorData.push({
          index,
          type,
          error: message,
        });
        setComponentError(errorData);
      }
    }
    if (method === 'removeError') {
      const indexOfError = componentError.filter(x => x.index === index && x.type === type);
      if (indexOfError.length > 0) {
        const errors = componentError.filter(v => v.type !== type);
        setComponentError(errors);
      }
    }
  };
  //====================M002==========================
  const RenderSlides = (item, index) => {
    return (
      <Draggable draggableId={index + ''} index={index} key={index}>
        {provided => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div className="tab-indexes">
              {item.active && <span className="active-dot" />}
              {!item.active && <span className="deactive-dot" />}
              {item.name} {item.type && <p className="tab-type-text">{`(${item.type})`}</p>}
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  const onDragEnd = result => {
    const { destination, source, reason } = result;

    if (!destination || reason === 'CANCEL') {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const slides = Object.assign([], slide);
    const droppedSlide = slides[source.index];
    slides.splice(source.index, 1);
    slides.splice(destination.index, 0, droppedSlide);
    setSlide(slides);
    saveSliderOrder(slides);
  };
  //====================M002==========================
  const onChangeHandler = (event, index, type) => {
    const file = event.target.files[0];
    if (type === 'image') {
      if (file.type.match('image/jpeg|image/png|image/gif')) { //M005
        let width = '';
        let height = '';

        const img = document.createElement('img');
        img.src = URL.createObjectURL(file);
        // eslint-disable-next-line func-names
        img.onload = function() {
          // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
          // The natural size is the actual image size regardless of rendering.
          // The 'normal' width/height are for the **rendered** size.

          width = img.naturalWidth || img.width;
          height = img.naturalHeight || img.height;
          // Do something with the width and height
          // Setting the source makes it start downloading and eventually call `onload`
          // warning for low dimension Images Upload
          if (height === 580 && width === 1280) {
            uploadHeaderImage({
              imageFile: file,
              sliderData: homePageSliders,
              index,
              type: 'image',
            });
            setWarning(null);
          } else {
            setWarning('Warninggg');
            setError(null);
          }
        };
      } else {
        setError(true);
        setWarning(null);
      }
    }
    if (type === 'video') {
      try {
        if (file.type.match('video/m4v|video/avi|video/mov|video/mpg|video/mpeg|video/mp4')) {
          const fileSize = (file.size / 1024 / 1024).toFixed(2);
          if (fileSize > VIDEO_SIZE) {
            setSizeError(true);
          } else {
            setSizeError(false);
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
              uploadHeaderImage({
                imageFile: file,
                sliderData: homePageSliders,
                index,
                duration: video.duration,
                format: file.type,
                type: 'video',
              });
            };
            video.src = URL.createObjectURL(file);
          }
        } else {
          setError(true);
          setWarning(null);
        }
      } catch (err) {
        // console.log('Please try again!');
      }
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Slider Management</title>
      </Helmet>
      <Row className="box-upload-image">
        <Col ld={12} mdOffset={2} md={8}>
          <Box className="box-upload-image text-center">
            <Confirm
              show={showConfirm}
              message="Are you sure you want to make changes to the HomePage Slider?"
              subHeading={null}
              onConfirm={() => {
                setShowConfirm(false);
                saveSettings();
              }}
              onCancel={() => setShowConfirm(false)}
            />            
            <Row>
              <h4 style={{ paddingBottom: '5%' }}> Homepage Slider </h4>
              {deleteDisabled &&  
                <Throbber
                  loading
                  style={{ position: 'relative', top: '40%', minHeight: 350 }}
                />
              }
              {slide && !deleteDisabled && (
                <Col sm={12} md={12}>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey={`slider_${selectedSlide}`}
                  >
                    <Row className="clearfix">
                      <Col sm={4}>
                        {/* //====================M002========================== */}
                        <Droppable droppableId="dp1">
                          {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              <Nav bsStyle="pills" stacked>
                                {slide != null &&
                                  slide.map((item, index) => (
                                    <NavItem
                                      eventKey={`slider_${index}`}
                                      style={{ textAlign: 'left' }}
                                      onClick={() => SetSelectedSlide(index)}
                                    >
                                      {RenderSlides(item, index)}
                                    </NavItem>
                                  ))}

                                {/*====================M001==========================*/}
                                <NavItem
                                  eventKey={homePageSliders && homePageSliders.length}
                                  style={{ textAlign: 'left' }}
                                  onClick={() => {
                                    addNewSlide(
                                      `slide${homePageSliders && homePageSliders.length + 1}`,
                                      `Slide ${homePageSliders && homePageSliders.length + 1}`
                                    );
                                  }}
                                >
                                  <div className="add-new-keyword">
                                    <Glyphicon
                                      glyph="glyphicon glyphicon-plus"
                                      className="slider-delete-icon"
                                      style={{ right: '8%', top: '1px' }}
                                    />
                                    Add New{' '}
                                  </div>
                                </NavItem>
                                {/*====================M001==========================*/}
                              </Nav>
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        {/* //====================M002========================== */}
                      </Col>
                      <Col sm={8}>
                        <Tab.Content animation>
                          {slide.length &&
                            slide.map((item, index) => (
                              <Tab.Pane eventKey={`slider_${index}`}>
                                {/* Image Upload Content */}
                                <div className="button-group-box">
                                  <ButtonToolbar>
                                    <ToggleButtonGroup
                                      type="radio"
                                      name="options"
                                      defaultValue={item.type}
                                    >
                                      <ToggleButton
                                        value="image"
                                        onClick={() => {
                                          const copyOfState = [...slide];
                                          copyOfState[index].type = 'image';
                                          copyOfState[index].active = true;
                                          setSliderState(copyOfState);
                                        }}
                                      >
                                        Image
                                      </ToggleButton>
                                      {/* <ToggleButton
                                        value="url"
                                        onClick={() => {
                                          const copyOfState = [...slide];
                                          copyOfState[index].type = 'url';
                                          copyOfState[index].active = true;
                                          setSliderState(copyOfState);
                                        }}
                                      >
                                        Url
                                      </ToggleButton>
                                      <ToggleButton
                                        value="video"
                                        onClick={() => {
                                          const copyOfState = [...slide];
                                          copyOfState[index].type = 'video';
                                          copyOfState[index].active = true;
                                          setSliderState(copyOfState);
                                        }}
                                      >
                                        Video
                                      </ToggleButton> */}
                                      <ToggleButton
                                        disabled={item.type === null || deleteDisabled} // M004
                                        value="null"
                                        onClick={() => {
                                          if (item.type !== null && !deleteDisabled) { // M004
                                            const copyOfState = [...slide];
                                            copyOfState[index].type = null;
                                            copyOfState[index].tempImage = null;
                                            copyOfState[index].tempVideo = null;
                                            copyOfState[index].active = false;
                                            const indexkey = copyOfState[index].key;
                                            const filteredarray = copyOfState.filter(
                                              x => x.key != indexkey
                                            );
                                            saveSliderOrder(filteredarray);
                                            setSlider(JSON.parse(JSON.stringify(filteredarray)));
                                          }
                                        }}
                                      >
                                        <Glyphicon
                                          glyph="glyphicon glyphicon-trash"
                                          className="slider-delete-icon"
                                        />
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  </ButtonToolbar>
                                </div>
                                {item.type === 'image' && (
                                  <>
                                    <Well>
                                      {loading ? (
                                        <Throbber
                                          loading
                                          style={{ position: 'relative', top: '40%' }}
                                        />
                                      ) : (
                                        <div className="banner-image-preview-box">
                                          {item.tempImage && !item.format ? (
                                            <Image
                                              className="banner-image-preview"
                                              src={item.tempImage}
                                              rounded
                                              id={`image-preview-${index}`}
                                              alt="banner image"
                                            />
                                          ) : (
                                            <p>No image available </p>
                                          )}
                                        </div>
                                      )}
                                    </Well>
                                    <span className="instruction-class">
                                      Instructions : Width of image should be 1280 pixels and height
                                      should be 580 pixels.{' '}
                                    </span>
                                    {error && (
                                      <div style={{ color: 'red', fontSize: 12 }}>
                                        {' '}
                                        Image should be in PNG, JPEG or GIF format only.{' '}
                                      </div>
                                    )}
                                    {warning && (
                                      <div
                                        style={{
                                          color: 'rgb(232 156 20)',
                                          fontSize: 12,
                                          fontWeight: '800',
                                          paddingTop: 5,
                                        }}
                                      >
                                        {' '}
                                        Warning: Image is not suitable for the required resolution .{' '}
                                      </div>
                                    )}
                                    <Row className="upload-bar">
                                      <FormGroup controlId="formInlineName">
                                        <Row className="text-center">
                                          <div className="text-center">
                                            <label className="custom-input">
                                              <input
                                                type="file"
                                                onChange={file =>
                                                  onChangeHandler(file, index, 'image')
                                                }
                                              />
                                            </label>
                                          </div>
                                        </Row>
                                        <Row>
                                          <Col
                                            componentClass={ControlLabel}
                                            sm={2}
                                            style={{ position: 'relative', top: '11px' }}
                                          >
                                            Link:
                                          </Col>
                                          <Col sm={10}>
                                            <FormControl
                                              type="url"
                                              placeholder="Enter Report URL"
                                              value={item.link}
                                              className="image-url-input"
                                              onChange={event => {
                                                const copyOfState = [...slide];
                                                copyOfState[index].link = event.target.value;
                                                const valid = validUrl(event.target.value);
                                                if (valid) {
                                                  errorHandler(
                                                    'setError',
                                                    index,
                                                    `${index}-url`,
                                                    'Url is not valid'
                                                  );
                                                }
                                                if (!valid) {
                                                  errorHandler(
                                                    'removeError',
                                                    index,
                                                    `${index}-url`,
                                                    null
                                                  );
                                                }
                                                setSliderState(copyOfState);
                                              }}
                                            />
                                          </Col>
                                          {componentError.length > 0 &&
                                            componentError.map(
                                              val =>
                                                val.index === index &&
                                                val.type === `${index}-url` && (
                                                  <div
                                                    style={{
                                                      color: 'red',
                                                      fontSize: 12,
                                                      paddingLeft: '10%',
                                                    }}
                                                  >
                                                    <p>{val.error}</p>
                                                  </div>
                                                )
                                            )}
                                        </Row>
                                      </FormGroup>
                                    </Row>
                                    <Col sm={12}>
                                      <div className="auth-checkbox">
                                        <label className="admin-checkbox-container">
                                          <p>Show this to authenticated users only</p>
                                          <input
                                            className="admin-checkbox"
                                            type="checkbox"
                                            label="Show this to authentiated users only"
                                            checked={item.isPrivate ? item.isPrivate : false}
                                            onChange={event => {
                                              const copyOfState = [...slide];
                                              copyOfState[index].isPrivate = event.target.checked;
                                              setSliderState(copyOfState);
                                            }}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                    </Col>
                                  </>
                                )}
                                {item.type === 'url' && (
                                  <Form>
                                    <FormGroup>
                                      <Col componentClass={ControlLabel} sm={4}>
                                        Link:
                                      </Col>
                                      <Col sm={8}>
                                        <FormControl
                                          type="url"
                                          placeholder="Enter Report URL"
                                          value={item.reportUrl}
                                          onChange={event => {
                                            const copyOfState = [...slide];
                                            copyOfState[index].reportUrl = event.target.value;
                                            if (validUrl(event.target.value)) {
                                              errorHandler(
                                                'setError',
                                                index,
                                                `${index}-pdfUrl`,
                                                'Url is not valid'
                                              );
                                            } else {
                                              errorHandler(
                                                'removeError',
                                                index,
                                                `${index}-pdfUrl`,
                                                null
                                              );
                                            }
                                            setSliderState(copyOfState);
                                          }}
                                        />
                                      </Col>
                                    </FormGroup>
                                    {componentError.length > 0 &&
                                      componentError.map(
                                        val =>
                                          val.index === index &&
                                          val.type === `${index}-pdfUrl` && (
                                            <div
                                              style={{
                                                color: 'red',
                                                fontSize: 12,
                                                paddingRight: '10%',
                                              }}
                                            >
                                              <p>{val.error}</p>
                                            </div>
                                          )
                                      )}
                                    <FormGroup>
                                      <Col componentClass={ControlLabel} sm={4}>
                                        Heading:
                                      </Col>
                                      <Col sm={8}>
                                        <FormControl
                                          type="text"
                                          placeholder="Enter Heading of Slider"
                                          value={item.heading}
                                          onChange={event => {
                                            const copyOfState = [...slide];
                                            if (
                                              event.target.value &&
                                              event.target.value.length > HEADING_LENGTH
                                            ) {
                                              errorHandler(
                                                'setError',
                                                index,
                                                `${index}-heading`,
                                                'Heading text limit exceeded'
                                              );
                                            } else {
                                              errorHandler(
                                                'removeError',
                                                index,
                                                `${index}-heading`,
                                                null
                                              );
                                            }
                                            copyOfState[index].heading = event.target.value;
                                            setSliderState(copyOfState);
                                          }}
                                        />
                                      </Col>
                                    </FormGroup>
                                    {componentError.length > 0 &&
                                      componentError.map(
                                        val =>
                                          val.index === index &&
                                          val.type === `${index}-heading` && (
                                            <div
                                              style={{
                                                color: 'red',
                                                fontSize: 12,
                                                paddingLeft: '5%',
                                              }}
                                            >
                                              <p>{val.error}</p>
                                            </div>
                                          )
                                      )}
                                    <FormGroup>
                                      <Col componentClass={ControlLabel} sm={4}>
                                        Sub Heading:
                                      </Col>
                                      <Col sm={8}>
                                        <FormControl
                                          type="text"
                                          placeholder="Enter Sub Heading of Slider"
                                          value={item.subHeading}
                                          onChange={event => {
                                            const copyOfState = [...slide];
                                            if (
                                              event.target.value &&
                                              event.target.value.length > SUB_HEADING_LENGTH
                                            ) {
                                              errorHandler(
                                                'setError',
                                                index,
                                                `${index}-subHeading`,
                                                'Sub Heading text limit exceeded'
                                              );
                                            } else {
                                              errorHandler(
                                                'removeError',
                                                index,
                                                `${index}-subHeading`,
                                                null
                                              );
                                            }
                                            copyOfState[index].subHeading = event.target.value;
                                            setSliderState(copyOfState);
                                          }}
                                        />
                                      </Col>
                                      {/* <Col sm={12}>
                                        <div className="auth-checkbox">
                                          <label className="admin-checkbox-container">
                                            <span>Show this to authenticated users only</span>
                                            <input
                                              className="admin-checkbox"
                                              type="checkbox"
                                              label="Show this to authentiated users only"
                                              checked={item.isPrivate ? item.isPrivate : false}
                                              onChange={event => {
                                                const copyOfState = [...slide];
                                                copyOfState[index].isPrivate = event.target.checked;
                                                setSliderState(copyOfState);
                                              }}
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        </div>
                                      </Col> */}
                                    </FormGroup>
                                    {componentError.length > 0 &&
                                      componentError.map(
                                        val =>
                                          val.index === index &&
                                          val.type === `${index}-subHeading` && (
                                            <div
                                              style={{
                                                color: 'red',
                                                fontSize: 12,
                                                paddingLeft: '10%',
                                              }}
                                            >
                                              <p>{val.error}</p>
                                            </div>
                                          )
                                      )}
                                  </Form>
                                )}
                                {item.type === 'video' && (
                                  <>
                                    <Well>
                                      {loading ? (
                                        <Throbber
                                          loading
                                          style={{ position: 'relative', top: '40%' }}
                                        />
                                      ) : (
                                        <>
                                          <div className="banner-image-preview-box">
                                            {item.tempVideo ? (
                                              <>
                                                <div className="player-wrapper-preview">
                                                  <ReactPlayer
                                                    className="react-player-preview"
                                                    url={item.tempVideo}
                                                    width="60%"
                                                    height="auto"
                                                    playing
                                                    controls
                                                    youtubeConfig={{ playerVars: { showinfo: 0 } }}
                                                    loop
                                                    muted
                                                    onDuration={duration => {
                                                      const copyOfState = [...slide];
                                                      const videoDuration =
                                                        copyOfState[index].duration;
                                                      if (
                                                        !videoDuration ||
                                                        videoDuration !== duration
                                                      ) {
                                                        copyOfState[index].duration = duration;
                                                        setSliderState(copyOfState);
                                                      }
                                                    }}
                                                  />
                                                </div>
                                              </>
                                            ) : (
                                              <p>No preview available </p>
                                            )}
                                          </div>
                                          {item.duration && (
                                            <p>Video Duration: {item.duration} seconds</p>
                                          )}
                                        </>
                                      )}
                                    </Well>
                                    <div>
                                      <span className="instruction-class">
                                        Instructions : Only (m4v,avi,mpg,mov,mpg,mpeg,mp4) type
                                        files allowed.
                                        <br />
                                        Size of video should be less than 15 Mbs
                                      </span>
                                      {error && (
                                        <div style={{ color: 'red', fontSize: 12 }}>
                                          {' '}
                                          Video should be in 'm4v,avi,mpg,mov,mpg,mpeg,mp4' format
                                          only.{' '}
                                        </div>
                                      )}
                                      {loading && (
                                        <div
                                          style={{
                                            color: 'rgb(232 156 20)',
                                            fontSize: 12,
                                            fontWeight: '800',
                                            paddingTop: 5,
                                          }}
                                        >
                                          {' '}
                                          Warning: Please do not switch the tabs while the video is
                                          uploading.{' '}
                                        </div>
                                      )}
                                      {sizeError && (
                                        <div style={{ color: 'red', fontSize: 12 }}>
                                          The size of this video exceeds the limit
                                          <br />
                                          Upload a video of the size lower than 15 mbs
                                        </div>
                                      )}
                                      <Row className="upload-bar">
                                        <FormGroup controlId="formInlineName">
                                          <div className="text-center">
                                            <label className="video-custom-input">
                                              <input
                                                type="file"
                                                onChange={file =>
                                                  onChangeHandler(file, index, 'video')
                                                }
                                              />
                                            </label>
                                          </div>
                                        </FormGroup>
                                      </Row>
                                      <Row>
                                        <Col sm={12}>
                                          <div className="auth-checkbox">
                                            <label className="admin-checkbox-container">
                                              <p>Show this to authenticated users only</p>
                                              <input
                                                className="admin-checkbox"
                                                type="checkbox"
                                                label="Show this to authentiated users only"
                                                checked={item.isPrivate ? item.isPrivate : false}
                                                onChange={event => {
                                                  const copyOfState = [...slide];
                                                  copyOfState[index].isPrivate =
                                                    event.target.checked;
                                                  setSliderState(copyOfState);
                                                }}
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                )}
                                {!item.type && (
                                  <div className="no-slider-set">
                                    {' '}
                                    <Jumbotron>
                                      <p>No slider available </p>
                                    </Jumbotron>{' '}
                                  </div>
                                )}
                              </Tab.Pane>
                            ))}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Col>
              )}
            </Row>
            <Row className="save-button-row">
              <Col sm={6} md={6} lg={6}></Col>
              <Col sm={6} md={6} lg={6}>
                <div className="save-button">
                  <Button type="submit" onClick={() => setShowConfirm(true)}>
                    Save Settings
                  </Button>
                </div>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
    </DragDropContext>
  );
};

const mapStateToProps = ({
  Config: {
    homePageSliders,
    sliderSettingsTab,
    disableSaveButton,
    uploadImage: { loading },
    deleteButton: { disabled: deleteDisabled }, // M004
    leavingPage,
  },
}) => ({ homePageSliders, deleteDisabled, loading, sliderSettingsTab, disableSaveButton, leavingPage });

const mapDispatchToProps = dispatch => ({
  uploadHeaderImage: bindActionCreators(configActions.uploadHeaderImage, dispatch),
  getImage: bindActionCreators(configActions.getHeaderImage, dispatch),
  //====================M001==========================
  setSlider: bindActionCreators(configActions.setSlider, dispatch),
  //====================M001==========================
  setSliderState: bindActionCreators(configActions.setSliderState, dispatch),
  saveSliderSettings: bindActionCreators(configActions.saveSliderSettings, dispatch),
  saveSliderOrder: bindActionCreators(configActions.saveSliderOrder, dispatch),
});

Container.propTypes = {
  homePageSliders: PropTypes.array,
  history: PropTypes.object.isRequired,
  loading: PropTypes.string,
  disableSaveButton: PropTypes.bool,
  sliderSettingsTab: PropTypes.number,
  uploadHeaderImage: PropTypes.func.isRequired,
  getImage: PropTypes.func.isRequired,
  saveSliderSettings: PropTypes.func.isRequired,
  saveSliderOrder: PropTypes.func.isRequired,
  setSliderState: PropTypes.func.isRequired,
};
Container.defaultProps = {
  homePageSliders: {},
  loading: false,
  disableSaveButton: true,
  sliderSettingsTab: 0,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
