import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Grid,
  Button,
  Form,
  DropdownButton,
  Dropdown,
  MenuItem,
  NavDropdown,
  FormControl,
  FormGroup,
  FormSelect,
  ControlLabel,
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import * as blogActions from '../../../actionCreators/Blog';
import * as configActions from '../../../actionCreators/Config';
import * as sectorActions from '../../../actionCreators/Sector';
import * as analystActions from '../../../actionCreators/Analyst';
import Throbber from '../../../components/Throbber';
import { MultiSelect } from 'react-multi-select-component';

const BlogSettings = ({
  uniqueKey,
  getBlogInfo,
  selectedBlog,
  setBlogInfo,
  getSectors,
  sectors,
  getAnalysts,
  analysts
}) => {
  //Disclaimer State
  const [editorDisclaimerState, setEditorDisclaimerState] = React.useState(EditorState.createEmpty());
  //Author State
  const [editorAuthorState, setEditorAuthorState] = React.useState(EditorState.createEmpty());
  //Title State
  const [title, setTitle] = React.useState('');
  //Sub-Title State
  const [subtitle, setSubTitle] = React.useState('');
  //Region State
  const [region, setRegion] = React.useState('');
  //Blog Type State
  const [blogType, setBlogType] = React.useState('');
  //Author Name State
  const [authorId, setAuthorId] = React.useState(-1);
  // Blog Access Type Dropdown Value
  const [blogAccessType, setBlogAccessType] = React.useState('pre');
  const [sectorsOptions, setSectorsOptions] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);

  const accessType = {
    'pre' : {
      'label' : 'Public Access'
    },
    'npi' : {
      'label' : 'Registered User Access'
    },
    'pi' : {
      'label' : 'Subscriber Access'
    },
  }

  //GET BLOG INFO
  React.useEffect(() => {
    getBlogInfo({ key: uniqueKey });
  }, [uniqueKey]);

  //TRANSFORM ARR AND SAVE TO OPTIONS
  React.useEffect(() => {
    getSectors();
    getAnalysts();
  }, []);

  React.useEffect(() => {
    if (sectors.data.length > 0) {
      const sectorArr = [];
      for (let sector of sectors.data){
        sectorArr.push({
          label: sector.sName,
          value: sector.id
        })
      }
      setSectorsOptions(sectorArr);
    }
  }, [sectors]);

  React.useEffect(() => {
    setTitle(selectedBlog.data.topic);
    setSubTitle(selectedBlog.data.subtitle);
    if (selectedBlog.data.blogType) {
      setBlogType(selectedBlog.data.blogType);
    }
    setRegion(selectedBlog.data.region);

    if (selectedBlog.data.footerHTML) {
      const blocksFromHTML = convertFromHTML(selectedBlog.data.footerHTML);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const contentState = EditorState.createWithContent(state);
      setEditorDisclaimerState(contentState);
    }
    if (selectedBlog.data.author) {
      const blocksFromHTML = convertFromHTML(selectedBlog.data.author.extraHTML);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const contentState = EditorState.createWithContent(state);
      setEditorAuthorState(contentState);
      setAuthorId(selectedBlog.data.author.id);
      setBlogAccessType(selectedBlog.data.accessType);
      if (selectedBlog.data.accessType == 'pi') {
        setSelectedSectors(selectedBlog.data.selectedSectors);
      }
    }
  }, [selectedBlog]);

  if (selectedBlog.loading) {
    return <Throbber style={{ position: 'relative', top: '40%' }} />;
  }

  const onDisclaimerEditorStateChange = editorState => {
    setEditorDisclaimerState(editorState);
  };

  const onAuthorEditorStateChange = editorState => {
    setEditorAuthorState(editorState);
  };

  const setAuthorItems = (analysts) => {
    const arr = [];
    if (analysts.data.length){
      for (let a of analysts.data){
        arr.push(<option value={a.id}>{a.name}</option>)
      }
    }
    return arr;
  }

  const sumbitBlogSetting = evt => {
    var footerHTML = draftToHtml(convertToRaw(editorDisclaimerState.getCurrentContent()));
    var authorHTML = draftToHtml(convertToRaw(editorAuthorState.getCurrentContent()));
    setBlogInfo({
      key: uniqueKey,
      keyword: [],
      topic: title,
      subtitle: subtitle,
      region: region,
      blogType: blogType,
      footerHTML: footerHTML,
      author: {
        iPSN_Id: authorId,
        extraHTML: authorHTML,
      },
      accessType: blogAccessType,
      selectedSectors: blogAccessType == 'pi' ? selectedSectors : [],
    });
    getBlogInfo({ key: uniqueKey });
  };
  return (
    <Grid>
      <Form
        onSubmit={event => {
          event.preventDefault();
          sumbitBlogSetting(event);
        }}
      >
        {/* Topic Name */}
        <Row>
          <Col xs={4} componentClass={ControlLabel} className="topic-label">
            Blog Name:
          </Col>
          <Col xs={8}>
            <FormControl
              type="text"
              name="topic"
              value={title}
              placeholder="Enter the blog name"
              className="topic-input"
              onChange={e => setTitle(e.target.value)}
              required
            />
          </Col>
        </Row>
        {/* Topic Name */}

        {/* Sub Title */}
        <Row>
          <Col xs={4} componentClass={ControlLabel} className="topic-label">
            Sub-Title:
          </Col>
          <Col xs={8}>
            <FormControl
              type="text"
              name="subtitle"
              value={subtitle}
              placeholder="Enter the sub-title"
              className="topic-input"
              onChange={e => setSubTitle(e.target.value)}
              required
            />
          </Col>
        </Row>
        {/* Sub Title */}

        {/* Region */}
        <Row>
          <Col xs={4} componentClass={ControlLabel} className="topic-label">
            Region:
          </Col>
          <Col xs={8}>
            <FormControl
              type="text"
              name="region"
              value={region}
              placeholder="Enter the region"
              className="topic-input"
              onChange={e => setRegion(e.target.value)}
            />
          </Col>
        </Row>
        {/* Region */}

        {/* Blog Type */}
        <Row>
          <Col xs={4} componentClass={ControlLabel} className="topic-label">
            Type:
          </Col>
          <Col xs={8}>
            <FormControl
              type="text"
              name="blogType"
              value={blogType}
              placeholder="Enter the blog type"
              className="topic-input"
              onChange={e => setBlogType(e.target.value)}
            />
          </Col>
        </Row>
        {/* Blog Type */}

        {/* Disclaimer */}
        <Row>
          <Col xs={4} componentClass={ControlLabel} className="topic-label">
            Disclaimer:
          </Col>
          <Col xs={8}>
            <Editor
              editorClassName={'post-content'}
              wrapperClassName={'post-content'}
              editorState={editorDisclaimerState}
              onEditorStateChange={onDisclaimerEditorStateChange}
            ></Editor>
          </Col>
        </Row>
        {/* Disclaimer */}

        {/* Autho Info */}
        <Row>
          <Col xs={4} componentClass={ControlLabel} className="topic-label">
            Author:
          </Col>
          <Col xs={8}>
            <FormControl
                componentClass="select"
                name="analyst"
                value={authorId}
                className="topic-input"
                onChange={e => setAuthorId(e.target.value)}
            >
            {setAuthorItems(analysts)}
            </FormControl>
          </Col>
        </Row>
        
        <Row>
          <Col xs={4} componentClass={ControlLabel} className="topic-label">
            Author Extra Details:
          </Col>
          <Col xs={8}>
            <Editor
              editorClassName={'post-content'}
              wrapperClassName={'post-content'}
              editorState={editorAuthorState}
              onEditorStateChange={onAuthorEditorStateChange}
            ></Editor>
          </Col>
        </Row>
        {/* Autho Info */}

        {/* Blog Access Type */}
        <Row>
          <Col xs={4} componentClass={ControlLabel} className="topic-label">
            Blog Access Type:
          </Col>
          <Col xs={8}>
            <FormControl
                componentClass="select"
                name="blogType"
                value={blogAccessType}
                placeholder="Enter the blog type"
                className="topic-input"
                onChange={e => setBlogAccessType(e.target.value)}
            >
              <option value='pre'>Public Access</option>
              <option value='npi'>Registered User Access</option>
              <option value='pi'>Subscriber Access</option>
            </FormControl>
          </Col>
        </Row>
        {blogAccessType === 'pi' && (
          <Row>
            <Col xs={4} componentClass={ControlLabel} className="topic-label">
              Subscribed Sector(s):
            </Col>
            <Col xs={8} style={{marginTop:10}}>
              <MultiSelect
                options={sectorsOptions}
                value={selectedSectors}
                onChange={setSelectedSectors}
                labelledBy="Select"
              />
            </Col>
          </Row>
        )}
        {/* Blog Access Type */}

        {/* SUBMIT BUTTON */}
        <Row className="save-button-row" style={{ textAlign: 'end' }}>
          <Button type="submit" bsStyle="primary">
            Save
          </Button>
        </Row>
        {/* SUBMIT BUTTON */}

      </Form>
    </Grid>
  );
};

BlogSettings.propTypes = {
  author: PropTypes.object,
  postContent: PropTypes.string,
  sectors: PropTypes.object.isRequired,
  getSectors: PropTypes.func.isRequired,
  getAnalysts: PropTypes.func.isRequired,
};

BlogSettings.defaultProps = {
  author: {
    name: '',
    email: '',
    extraHTML: ``,
  },
};

const mapDispatchToProps = dispatch => ({
  getBlogInfo: bindActionCreators(blogActions.getBlogInfo, dispatch),
  setBlogInfo: bindActionCreators(configActions.saveFavourites, dispatch),
  getSectors: bindActionCreators(sectorActions.getAll, dispatch),
  getAnalysts: bindActionCreators(analystActions.getAll, dispatch)
});

const mapStateToProps = ({
  blog: { selectedBlog },
  sector: { sectors },
  analyst: { analysts }
}) => ({
  selectedBlog,
  sectors,
  analysts
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BlogSettings));
