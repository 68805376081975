/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
    M001 : 24 Jun 2021 : Nick
    * M-0003465: Remove sub-title from event registration page
**************************************************************************************************************
*/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HeaderConnect from '../../components/PageHeader';
import MultiEventRegForm from './components/signupTable';
import SingleEventRegForm from './components/signupIndividualEvts';
import { Helmet } from 'react-helmet';

const AletheiaConnectReg = ({
  location: { pathname }
}) => {
  const id = pathname.split('/')[3];

  return (
    <>
        <Helmet>
            <title>CONNECT Registration</title>
        </Helmet>
        <HeaderConnect pageName="REGISTRATION" pageDesc="" /> {/*M001*/}
        <br />
        {id?(
          // RESERVED FOR SINGLE EVENT
          <SingleEventRegForm evtId={id} />
        ):(
          <MultiEventRegForm/>
        )}
    </>
  );
};

export default withRouter(connect()(AletheiaConnectReg));
