import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { getStandardDateTime } from '../../utils/dateTime';
import * as researchActions from '../../actionCreators/Research';
import HighlightedPanel from '../../components/HighlightedPanel';
import PostGenericHeader from './components/PostGenericHeader';
import PostGenericFooter from './components/PostGenericFooter';
import PostDataContainer from './components/PostDataContainer';
import { Helmet } from 'react-helmet';
import Box from '../../components/Box';
import * as blogActions from '../../actionCreators/Blog';
import FullPageLoader from '../../components/FullPageLoader';

import { doGet } from '../../utils/request';

//import stylesheet
import '../../assets/sass/blogs.scss';

const Container = ({
  getPost,
  post,
  getPostAssociatedResearches,
  getHighlightedResearches,
  highlightedResearches,
  selectedBlog,
  getBlogInfo,
  MostViewedReports,
  GetMostViewedReports,
  history,
  currentUser
}) => {
  let parameters = useLocation().pathname.split('/');
  var id = parameters[2];

  React.useEffect(() => {
    getHighlightedResearches();
    GetMostViewedReports();
    if (id != '' && id != undefined) {
      getPost({ id: id });
      getPostAssociatedResearches({ id: id });
    }
  }, []);

  React.useEffect(() => {
    
    if (post.data?.content?.requiresLogin) {
      history.push('/login');
    }

  }, [post.data?.content])

  React.useEffect(() => {
    if (
      post.data.content &&
      !post.loading &&
      !post.data.content.error
    ) {
      getBlogInfo({ key: post.data.content.posts[0].key });
    }
  }, [post.data?.content]);

  //Quit Page Event
  React.useEffect(() => {
    window.addEventListener('beforeunload', async ()=>{
      await doGet(`/post/leave/${id}`)
      return;
    });
    return history.listen(async (location, action) => {
      await doGet(`/post/leave/${id}`)
      return;
    });
  },[])

  //Switch Page

  if (post.loading || !post.data.content) {
    return <FullPageLoader></FullPageLoader>;
  }

  return (
    <>
      <Helmet>
        <title>{selectedBlog.data.topic}</title>
      </Helmet>
      <Row>
        <Col xs={12} lg={8}>
          <div className="blogsDiv">
            <Box className="pdf-viewer-section">
              {/* ERROR BOX */}
              {
                post.data.content.error ? (
                  <div style={{ borderRadius: 5, textAlign: 'center', marginTop: 10 }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: post.data.content.error,
                      }}
                    />
                  </div>
                )
                :null
              }
              {/* ERROR BOX */}
              {/* Blog Post Header (With Logo, Date, Title) */}
              {post.loading || post.data.content.error ? null : (
                <PostGenericHeader
                  title={selectedBlog.data.topic}
                  subtitle={selectedBlog.data.subtitle}
                  region={selectedBlog.data.region}
                  blogType={selectedBlog.data.blogType}
                  date={getStandardDateTime(post.data.content.posts[0].created_at, {
                    format: 'D MMM YYYY',
                  })}
                  history={history}
                  user={currentUser}
                />
              )}
              {/* Blog Post Header (With Logo, Date, Title) */}

              {/* Blog Post Body (Left Panel: Author, Right Panel: Content) */}
              {post.loading || post.data.content.error ? null : (
                <PostDataContainer
                  title={post.data.content.posts[0].title}
                  postContent={JSON.parse(post.data.content.posts[0].draft)}
                  author={selectedBlog.data.author}
                />
              )}

              {/* Blog Post Body (Left Panel: Author, Right Panel: Content) */}

              {/* Blog Post Footer (Disclaimer) */}
              {post.loading || post.data.content.error ? null : (
                <PostGenericFooter footerHTML={selectedBlog.data.footerHTML} />
              )}
              {/* Blog Post Footer (Disclaimer) */}
            </Box>
          </div>
        </Col>
        {/* PLACE HOLDER FOR HIGHLIGHTED RESEARCH */}
        {post.loading || !post.data.associatedResearches ? null : post.data.associatedResearches.researches.length > 0 ? (
          <HighlightedPanel
            loading={highlightedResearches.loading}
            panelText={['Associated Research', 'Must Read']}
            leftResearches={
              post.data.associatedResearches.researches
                ? post.data.associatedResearches.researches
                : []
            }
            rightResearches={highlightedResearches.data ? highlightedResearches.data : []}
            Bookmark={false}
          />
        ) : (
          <HighlightedPanel
            loading={highlightedResearches.loading}
            panelText={['Must Read', 'Most Viewed']}
            leftResearches={highlightedResearches.data ? highlightedResearches.data : []}
            rightResearches={MostViewedReports.data ? MostViewedReports.data.researches : []}
            Bookmark={false}
          />
        )}
        {/* PLACE HOLDER FOR HIGHLIGHTED RESEARCH */}
      </Row>
    </>
  );
};

const mapStateToProps = ({
  analyst: { analyst },
  research: { highlightedResearches, MostViewedReports },
  user: { currentUser },
  blog: { post, selectedBlog },
}) => ({
  analyst,
  currentUser,
  highlightedResearches,
  post,
  selectedBlog,
  MostViewedReports,
});

const mapDispatchToProps = dispatch => ({
  getPost: bindActionCreators(blogActions.getPost, dispatch),
  getHighlightedResearches: bindActionCreators(researchActions.getHighlighted, dispatch),
  GetMostViewedReports: bindActionCreators(researchActions.GetMostViewedReports, dispatch),
  getPostAssociatedResearches: bindActionCreators(
    blogActions.getPostAssociatedResearches,
    dispatch
  ),
  getBlogInfo: bindActionCreators(blogActions.getBlogInfo, dispatch),
});

Container.propTypes = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)
(Container));
