/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 24 August 2020 : Nicho
		  * M-0002943: Homepage - Global header redesign
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Navbar, Form, FormControl, Glyphicon } from 'react-bootstrap';

const Component = ({ history }) => {
  const [keyword, setKeyword] = React.useState('');

  return (
    <Navbar.Form pullLeft id="searchBox" className="search-box">
      <Form
        onSubmit={() => {
          if (keyword) {
            history.push(`/search?q=${keyword}`);
          }
        }}
      >
        <FormControl
          type="text"
          placeholder="         "
          value={keyword}
          //====================M001==========================
          id="searchInput"
          //====================M001==========================
          onChange={e => setKeyword(e.target.value)}
        />
        <Glyphicon
          glyph="glyphicon glyphicon-search"
          className="search-icon"
          //====================M001==========================
          onClick={() => {
            document.getElementById("searchInput").focus();
            if (keyword) {
              history.push(`/search?q=${keyword}`);
            }
          }}
          //====================M001==========================
        />
      </Form>
    </Navbar.Form>
  );
};

Component.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Component);
