
import GlobalStratIcon from '../../../../assets/images/reports/icons/global-strat.png';
import AsiaMacroIcon from '../../../../assets/images/reports/icons/asia-macro.png';
import AsiaStratIcon from '../../../../assets/images/reports/icons/asia-strat.png';
import AsiaEqIcon from '../../../../assets/images/reports/icons/asia-eq-strat.png';
import TechIcon from '../../../../assets/images/reports/icons/tech-strat.png';
import ChinaStratIcon from '../../../../assets/images/reports/icons/china-strat.png';
import EnergyIcon from '../../../../assets/images/reports/icons/energy.png';
import FinaIcon from '../../../../assets/images/reports/icons/financials.png';
import InternetIcon from '../../../../assets/images/reports/icons/internet.png';
import HardwareIcon from '../../../../assets/images/reports/icons/hardware.png';
import EcomIcon from '../../../../assets/images/reports/icons/ecom-log.png';
import TelecomIcon from '../../../../assets/images/reports/icons/telecom.png';
import Aletheia360 from '../../../../assets/images/reports/icons/360.png';


export const StratIcons = [
  { src: GlobalStratIcon, alt: 'Global Strategy', sector: '/sectors/10000131' },
  { src: AsiaEqIcon, alt: 'Asia Equity Strategy', sector: '/sectors/10000047' },
  { src: AsiaMacroIcon, alt: 'Asianomics Macro', sector: '/sectors/10000133' },
  { src: AsiaStratIcon, alt: 'Asianomics Strategy', sector: '/sectors/10000130' },
  { src: TechIcon, alt: 'Tech Thematic Strategy', sector: '/sectors/10000087' },
  { src: EnergyIcon, alt: 'Energy', sector: '/sectors/10000136' },
  { src: FinaIcon, alt: 'Financials', sector: '/sectors/10000137' },
  { src: InternetIcon, alt: 'Internet', sector: '/sectors/10000132' },
  { src: HardwareIcon, alt: 'Technology Hardware', sector: '/sectors/10000126' },
  { src: EcomIcon, alt: 'Ecommerce Logistics', sector: '/sectors/10000139' },
  { src: Aletheia360, alt: 'Aletheia 360', sector: '/sectors/10000138' },
  { src: ChinaStratIcon, alt: 'China Strategy', sector: '/sectors/10000142' },
  { src: TelecomIcon, alt: 'Telecoms', sector: '/sectors/10000140' },
];