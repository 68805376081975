/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { getStandardDateTime } from '../../utils/dateTime';
import { sanitizeText } from '../../utils';

var date = new Date();
date.setDate(date.getDate() - 7);
var sevenDays = getStandardDateTime(date);

export const reportLabels = (
  handleHighlightReport,
  fetchLatestDoc,
  handleTempReport,
  tempResearches,
  highlightedResearches
) => [
  {
    sortable: false,
    key: 'title',
    label: 'Title',
    render: (val, obj) => (
      <Link
        href={
          obj.video
            ? `/connect/videos/${obj.video}`
            : `/reports/${obj.id}_${sanitizeText(obj.title)}`
        }
        to={
          obj.video
            ? `/connect/videos/${obj.video}`
            : `/reports/${obj.id}_${sanitizeText(obj.title)}`
        }
      >
        {val}
      </Link>
    ),
  },
  {
    sortable: false,
    key: 'analysts[0].name',
    label: 'Analyst',
  },
  {
    sortable: false,
    key: 'companies',
    label: 'Company',
    render: val => val && val.map(({ sName }) => sName).join(', '),
  },
  {
    sortable: false,
    key: 'countries',
    label: 'Geography',
    render: val => val && val.map(({ name }) => name).join(', '),
  },

  {
    sortable: false,
    key: 'published_date',
    label: 'Published On',
    render: val => getStandardDateTime(val),
  },
  {
    sortable: false,
    label: 'Must Read',
    render: (val, obj) => (
      <Button
        bsSize="small"
        className="highlight-button"
        disabled={highlightedResearches.loading}
        onClick={() => alert("Please configure in Spyder.")}
      >
        {highlightedResearches.some(item => item.id == obj.id) ? 'Remove' : 'Add'}
      </Button>
    ),
  },
  {
    sortable: false,
    label: 'Aletheia Select',
    render: (val, obj) => (
      <Button
        bsSize="small"
        className="highlight-button"
        disabled={
          new Date(getStandardDateTime(obj.published_date)) < new Date(sevenDays) ? false : true
        }
        onClick={() => alert("Please configure in Spyder.")}
      >
        {tempResearches.find(x => x.id == obj.id) ? 'Remove' : 'Add'}
      </Button>
    ),
  },
];
