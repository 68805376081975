/* eslint-disable */

import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import {
  Navbar,
  Nav,
  NavItem,
  Glyphicon,
  Image,
  Form,
  FormControl,
  Tabs,
  Tab,
  MenuItem,
  NavDropdown,
  FormGroup,
  Grid,
  Row,
  Col,
  Table,
  ButtonToolbar,
  DropdownButton,
  Panel,
  Pagination,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';

import Flag from '../../assets/images/americanFlag.svg';
import FilterButton from '../../assets/images/Filter2x-512.svg';
import FilterBox from '../../components/FilterBox';

const data = [
  {
    name: 'Jan 18',
    uv: 1000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Feb 18',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Mar 18',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Apr 18',
    uv: 2780,
    pv: 3908,
    amt: 1000,
  },
  {
    name: 'May 18',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Jun 18',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Jul 18',
    uv: 3490,
    pv: 4300,
    amt: 1900,
  },
  {
    name: 'Aug 18',
    uv: 1200,
    pv: 5800,
    amt: 1300,
  },
];

let active = 3;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(<Pagination.Item active={number === active}>{number}</Pagination.Item>);
}

class StyleGridContainer extends React.Component {
  componentDidMount() {}

  /** @description Function to render the Header Component
   */
  renderHeaderComponent = () => (
    <>
      <Navbar style={{ padding: '0.6rem 3rem', background: '#036ca6' }}>
        <Navbar.Header>
          <Navbar.Brand>
            <Image
              className="logo-hd"
              style={{ padding: '0px' }}
              src="/images/login-logo.png"
              alt="Logo"
            />
          </Navbar.Brand>
        </Navbar.Header>
        <Nav
          className="custom-nav-Items"
          style={{ color: '#white', fontSize: '15px', fontWeight: '800' }}
        >
          <NavItem eventKey={1} href="#">
            Research
          </NavItem>
          <NavItem eventKey={2} href="#">
            My Page
          </NavItem>
          <NavDropdown eventKey={3} title="Stock Page" id="basic-nav-dropdown">
            <MenuItem eventKey={3.1}>Action</MenuItem>
            <MenuItem eventKey={3.2}>Another action</MenuItem>
            <MenuItem eventKey={3.3}>Something else here</MenuItem>
            <MenuItem divider />
            <MenuItem eventKey={3.4}>Separated link</MenuItem>
          </NavDropdown>
        </Nav>
        <Navbar.Form pullLeft>
          <FormGroup className="aletheia-msh-content active" style={{ padding: '0.6rem' }}>
            <FormControl type="text" placeholder="Search" />
            <Glyphicon
              glyph="glyphicon glyphicon-search"
              className="aletheia-msi-content"
              style={{ top: '2px', right: '20px', color: '#036ca6ad' }}
            />
          </FormGroup>{' '}
        </Navbar.Form>
        {this.renderProfileIcon()}
      </Navbar>
    </>
  );

  /** @description Function to render the User Profile Icon
   */
  renderProfileIcon = () => (
    <>
      <div className="profile-section">
        <div className="user-profile-icon">
          <h2 className="user-profile-name"> TH </h2>
        </div>
        <Nav
          className="custom-nav-Items"
          style={{ color: '#white', fontSize: '15px', padding: '0px' }}
        >
          <NavItem eventKey={1} href="#">
            SIGN OUT
          </NavItem>
        </Nav>
      </div>
    </>
  );

  /** @description Function to render the Stocks Info Card
   */
  renderStocksCard = () => (
    <div className="stocksCard">
      <div className="stocksCardDate">12/2/2019</div>
      <h2 className="stocksCardTitle">Apple Inc</h2>
      <div style={{ paddingTop: '10px' }}>
        <Image className="countryFlag" alt="flag" src={Flag} />
        <span className="companyCode">AAPL</span>
        <span style={{ fontSize: '10px', color: '#6d6d6d', paddingRight: '14px', float: 'right' }}>
          Conservative, Large Cap, High Flyer
        </span>
      </div>
      <div className="line"></div>
      <div>
        <span className="companyCode" style={{ padding: '4%', fontSize: '15px' }}>
          {' '}
          $ 221.1{' '}
        </span>
        <span style={{ fontSize: '15px', color: '#f3211d', paddingRight: '14px', float: 'right' }}>
          <span
            className="glyphicon glyphicon-triangle-bottom"
            style={{ right: '24px', top: '2px', fontSize: '12px' }}
          ></span>
          -1.8 , -0.8 %
        </span>
      </div>
      <div className="stocksCardLinks">
        <a>Company Page |</a>
        <a> Research |</a>
        <a> Financial</a>
      </div>
    </div>
  );

  /** @description Function to render the News Feed
   */
  renderNewsFeed = () => (
    <div>
      <div
        className="stocksCard"
        style={{ height: '70px', margin: '7px 0px 7px 0px', display: 'flex' }}
      >
        <Image className="newsFeedImage" alt="newsFeedImage" src="https://tinyurl.com/y35skfhh" />
        <div style={{ margin: '5px' }}>
          <span className="newsFeedHeading">
            Lorem ipsum dolor sit amet, consectetur adipiscing.
          </span>{' '}
          <br />
          <span>
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
          </span>
        </div>
        <div className="newsFeedTime">2.5 min ago</div>
      </div>
      <div
        className="stocksCard"
        style={{ height: '70px', margin: '7px 0px 7px 0px', display: 'flex' }}
      >
        <Image className="newsFeedImage" alt="newsFeedImage" src="https://tinyurl.com/y5555clk" />
        <div style={{ margin: '5px' }}>
          <span className="newsFeedHeading">Lorem ipsum dolor sit amet, consectetur.</span> <br />
          <span>
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur
          </span>
        </div>
        <div className="newsFeedTime">1 h ago</div>
      </div>
      <div
        className="stocksCard"
        style={{ height: '70px', margin: '7px 0px 7px 0px', display: 'flex' }}
      >
        <Image className="newsFeedImage" alt="newsFeedImage" src="https://tinyurl.com/yxbzlpm8" />
        <div style={{ margin: '5px' }}>
          <span className="newsFeedHeading">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </span>{' '}
          <br />
          <span>
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur
          </span>
        </div>
        <div className="newsFeedTime">3.5 h ago</div>
      </div>
    </div>
  );

  /** @description Function to render the Charts
   */
  renderCharts = () => (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ fontWeight: '700', fontSize: '12px', color: '#036ca6' }}>Stocks Report</p>
        <ButtonToolbar>
          <DropdownButton
            title="Duration: For 1 year"
            id="dropdown-size-medium"
            style={{ color: '#6d6d6d', marginTop: '-5px' }}
          >
            <MenuItem eventKey="1">Action</MenuItem>
            <MenuItem eventKey="2">Another action</MenuItem>
            <MenuItem eventKey="3">Something else here</MenuItem>
            <MenuItem divider />
            <MenuItem eventKey="4">Separated link</MenuItem>
          </DropdownButton>
        </ButtonToolbar>
      </div>
      <AreaChart
        width={500}
        height={200}
        data={data}
        margin={{
          right: 50,
          left: -20,
          top: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="uv" stroke="#036ca6" fill="#4088be" />
      </AreaChart>
    </div>
  );

  /** @description Function to render the Latest Reports Tables
   */
  renderLatestReportsTable = () => (
    <>
      <div
        className="reportsTables"
        style={{ display: 'flex', color: '#6d6d6d', fontSize: '11px' }}
      >
        <Table responsive bordered={true}>
          <tbody>
            <tr>
              <td className="tablekeys">Issue Sub Type</td>
              <td>Common Stock</td>
            </tr>
            <tr>
              <td className="tablekeys">52 Week High</td>
              <td>233.47</td>
            </tr>
            <tr>
              <td className="tablekeys">Dividend Yield</td>
              <td>1.3%</td>
            </tr>
            <tr>
              <td className="tablekeys">P/E Ratio</td>
              <td>20.5</td>
            </tr>
          </tbody>
        </Table>
        <div style={{ width: '4%' }}></div>
        <Table responsive bordered={true}>
          <tbody>
            <tr>
              <td className="tablekeys">Exchange</td>
              <td>NASDAQ-GS</td>
            </tr>
            <tr>
              <td className="tablekeys">52 Week Low</td>
              <td>150.24</td>
            </tr>
            <tr>
              <td className="tablekeys">Annual Dividend</td>
              <td> $ 2.92 </td>
            </tr>
            <tr>
              <td className="tablekeys">Shares Outstanding </td>
              <td>4,829,926,000</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );

  /** @description Function to render the Tabs Components
   */
  renderTabsComponent = () => (
    <div className="tabsComponent">
      <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
        <Tab eventKey={1} title="Income Statement">
          <Table responsive striped condensed hover bordered={true}>
            <thead style={{ color: '#31708f' }}>
              <tr>
                <th>FYE Dec ($ m)</th>
                <th>FY15A</th>
                <th>FY16E</th>
                <th>FY17E</th>
                <th>FY18E</th>
                <th>FY19E</th>
                <th>FY20E</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Revenue</td>
                <td>3,22</td>
                <td>3,455</td>
                <td>3,953</td>
                <td>5,433</td>
                <td>5,837</td>
                <td>4,383</td>
              </tr>
              <tr>
                <td>COGS</td>
                <td>2,300</td>
                <td>3,3490</td>
                <td>4,3454</td>
                <td>2,456</td>
                <td>2,234</td>
                <td>3,234</td>
              </tr>
              <tr>
                <td>Gross Profile</td>
                <td>(12)</td>
                <td>(212)</td>
                <td>(2)</td>
                <td>(11)</td>
                <td>(2)</td>
                <td>(132)</td>
              </tr>
              <tr>
                <td>EBIDTA</td>
                <td>2</td>
                <td>2</td>
                <td>2</td>
                <td>2</td>
                <td>2</td>
                <td>2</td>
              </tr>
              <tr>
                <td>CAR OP</td>
                <td>0923</td>
                <td>34</td>
                <td>3</td>
                <td>32</td>
                <td>0923</td>
                <td>234</td>
              </tr>
              <tr>
                <td>Pre-Tax Profit</td>
                <td>0.33</td>
                <td>0.23</td>
                <td>0.54</td>
                <td>0.75</td>
                <td>0.86</td>
                <td>0.09</td>
              </tr>
              <tr style={{ fontWeight: '700' }}>
                <td>GAAP-FD-EPS ($)</td>
                <td>(45)</td>
                <td>(4)</td>
                <td>(2)</td>
                <td>(66)</td>
                <td>(34)</td>
                <td>(7)</td>
              </tr>
              <tr>
                <td>OPEX</td>
                <td>2,33</td>
                <td>2,221</td>
                <td>3,11</td>
                <td>6,31</td>
                <td>2,12</td>
                <td>8,1</td>
              </tr>
              <tr>
                <td>OPEX</td>
                <td>0.726</td>
                <td>0.123</td>
                <td>0.543</td>
                <td>0.86</td>
                <td>0.23</td>
                <td>0.97</td>
              </tr>
              <tr style={{ fontWeight: '700' }}>
                <td> EPS ($)</td>
                <td>0.89</td>
                <td>0.23</td>
                <td>0.90</td>
                <td>0.43</td>
                <td>0.98</td>
                <td>0.12</td>
              </tr>
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey={2} title="Balance Statement"></Tab>
      </Tabs>
    </div>
  );

  /** @description Function to render the Tabs Components
   */

  renderSingleTable = () => (
    <div className="singlePanelTable">
      <Panel>
        <Panel.Heading>
          <Panel.Title componentClass="h3"> Key Valuation Table </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Table responsive striped condensed hover>
            <thead style={{ color: '#31708f' }}>
              <tr>
                <th>FYE Dec ($ m)</th>
                <th>FY15A</th>
                <th>FY16E</th>
                <th>FY17E</th>
                <th>FY18E</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Revenue</td>
                <td>3,22</td>
                <td>3,455</td>
                <td>3,953</td>
                <td>5,433</td>
              </tr>
              <tr>
                <td>COGS</td>
                <td>2,300</td>
                <td>3,3490</td>
                <td>4,3454</td>
                <td>2,456</td>
              </tr>
              <tr>
                <td>Gross Profile</td>
                <td>(12)</td>
                <td>(212)</td>
                <td>(2)</td>
                <td>(11)</td>
              </tr>
              <tr>
                <td>EBIDTA</td>
                <td>2</td>
                <td>2</td>
                <td>2</td>
                <td>2</td>
              </tr>
              <tr>
                <td>CAR OP</td>
                <td>0923</td>
                <td>34</td>
                <td>3</td>
                <td>32</td>
              </tr>
              <tr>
                <td>Pre-Tax Profit</td>
                <td>0.33</td>
                <td>0.23</td>
                <td>0.54</td>
                <td>0.75</td>
              </tr>
              <tr style={{ fontWeight: '700' }}>
                <td>GAAP-FD-EPS ($)</td>
                <td>(45)</td>
                <td>(4)</td>
                <td>(2)</td>
                <td>(66)</td>
              </tr>
              <tr>
                <td>OPEX</td>
                <td>2,33</td>
                <td>2,221</td>
                <td>3,11</td>
                <td>6,31</td>
              </tr>
              <tr>
                <td>OPEX</td>
                <td>0.726</td>
                <td>0.123</td>
                <td>0.543</td>
                <td>0.86</td>
              </tr>
              <tr style={{ fontWeight: '700' }}>
                <td> EPS ($)</td>
                <td>0.89</td>
                <td>0.23</td>
                <td>0.90</td>
                <td>0.43</td>
              </tr>
            </tbody>
          </Table>
        </Panel.Body>
      </Panel>
    </div>
  );

  /** @description Function to render the Reports Cards
   */
  renderReportsCard = () => (
    <>
      <div className="reportCard">
        <div className="col-sm-6 col-lg-1">
          <Image className="reportAuthorImage" src="https://tinyurl.com/y4ucz2mh" />
        </div>
        <div style={{ width: '90%', marginTop: '2%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <p className="reportCardTitle">Altman Z-Score Screen</p>
              <span className="newsFeedHeading"> Sector A</span>
            </div>
            <div className="newsFeedTime"> 2.5 min ago </div>
          </div>
          <div style={{ paddingTop: '1%' }}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed suscipit, urna vel
              scelerisque ullamcorper, lacus mi tincidunt mauris, sit amet facilisis orci purus eget
              purus. Sed rhoncus maximus magna vitae consectetur. Aenean ex dolor, gravida a est at,
              maximus euismod ipsum.
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '1%' }}>
            <div>
              <p className="reportCardAuthorName">Sharmila Whelan</p>
            </div>
            <div>
              <a className="reportCardReadMore">read More</a>
            </div>
          </div>
        </div>
      </div>
      <div className="reportCard">
        <div className="col-sm-6 col-lg-1">
          <Image className="reportAuthorImage" src="https://tinyurl.com/y6l3e6vo" />
        </div>
        <div style={{ width: '90%', marginTop: '2%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <p className="reportCardTitle">Altman Z-Score Screen</p>
              <span className="newsFeedHeading"> Sector A</span>
            </div>
            <div className="newsFeedTime"> 2.5 min ago </div>
          </div>
          <div style={{ paddingTop: '1%' }}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed suscipit, urna vel
              scelerisque ullamcorper, lacus mi tincidunt mauris, sit amet facilisis orci purus eget
              purus. Sed rhoncus maximus magna vitae consectetur. Aenean ex dolor, gravida a est at,
              maximus euismod ipsum.
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '1%' }}>
            <div>
              <p className="reportCardAuthorName">Jim Walker</p>
            </div>
            <div>
              <a className="reportCardReadMore">read More</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  /** @description Function to render the Tabs Components
   */

  renderLatestResearchPanel = () => (
    <div className="singlePanelTable">
      <Panel>
        <Panel.Heading>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1%' }}>
            <Panel.Title componentClass="h3" style={{ fontWeight: '700', color: '#036ca6' }}>
              {' '}
              Latest Research{' '}
            </Panel.Title>
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '-1rem' }}>
              <FilterBox />
              <p
                style={{
                  fontWeight: '700',
                  fontSize: '15px',
                  color: '#036ca6',
                  padding: '8px 12px 0px 15px',
                }}
              >
                {' '}
                Filter
              </p>
            </div>
          </div>
        </Panel.Heading>
        <ListGroup>
          <ListGroupItem>
            {/* Tags Components */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', padding: '4px' }}>
                <div className="search-tags">
                  {' '}
                  Jim Walker <span className="glyphicon glyphicon-remove"></span>
                </div>
                <div className="search-tags">
                  {' '}
                  Japan <span className="glyphicon glyphicon-remove"></span>
                </div>
                <div className="search-tags">
                  {' '}
                  India <span className="glyphicon glyphicon-remove"></span>
                </div>
              </div>
            </div>
          </ListGroupItem>
        </ListGroup>
        <Panel.Body>
          <Table responsive striped condensed hover>
            <thead style={{ color: '#31708f' }}>
              <tr>
                <th>Title</th>
                <th>
                  Analyst
                  <Glyphicon
                    className="glyphicon glyphicon-triangle-top"
                    style={{ top: '-2px', right: '-7px', color: '#036ca6' }}
                  />
                  <Glyphicon
                    className="glyphicon glyphicon-triangle-bottom"
                    style={{ right: '5px', top: '6px', color: '#8ebcd3' }}
                  />
                </th>
                <th>Sector</th>
                <th>Published Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Regional Strategy Report</td>
                <td>Jim Walker</td>
                <td>Japan</td>
                <td>31-May-18</td>
              </tr>
              <tr>
                <td>Korea Paninsula: Rapproachment</td>
                <td>Jim Walker</td>
                <td>Japan</td>
                <td>2-June-18</td>
              </tr>
              <tr>
                <td>Malaysia: Election Stunner</td>
                <td>Jim Walker</td>
                <td>Japan</td>
                <td>5-July-18</td>
              </tr>
              <tr>
                <td>Dollar Shortage</td>
                <td>Jim Walker</td>
                <td>India</td>
                <td>6-Aug-18</td>
              </tr>
              <tr>
                <td>Japan: Whats the plan, Buddy?</td>
                <td>Jim Walker</td>
                <td>India</td>
                <td>22-Sep-18</td>
              </tr>
              <tr>
                <td>Sharmila's India: Scuppered</td>
                <td>Jim Walker</td>
                <td>India</td>
                <td>12-Dec-18</td>
              </tr>
              <tr>
                <td>Jims's India: Glass Half Full?</td>
                <td>Jim Walker</td>
                <td>India</td>
                <td>5-Jan-17</td>
              </tr>
            </tbody>
          </Table>
        </Panel.Body>
      </Panel>
    </div>
  );

  /** @description Function to render the Research Page Components
   */
  renderResearchPage() {
    return (
      <>
        <Row className="show-grid">
          <Col xs={12} md={8}>
            <p style={{ fontWeight: '700', fontSize: '22px', color: '#6d6d6d', padding: '4px' }}>
              Research Page
            </p>
            {/* Tags Components */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', padding: '4px' }}>
                <div className="search-tags">
                  {' '}
                  Jim Walker <span className="glyphicon glyphicon-remove"></span>
                </div>
                <div className="search-tags">
                  {' '}
                  Japan <span className="glyphicon glyphicon-remove"></span>
                </div>
                <div className="search-tags">
                  {' '}
                  India <span className="glyphicon glyphicon-remove"></span>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={4}>
            {/* Title & Search Box */}
            <span className="searchRow">
              <FormGroup
                className="aletheia-msh-content active"
                style={{ marginTop: '7px', marginBottom: '0px' }}
              >
                <FormControl type="text" placeholder="search research list " />
                <Glyphicon glyph="glyphicon glyphicon-search" className="aletheia-msi-content" />
              </FormGroup>{' '}
            </span>
            {/* Filter Button */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '-1rem' }}>
              <FilterBox />
              <p
                style={{
                  fontWeight: '700',
                  fontSize: '15px',
                  color: '#036ca6',
                  padding: '8px 12px 0px 15px',
                }}
              >
                {' '}
                Filter
              </p>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <hr style={{ borderTop: '2px solid #ded9d9' }}></hr>
          </Col>
          <Col xs={12} md={8}>
            <ButtonToolbar>
              <DropdownButton
                title="Sort By: "
                id="dropdown-size-medium"
                style={{
                  color: '#036ca6',
                  width: '22rem',
                  textAlign: 'left',
                  background: '#dfeff6',
                }}
              >
                <MenuItem eventKey="1">Action</MenuItem>
                <MenuItem eventKey="2">Another action</MenuItem>
                <MenuItem eventKey="3">Something else here</MenuItem>
                <MenuItem divider />
                <MenuItem eventKey="4">Separated link</MenuItem>
              </DropdownButton>
            </ButtonToolbar>
          </Col>
          <Col xs={12} md={4}>
            <div className="pagination-section">
              <Pagination bsSize="small" style={{ margin: '0px' }}>
                {items}
              </Pagination>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            {this.renderReportsCard()}
            <hr style={{ borderTop: '2px solid #ded9d9' }}></hr>
          </Col>
        </Row>
      </>
    );
  }

  renderBody = () => (
    <Grid>
      <Row className="show-grid">
        <Col xs={12} md={5}>
          {this.renderStocksCard()}
          <br />
          {this.renderCharts()}
          <br />
          {this.renderSingleTable()}
        </Col>
        <Col xs={12} md={7}>
          <p style={{ fontWeight: '700', fontSize: '12px', color: '#036ca6' }}>News Feed</p>
          {this.renderNewsFeed()}
          <p style={{ fontWeight: '700', fontSize: '12px', color: '#036ca6' }}>Latest Reports</p>
          {this.renderLatestReportsTable()}
          {this.renderTabsComponent()}
        </Col>
      </Row>
      {this.renderResearchPage()}
      <Row>
        <Col xs={12} md={12}>
          {this.renderLatestResearchPanel()}
        </Col>
      </Row>
    </Grid>
  );

  render() {
    return <>{this.renderBody()}</>;
  }
}

export default StyleGridContainer;
