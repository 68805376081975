import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-bootstrap';
import CookieConsent from 'react-cookie-consent';

const Layout = ({ children }) => (
  <main>
    <Grid>{children}</Grid>
    <CookieConsent
      cookieName="cookie_consent"
      location="bottom"
      style={{ background: 'white', color: '#036CA6', fontSize: '12px', fontWeight: 'bold' }}
      buttonStyle={{
        background: '#337ab7',
        color: 'white',
        borderColor: '#2e6da4',
        padding: '10px 25px',
        textTransform: 'uppercase',
        borderRadius: '5px',
      }}
    >
      {
      "This website uses cookies to improve your browsing experience. You give consent for cookies to be used if you continue to browse this website. To learn more about how our website uses cookies, please read our "}
      <a href="/privacy">Privacy Policy Statement</a>.
    </CookieConsent>
  </main>
);

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
