import { all, put, takeLatest } from 'redux-saga/effects';
import qs from 'querystring';

import { getAllSuccess, getAllFail } from '../actionCreators/Country';
import { doGet } from '../utils/request';

function* getCountries({ obj }) {
  try {
    const response = yield doGet(`/countries?${qs.stringify(obj)}`);
    yield put(getAllSuccess(response.data, obj));
  } catch (err) {
    yield put(getAllFail(err.message));
  }
}

export default function* watcher() {
  yield all([takeLatest('GET_COUNTRIES', getCountries)]);
}
