import React from 'react';
import { Helmet } from 'react-helmet';
import { Image, Col, Row, Grid } from 'react-bootstrap';
import Box from '../../components/Box';
import Ikigai from '../../assets/images/ikigai.jpg';
import Signature from '../../assets/images/signature.png';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as firmActions from '../../actionCreators/OurFirm';
const Container = ({ getContent, content }) => {
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  React.useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, []);

  React.useEffect(() => {
    getContent();
  }, []);

  React.useEffect(() => {
    if (content) {
      setEditorState(EditorState.createWithContent(convertFromRaw(content)));
    }
  }, [content]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us</title>
        <meta name="description" content="About Aletheia Capital" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Grid>
        <Box style={{ borderRadius: 5 }}>
          {/* <h1 style={{ marginTop: 0 }}>From the CEO's Desk</h1>
          <p>July 5, 2018</p>

          <div className="text-center">
            <h3>Adieu & Chaíro, say Nĭ hăo to Aletheia Capital</h3>
          </div> */}
          <Editor
            editorState={editorState}
            toolbarHidden
            readOnly
            wrapperClassName="read-only"
            editorClassName="read-only"
          />
          {/* <p>
            June is normally a note-worthy month of the year for me as it is my birth month. June
            this year though holds special significance for a number of other reasons: I completed
            my 25 years in the industry, I also completed 20 years with Credit Suisse and most
            importantly, I have decided to embark on a new path for myself (for the next hopefully
            quarter of a century.
          </p>
          <p>
            Let me first thank all of you for supporting me and my teams in making the past 25 years
            an amazing journey in every respect. Special thanks to Credit Suisse, the thousands of
            colleagues and clients I had the privilege to work with, and especially to the 200 odd
            team members who worked for an unprecedented 15 years or so together to bring Credit
            Suisse from nowhere in 1998 to the most profitable Equities house ever in Asia. I feel
            blessed and indeed very thankful to all of you.
          </p>
          <p>So, what next?</p>

          <h6>
            The answer to that is not easy, as people who have gone through it before me will know
            well.
          </h6>
          <p>
            As you near completing a successful quarter of a century in a profession with each
            incremental year becoming less intellectually challenging and you can see the lightning
            speed at which technology is changing the world around you, how do you find your next
            lkigai*?
          </p>
          <p>
            I had been thinking about this question for a few years now, especially as I approached
            the '5 handle'. Success in one's profession actually makes it difficult to let go and
            move to the next lkigai. With each passing year, one normally focuses on what one is
            good at and hence can be compensated for. This results in a 'comfortable lifestyle but
            with a feeling of emptiness'. Rarely does a situation arise where a combination of what
            you are good at, what you love and what you can get paid for, comes together with what
            the world needs.
          </p>
          <p>
            The ‘perfect’ opportunity for my next lkigai came when the European regulators started
            to implement new regulations (MIFID II) in the Global Markets industry thereby
            unbundling the opaque pricing structure prevalent on the sell-side. This regulatory
            catalyst which became effective from Jan 3, 2018 (with some grace period) meant that the
            fee paid by the buyside will reduce thereby necessitating lower cost base of the
            sell-side (unless one takes more market share of a smaller pie). Combine this with the
            fact that most large houses have 50-70% costs as allocated costs (ie the departments
            don't control those costs and hence can't reduce them) meant that the large houses will
            be at a big cost disadvantage and will inevitably have to reduce costs (read head
            count). This will (is) result (ing) in smaller and less experienced coverage teams;
            something clients ‘complain’ about all the time.
          </p>
          <Row>
            <Col xs={12} smOffset={2} sm={8} mdOffset={3} md={6}>
              <Image src={Ikigai} alt="ikigai logo" responsive />
            </Col>
          </Row>
          <p>
            Despite the ‘bad news’ of a contracting revenue pie, for a new comer, this means that a
            $10-lSbn global market is practically opening for the first time ever. The regulatory
            catalyst will also enable technology disruption in the sector, which is sweeping most
            other sectors of the economy but has yet to disrupt the markets sector materially. The
            lack of interest by the incumbents due to legacy infrastructure issues and
            cannibalisation concerns have been the main constraints here.
          </p>
          <p>
            Hence is the birth of Aletheia Capital. The word Aletheia (Ancient Greek: cU 8Eta) means
            truth, unconcealed-ness, unclosed-ness, disclosure.
          </p>
          <p>
            Aletheia Capital is Asia's First Fully Independent Content firm with plans to have scale
            (subject to regulatory approvals).
          </p>
          <p>
            Our Vision is to be a client centric technology driven company with market leading,
            independent and original Asian research product. We intend to create the first and
            leading Independent Research Entity with scale by attracting and retaining best
            analysts, and providing research based on the client's needs with delivery customised to
            the clients choosing.
          </p>
          <p>
            We aim to be fiercely independent and provide deeply insightful ideas with impeccable
            integrity; through market-leading analysts and their products. We intend to overcome the
            typical scaling constraints of independent research through 1) a true partnership
            culture where the employees own the firm, 2) consolidating highly fragmented independent
            research space and 3) deploying disruptive technology for scalability and a seamless
            client experience.
          </p>
          <p>
            As we open doors for business, I hope you find Aletheia's services value added to you,
            your team and your firms.
          </p>
          <p>
            Also, as in the past twenty-five years, I hope we garner your support, guidance,
            feedback and friendship as we embark on this new mission.
          </p>
          <p>Best regards</p> */}
          {/* <Row>
            <Col xs={4} sm={2}>
              <Image src={Signature} alt="Signature" responsive />
            </Col>
          </Row>
          <p>
            Ali Naqvi
            <br />
            Chief Executive Officer
            <br />
            Aletheia Capital Limited
            <br />@ AletheiaCapital
          </p> */}
        </Box>
      </Grid>
    </>
  );
};
function mapStateToProps(state) {
  const content = state.ourfirm.content.data;
  return { content };
}

const mapDispatchToProps = dispatch => ({
  getContent: bindActionCreators(firmActions.getContent, dispatch),
});

Container.propTypes = {
  getContent: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
