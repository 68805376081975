import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Grid } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw } from 'draft-js';

const PostDataContainer = ({ author, postContent, title }) => {
  
  return (
    <Grid>
        <Row>
            <Col xs={12} sm={3} className="post-author">
                <p>{author.name}</p>
                <a href={author.email}>{author.email}</a>
                <br/>
                <br/>
                <div dangerouslySetInnerHTML={{__html: author.extraHTML}}></div>
            </Col>
            <Col xs={12} sm={9} className="post-content">
                <br/>
                <h1>{title}</h1>
                <Editor
                    editorState={EditorState.createWithContent(
                        convertFromRaw(postContent)
                    )}
                    toolbarHidden
                    readOnly
                    wrapperClassName="read-only"
                    editorClassName="read-only"
                />
            </Col>
        </Row>
    </Grid>
  );
};

PostDataContainer.propTypes = {
    author: PropTypes.object,
    postContent: PropTypes.string,
};

PostDataContainer.defaultProps = {
    author: { 
        name: '', 
        email: '',
        extraHTML: ``,
    },
    postContent: null
};

export default PostDataContainer;