/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 05 August 2020 : Aman
		* M-0002939: Our People page should allow sort ordering of staff from the admin page.
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Grid } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { sanitizeText } from '../../utils';
//====================M001=====[====================
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
//====================M001=====[====================
import Table from '../../components/Table';
import ProfileCard from './components/ProfileCard';
import Confirm from '../../components/Confirm';

import { teamTableLabels } from './config';

import * as analystActions from '../../actionCreators/Analyst';
//====================M001=====[====================
import '../../assets/sass/team-management.scss';
//====================M001=====[====================

const Container = ({
  ourPeople,
  getOurPeople,
  editProfileModal,
  showEditProfileModal,
  hideEditProfileModal,
  updateOurTeam,
  sortOurTeam,
}) => {

  //====================M001=====[====================
  const [people, setPeople] = React.useState([]);
  //====================M001=====[====================

  const [showDetailBoxType, setShowDetailBoxType] = React.useState(null);
  const [userData, setUserData] = React.useState();

  const [showConfirm, setShowConfirm] = React.useState(false);
  const [val, setVal] = React.useState(null);

  
  React.useEffect(() => {
    getOurPeople();
  }, []);

  React.useEffect(()=>{
    const isOurPeople = ourPeople.data.filter(i => i.is_our_people);
    setPeople(isOurPeople);
  }, [ourPeople]);

  //====================M001=====[====================
  const onDragEnd = result => {
    const { destination, source, reason } = result;

    if (!destination || reason === 'CANCEL') {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const peoples = Object.assign([], people);
    const droppedUser = peoples[source.index];
    peoples.splice(source.index, 1);
    peoples.splice(destination.index, 0, droppedUser);
    setPeople(peoples);
  };

  const RenderUsers = (item, index) => {
    return (
      <Draggable draggableId={index + ''} index={index} key={index}>
        {provided => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <Row className="dataRow">
              <Col xs={12} sm={3} md={3} className="tableData">
                <Link to={`/analysts/${item.id}_${sanitizeText(item.name)}`}>{item.name}</Link>
              </Col>
              <Col className="tableData" xs={12} sm={3} md={3}>
                {item.designation}
              </Col>
              <Col className="tableData" xs={12} sm={3} md={3}>
                {item.country}
              </Col>
              <Col className="tableData" xs={12} sm={3} md={3}>
                {item.email}
              </Col>
            </Row>
          </div>
        )}
      </Draggable>
    );
  };

  const SaveButton = () => {
    var sortValue = {}
    people.map((item, index) => {
      sortValue[item.id] = index;
    });
    sortOurTeam({sortValue: sortValue});
    window.location.reload(false);
  };
  //====================M001=====[====================

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Team Management</title>
      </Helmet>

      <Grid>
        <Confirm
          show={showConfirm}
          message="Are you sure you want to disable member from Our Team?"
          subHeading={null}
          onConfirm={() => {
            setShowConfirm(false);
            updateOurTeam(val);
          }}
          onCancel={() => setShowConfirm(false)}
        />
        <div style={{ overflowX: 'auto' }}>
          <ProfileCard
            show={editProfileModal}
            data={userData}
            onCancel={() => hideEditProfileModal()}
            editable={showDetailBoxType}
          />
          {/* //====================M001=====[==================== */}
          <Droppable droppableId="dp1">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div className="tableHeader">
                  Sort Our People{' '}
                  <button className="saveBtn" onClick={SaveButton}>
                    Save
                  </button>
                </div>
                <Row className="heading">
                  <Col xs={12} sm={3} md={3}>
                    Name
                  </Col>
                  <Col xs={12} sm={3} md={3}>
                    Designation
                  </Col>
                  <Col xs={12} sm={3} md={3}>
                    Country
                  </Col>
                  <Col xs={12} sm={3} md={3}>
                    Email
                  </Col>
                </Row>
                {people.map(RenderUsers)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <br />
          {/* //====================M001=====[==================== */}
          {/* <Table
            title="Our People"
            data={ourPeople.data}
            loading={ourPeople.loading}
            labels={teamTableLabels(
              handleOurPeople,
              (is_our_people, obj) => {
                updateOurTeam({ id: obj.id, is_our_people });
              },
              removeMember
            )}
          /> */}
        </div>
      </Grid>
    </DragDropContext>
  );
};

const mapStateToProps = ({ analyst: { ourPeople, editProfileModal } }) => ({
  ourPeople,
  editProfileModal,
});

const mapDispatchToProps = dispatch => ({
  getOurPeople: bindActionCreators(analystActions.getOurPeople, dispatch),
  showEditProfileModal: bindActionCreators(analystActions.showEditProfileModal, dispatch),
  hideEditProfileModal: bindActionCreators(analystActions.hideEditProfileModal, dispatch),
  //====================M001=====[====================
  updateOurTeam: bindActionCreators(analystActions.updateOurTeam, dispatch),
  //====================M001=====[====================
  sortOurTeam: bindActionCreators(analystActions.sortOurTeam, dispatch),
});

Container.propTypes = {
  ourPeople: PropTypes.object.isRequired,
  getOurPeople: PropTypes.func.isRequired,
  showEditProfileModal: PropTypes.func.isRequired,
  hideEditProfileModal: PropTypes.func.isRequired,
  updateOurTeam: PropTypes.func.isRequired,
  //====================M001=====[====================
  sortOurTeam: PropTypes.func.isRequired,
  //====================M001=====[====================
  editProfileModal: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
