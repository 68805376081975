/*
**************************************************************************************************************
												R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	:	Nick Ng
	Date	:	10 Oct 2022
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************
	M006 : 18 Oct 2022 : Nick Ng : Core
		 * M-4172: Fix Must Read and Free Access buttons
  M005 : 27 Apr 2021 : Nick
    * M-3382: Website UI Improve - Aletheia Connect
  M004 : 03 Feb 2021 : Aman
    * M-3220: Allow users to save documents
  M003 : 26 Jan 2020 : Nick
    * M-3243: Must read section should show all reports across sectors
  M002 : 20 Nov 2020 : Nick
    * M-3148: Switching report's sectors will remain the same
  M001 : 05 Nov 2020 : Nick
    * M-3109: Various issue with searching reports
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  Col,
  Row,
  Button,
  NavDropdown,
  MenuItem,
  ButtonGroup,
  Glyphicon,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';

import { usePreviousProps, sanitizeText } from '../../utils';

import Box from '../../components/Box';
import Card from '../../components/Card';
import FilterStrip from './components/FilterStrip';
import ReportCard from './components/ReportCard';
import * as userActions from '../../actionCreators/User';
import * as researchActions from '../../actionCreators/Research';
import * as configActions from '../../actionCreators/Config';
import { getStandardDateTime } from '../../utils/dateTime';
import { filterKeys } from './config';
import HighlightedPanel from '../../components/HighlightedPanel'; //M005
import ReportImages from '../../pages/ReportViewer/components/SocialMediaTilesSectorImageMapping';

import TypeTabBar from './components/TypeTabBar';

const Container = ({
  //=====================M004=======================
  showSavedReports,
  BlogSectionReports,
  AttachClick,
  //=====================M004=======================
  showHighlighted,
  q,
  analystId,
  companyId,
  sectorId,
  latestResearches,
  highlightedResearches,
  getLatestReports,
  getHighlightedResearches,
  history,
  applyFilters,
  logIn,
  currentUser,
  //=====================M004=======================
  SaveReport,
  GetSavedReports,
  savedReports,
  ReportsBlog,
  MostViewedReports,
  GetMostViewedReports,
  //=====================M004=======================
  Bookmark,
  isManagement,
  mgtAddTemp,
  mgtAddMustRead,
  getTempReports,
  showTypeBar,
  showSwitchView,
  getParameter,
  parameters,
}) => {
  const [hackLoading, setHackLoading] = React.useState([]);
  const [reports, setReports] = React.useState([]);
  const [sectorReports, setSectorReports] = React.useState([]);
  const prevProps = usePreviousProps({ latestResearches, sectorId, companyId });
  const getResearches = (page = 1, filters = {}) => {
    if (filters.q?.join() && filters?.length > 0) {
      sessionStorage.setItem('TagsInput', filters.q?.join());
    } else sessionStorage.removeItem('TagsInput');

    //GET TEMP REPORT FOR NPI
    if (user.type == 'npi') {
      getTempReports({ analystId, companyId, sectorId, reportType, ...filters, page });
    } else {
      //GET ALL REPORTS
      getLatestReports({ analystId, companyId, sectorId, reportType, ...filters, page });
    }
  };
  const [isSafariOrFirefox, setIsSafariOrFirefox] = React.useState(false);
  const [clickedTag, setClickedTag] = React.useState([]);
  const [isHighlighted, setIsHighlighted] = React.useState(true);
  //=====================M004=======================
  const [SavedNew, setSavedNew] = React.useState();
  //=====================M004=======================
  const user = currentUser && currentUser.data;
  const [reportType, setReportType] = React.useState();
  const [viewType, setViewType] = React.useState(1);

  // Check if Safari Or Firefox for special handling
  const checkIfSafariOrFirefox = () => {
    // Detecting Browser by duck typing
    let isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    if (isSafari) {
      if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
        isSafari = false;
      }
    }
    const isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox || isSafari) {
      return true;
    }
    return false;
  };

  const handletags = tag => {
    // setClickedTag([]);
    let arr = clickedTag;
    arr.push(tag);
    setClickedTag(arr);
    setReports([]);
    getResearches(undefined, { q: tag });
  };
  //=====================M004=======================
  const SaveReports = reportid => {
    let savedarray = SavedNew;
    const report = reportid.substring(2);
    const index = savedarray.findIndex(x => x.id == reportid);
    if (index > -1) {
      savedarray.splice(index, 1);
      setSavedNew(JSON.parse(JSON.stringify(savedarray)));
      SaveReport({ id: report, status: 0 });
      GetSavedReports();
    } else {
      savedarray.push({ id: reportid });
      setSavedNew(JSON.parse(JSON.stringify(savedarray)));
      SaveReport({ id: report, status: 1 });
      GetSavedReports();
    }

    GetSavedReports();
  };

  const getDateTimeBox = dateTime => {
    var standardDateTime = getStandardDateTime(dateTime, { format: 'D MMM YYYY' });
    var obj = <div style={{ textAlign: 'left', paddingTop: '10px' }}>{standardDateTime}</div>;
    return obj;
  };

  const showCompanyNames = companies => {
    let toReturn = '';
    companies.forEach((c, i) => {
      // Limiting the number of companies listed
      if (i > 2) {
        return;
      }
      toReturn += c.sName + (i == companies.length - 1 || i == 2 ? '' : ', ');
    });
    return toReturn;
  };

  React.useEffect(() => {
    setSavedNew(savedReports.data.researches);
  }, [savedReports]);

  //=====================M004=======================
  // First on load of the reports
  React.useEffect(() => {
    getParameter('rw_CNTBkTalkToThe,bEnableConnectBookings');
    var previousSearchInput = [];
    try {
      previousSearchInput = sessionStorage.getItem('TagsInput').split(',');
    } catch (e) {
      previousSearchInput = [];
    }
    //=====================M002=======================
    if (sectorId) {
      setSectorReports(true);
      getLatestReports({ sectorId, q: previousSearchInput });
    } else if (previousSearchInput.length > 0) {
      getResearches(1, { q: previousSearchInput });
    } else {
      getResearches(1, { q });
    }
    setHackLoading(true);
    setIsSafariOrFirefox(checkIfSafariOrFirefox());
    //getCurrentUser();
    //=====================M002=======================

    if (showHighlighted) {
      if (highlightedResearches.data.length == 0) {
        getHighlightedResearches(); //M003
        GetMostViewedReports();
      }
    }
    //=====================M004=======================
    GetSavedReports();
    //=====================M004=======================
    if (user.type == 'npi') {
      setViewType(2);
    }
    // }, [sessionStorage.getItem('TagsInput')]);
  }, []);

  //Recursive Getting Reports
  React.useEffect(() => {
    var previousSearchInput = [];
    try {
      previousSearchInput = sessionStorage.getItem('TagsInput').split(',');
    } catch (e) {
      previousSearchInput = [];
    }
    //=====================M002=======================
    if (prevProps) {
      if (
        latestResearches.pagination == 1 ||
        prevProps.sectorId !== sectorId ||
        prevProps.companyId !== companyId
      ) {
        setHackLoading(true);
      }
      if (prevProps.sectorId !== sectorId) {
        getResearches(1, { q: previousSearchInput });
      } else if (prevProps.companyId !== companyId) {
        getResearches(1, { q: previousSearchInput });
      } else if (
        latestResearches.pagination.current > prevProps.latestResearches.pagination.current
      ) {
        setReports([...reports, ...latestResearches.data]);
      } else if (
        JSON.stringify(latestResearches.filters) !==
        JSON.stringify(prevProps.latestResearches.filters)
      ) {
        setReports(latestResearches.data);
      }
    }
    //=====================M002=======================
  });

  if (sectorReports) {
    setReports(latestResearches.data);
    setSectorReports(false);
  }

  return (
    <>
      <Row>
        <Col md={12} lg={showHighlighted ? 8 : 12}>
          <div>
            <Row>
              <Col xs={9} md={10} style={{ marginBottom: 5 }}>
                {/* SEARCH AND FILTER BOX */}
                <FilterStrip
                  data={latestResearches.filters}
                  extraTags={q && q.length && Array.isArray(q) ? q : q === undefined ? q : [q]}
                  tagsclicked={clickedTag}
                  filters={filterKeys.filter(({ key }) => !(analystId && key === 'analyst'))}
                  onChange={t => {
                    setReports([]);
                    getResearches(undefined, t);
                  }}
                  onRemove={key => {
                    const updatedFilters = {};
                    Object.keys(latestResearches.filters).forEach(k => {
                      if (k !== key) {
                        updatedFilters[key] = latestResearches[key];
                      }
                    });
                    setReports([]);
                    getResearches(undefined, updatedFilters);
                  }}
                />
                {/* SEARCH AND FILTER BOX */}
              </Col>

              {/* SWITCH VIEW (LIST AND TILE) */}
              {showSwitchView && (
                <Col xs={3} md={2} style={{ textAlign: 'right', marginTop: 10 }}>
                  <ToggleButtonGroup
                    type="radio"
                    name="options"
                    onChange={e => {
                      setViewType(e);
                    }}
                    value={viewType}
                  >
                    <ToggleButton value={1}>
                      <Glyphicon glyph="glyphicon glyphicon-th-list" />
                    </ToggleButton>
                    <ToggleButton value={2}>
                      <Glyphicon glyph="glyphicon glyphicon-th" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Col>
              )}
              {/* SWITCH VIEW (LIST AND TILE) */}
            </Row>
          </div>

          {/* REPORTS TYPE TAB BAR */}
          {showTypeBar && (
            <TypeTabBar
              show={sectorId ? false : true}
              reportType={reportType}
              setReportType={type => {
                if (type == reportType) {
                  return;
                }
                setReports([]);
                var previousSearchInput = [];

                try {
                  previousSearchInput = sessionStorage.getItem('TagsInput').split(',');
                } catch (e) {
                  previousSearchInput = [];
                }
                getLatestReports({
                  analystId,
                  companyId,
                  sectorId,
                  reportType: type,
                  q: previousSearchInput,
                  page: 1,
                });
                setReportType(type);
              }}
            ></TypeTabBar>
          )}
          {/* REPORTS TYPE TAB BAR */}

          {/* HACK LOADING TABLE */}
          {hackLoading && latestResearches.loading ? (
            Array.from({ length: 20 }).map((v, i) => (
              <Card key={`loading_research_i_${i}`} style={{ margin: '2px 0px' }} loading />
            ))
          ) : (
            <></>
          )}
          {/* HACK LOADING TABLE */}

          {/* EMPTY RESEARCH */}
          {!latestResearches.loading && !latestResearches.data.length && (
            <Box className="text-center">There are currently no reports available.</Box>
          )}
          {/* EMPTY RESEARCH */}

          {/* RESEARCH TABLE VIEW */}
          {!showSavedReports && viewType == 1 ? (
            <InfiniteScroll
              initialLoad={true}
              pageStart={1}
              loadMore={() => {
                if (latestResearches.loading) {
                  return;
                } else if (
                  latestResearches.pagination.current < latestResearches.pagination.total
                ) {
                  getResearches(latestResearches.pagination.current + 1, latestResearches.filters);
                  setHackLoading(false);
                }
              }}
              hasMore={latestResearches.pagination.current < latestResearches.pagination.total}
              loader={<></>}
            >
              {/* //=====================M004,M006======================= */}
              {!isSafariOrFirefox && !BlogSectionReports
                ? reports.map((item, i) => (
                    <Row>
                      <Col xs={isManagement ? 9 : 12}>
                        <ReportCard
                          parameters={parameters}
                          Bookmark={Bookmark}
                          ShowTag
                          SavedVideos={SavedNew}
                          OnSaveClick={SaveReports}
                          onTagClick={handletags}
                          reportid={item.id}
                          key={`report_card_${item.id}_${i}`}
                          research={{
                            ...item,
                            published_date: getStandardDateTime(item.published_date),
                          }}
                          style={{ margin: '4px 0px', borderRadius: '5px' }}
                        />
                      </Col>
                      {isManagement ? (
                        <Col xs={3} style={{ margin: '22px 0px' }}>
                          <Button
                            bsSize="small"
                            className="highlight-button"
                            onClick={e => {
                              if (item.type == 'highlighted') {
                                item.type = 'latest';
                                e.target.innerHTML = 'Add to Must Read';
                                mgtAddMustRead(item);
                              } else {
                                item.type = 'highlighted';
                                e.target.innerHTML = 'Remove from Must Read';
                                mgtAddMustRead(item);
                              }
                            }}
                          >
                            {item.type == 'highlighted'
                              ? 'Remove from Must Read'
                              : 'Add to Must Read'}
                          </Button>
                          <Button
                            bsSize="small"
                            className="highlight-button"
                            onClick={e => {
                              if (item.isTempReport) {
                                item.isTempReport = false;
                                e.target.innerHTML = 'Add to Aletheia Select';
                                mgtAddTemp(item, 'remove');
                              } else {
                                item.isTempReport = true;
                                e.target.innerHTML = 'Remove from Aletheia Select';
                                mgtAddTemp(item, 'add');
                              }
                            }}
                            disabled={
                              new Date(getStandardDateTime(item.published_date)) <
                              new Date(new Date().setDate(new Date().getDate() - 7))
                                ? false
                                : true
                            }
                          >
                            {item.isTempReport
                              ? 'Remove from Aletheia Select'
                              : 'Add to Aletheia Select'}
                          </Button>
                        </Col>
                      ) : null}
                    </Row>
                  ))
                : BlogSectionReports
                ? reports.map((item, i) => (
                    <ReportCard
                      ReportsBlog={ReportsBlog}
                      BlogSectionReports
                      ShowTag
                      OnAttachClick={() => AttachClick(item.id)}
                      showAvatar={false}
                      onTagClick={handletags}
                      reportid={item.id}
                      key={`report_card_${item.id}_${i}`}
                      research={{
                        ...item,
                        published_date: getStandardDateTime(item.published_date),
                      }}
                      style={{ margin: '4px 0px', borderRadius: '5px' }}
                    />
                  ))
                : reports.map((item, i) =>
                    viewType == 1 ? (
                      <ReportCard
                        parameters={parameters}
                        ReportsBlog={ReportsBlog}
                        BlogSectionReports
                        ShowTag
                        OnAttachClick={() => AttachClick(item.id)}
                        showAvatar={false}
                        onTagClick={handletags}
                        reportid={item.id}
                        key={`report_card_${item.id}_${i}`}
                        research={{
                          ...item,
                          published_date: getStandardDateTime(item.published_date),
                        }}
                        style={{ margin: '4px 0px', borderRadius: '5px' }}
                      />
                    ) : (
                      <ReportCard
                        ReportsBlog={ReportsBlog}
                        BlogSectionReports
                        ShowTag
                        OnAttachClick={() => AttachClick(item.id)}
                        showAvatar={false}
                        onTagClick={handletags}
                        reportid={item.id}
                        key={`report_card_${item.id}_${i}`}
                        research={{
                          ...item,
                          published_date: getStandardDateTime(item.published_date),
                        }}
                        style={{ margin: '4px 0px', borderRadius: '5px' }}
                      />
                    )
                  )}
              {/* //=====================M004,M006======================= */}
            </InfiniteScroll>
          ) : savedReports.data.researches != undefined &&
            savedReports.data.researches.length != 0 &&
            viewType == 1 ? (
            savedReports.data.researches.map((item, i) => (
              <ReportCard
                parameters={parameters}
                Bookmark={Bookmark}
                ShowTag
                SavedVideos={SavedNew}
                OnSaveClick={SaveReports}
                onTagClick={handletags}
                reportid={item.id}
                key={`report_card_${item.id}_${i}`}
                research={{ ...item, published_date: getStandardDateTime(item.published_date) }}
                style={{ margin: '4px 0px', borderRadius: '5px' }}
              />
            ))
          ) : (
            viewType == 1 && (
              <Box className="text-center">There are currently no reports available.</Box>
            )
          )}
          {/* RESEARCH TABLE VIEW */}

          {/* RESEARCH TILES VIEW */}
          {viewType == 2 && (
            <InfiniteScroll
              initialLoad={true}
              pageStart={1}
              loadMore={() =>
                !latestResearches.loading &&
                latestResearches.pagination.current < latestResearches.pagination.total &&
                getResearches(latestResearches.pagination.current + 1, latestResearches.filters) &&
                setHackLoading(false)
              }
              hasMore={latestResearches.pagination.current < latestResearches.pagination.total}
              loader={<></>}
              style={{ marginTop: 5 }}
            >
              <Row>
                {reports.map((item, i) => (
                  <Col
                    md={i < 2 || i % 20 == 0 || i % 20 == 1 ? 6 : 4}
                    style={{ marginBottom: '20px' }}
                  >
                    <Card
                      style={{
                        maxHeight: '600px',
                        minHeight: '600px',
                      }}
                      imageLocation="top"
                      image={
                        ReportImages[item.sectors[0].id]
                          ? ReportImages[item.sectors[0].id].src
                          : ReportImages['default'].src
                      }
                      imageTitleLink={'/reports/' + item.id + '_'}
                      imageClass="blogs-landing-card-image"
                      title={item.title}
                      descriptionLength="200"
                      titleBrand={
                        (item.companies && item.companies.length > 0
                          ? showCompanyNames(item.companies)
                          : '') +
                        ' - ' +
                        (item?.sectors ? item.sectors[0]?.sName : '')
                      }
                      description={item.description}
                      descriptionFontSize="14px"
                      Author={item.analysts.length ? item.analysts[0].name : 'Aletheia Research'}
                      component={getDateTimeBox(item.published_date)}
                      titleLink={'/reports/' + item.id + '_'}
                      readMoreLink={'/reports/' + item.id + '_'}
                      key={`report_card_${item.id}_${i}`}
                    />
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>
          )}

          {/* RESEARCH TILES VIEW */}

          {/* INFINITE LOADING TABLE */}
          {latestResearches.loading &&
            Array.from({ length: 20 }).map((v, i) => (
              <Card key={`loading_research_i_${i}`} style={{ margin: '2px 0px' }} loading />
            ))}
          {/* INFINITE LOADING TABLE */}
        </Col>

        {/* Highlighted Reports Section - M005*/}
        {showHighlighted ? (
          <HighlightedPanel
            leftResearches={highlightedResearches.data ? highlightedResearches.data : []}
            rightResearches={MostViewedReports.data ? MostViewedReports.data.researches : []}
            loading={highlightedResearches.loading || highlightedResearches.loading}
            saveReports={SaveReports}
            savedVideos={SavedNew}
            Bookmark={Bookmark}
          />
        ) : (
          <></>
        )}
        {/* Highlighted Reports Section - M005*/}
      </Row>
    </>
  );
};

const mapStateToProps = ({
  research: { latestResearches, highlightedResearches, savedReports, MostViewedReports },
  user: { currentUser },
  Config: { parameters },
}) => ({
  latestResearches,
  highlightedResearches,
  currentUser,
  savedReports,
  MostViewedReports,
  parameters,
});

const mapDispatchToProps = dispatch => ({
  getLatestReports: bindActionCreators(researchActions.getLatest, dispatch),
  getHighlightedResearches: bindActionCreators(researchActions.getHighlighted, dispatch),
  SaveReport: bindActionCreators(researchActions.SaveReport, dispatch),
  applyFilters: bindActionCreators(researchActions.applyFilters, dispatch),
  GetSavedReports: bindActionCreators(researchActions.GetSavedReports, dispatch),
  GetMostViewedReports: bindActionCreators(researchActions.GetMostViewedReports, dispatch),
  logIn: bindActionCreators(userActions.logIn, dispatch),
  getTempReports: bindActionCreators(researchActions.getTempReports, dispatch),
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
});

Container.propTypes = {
  latestResearches: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  highlightedResearches: PropTypes.object.isRequired,
  savedReports: PropTypes.object.isRequired,
  MostViewedReports: PropTypes.object.isRequired,
  getLatestReports: PropTypes.func.isRequired,
  getHighlightedResearches: PropTypes.func.isRequired,
  SaveReport: PropTypes.func.isRequired,
  GetSavedReports: PropTypes.func.isRequired,
  GetMostViewedReports: PropTypes.func.isRequired,
  showHighlighted: PropTypes.bool,
  analystId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  companyId: PropTypes.string,
  sectorId: PropTypes.string,
  applyFilters: PropTypes.func,
  q: PropTypes.array,
  logIn: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
};

Container.defaultProps = {
  showHighlighted: false,
  analystId: undefined,
  companyId: undefined,
  sectorId: undefined,
  q: undefined,
  applyFilters: {},
  Bookmark: true,
  isManagement: false,
  showTypeBar: true,
  showSwitchView: true,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
