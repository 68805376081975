import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { Stack, Typography } from '@mui/material';
import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Section4 from './components/Section4';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as configActions from '../../actionCreators/Config';
import parse from 'html-react-parser';
import { CssBaseline } from '@material-ui/core';
import '../../assets/sass/access-mui.scss';

const AletheiaAccess = ({ getParameter, content }) => {
  const [accessHTML, setAccessHTML] = React.useState('');

  React.useEffect(() => {
    // getParameter('sRshWebAccessHTML');
  }, []);

  // React.useEffect(() => {
  //   if (content != null && content?.sRshWebAccessHTML != null) {
  //     const parsedHTML = parse(content.sRshWebAccessHTML);
  //     setAccessHTML(parsedHTML);
  //   }
  // }, [content]);
  return (
    <>
      <Helmet>
        <title>Aletheia Access</title>
      </Helmet>
      <PageHeader
        pageName="ACCESS"
        pageDesc="Your Partner in Advisory 
Across All Asset Classes – Public & Private "
      />
      {/* {accessHTML != '' ? accessHTML : null} */}
      <div>
        <Section1 />
        <Stack
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '50px', marginBottom: '50px' }}
        >
          <Typography fontSize={26} color="#036ca6" style={{ fontFamily: 'open_sanssemibold' }}>
            Our Capabilities
          </Typography>
        </Stack>
        <Section2 />
        <Stack
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '50px', marginBottom: '50px' }}
        >
          <Typography fontSize={26} color="#036ca6" style={{ fontFamily: 'open_sanssemibold' }}>
            360° Service for All Stakeholders
          </Typography>
        </Stack>
        <Section3 />
        <Stack
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '50px', marginBottom: '50px' }}
        >
          <Typography fontSize={26} color="#036ca6" style={{ fontFamily: 'open_sanssemibold' }}>
            Comprehensive Set of Services
          </Typography>
        </Stack>
        <Section4 />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const content = state.Config.parameters;
  return { content };
}

const mapDispatchToProps = dispatch => ({
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AletheiaAccess));
