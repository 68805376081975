/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 27 Apr 2021 : Nick
    * M-0003382: Website UI Improve - Aletheia Connect
  	M002 : 15 July 2021 : Aman
    * M-0003480: Center align save report/video stars above dates
**************************************************************************************************************
*/

/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from './components/Avatar';
import Tags from './components/Tags';
import Box from '../../../../components/Box';

import { OnAtLeastTablet } from '../../../../components/ReactBreak';
import starLine from '../../../../assets/images/bookmark-4-starline.svg';
import starFilled from '../../../../assets/images/bookmark-4-yellow-filled.svg';
import plus from '../../../../assets/images/plus.png';
import tick from '../../../../assets/images/tick.png';
import PlayButtonImg from '../../../../assets/images/play-filled-thin-blue.svg'; //M001
import { Image } from 'react-bootstrap'; //M001
import { Link } from 'react-router-dom'; //M001

const DESCRIPTION_LENGTH = 1000;

const Component = ({
  style,
  showAvatar,
  research,
  onTagClick,
  ShowTag,
  reportid,
  Bookmark,
  BlogSectionReports,
  OnSaveClick,
  OnAttachClick,
  SavedVideos,
  ReportsBlog,
  PlayButton, //M001
  PlayLink, //M001
  parameters,
}) => {
  return (
    <Box style={{ padding: '0px', ...style }}>
      <table style={{ width: '100%' }}>
        <tbody>
          {showAvatar && <Avatar analyst={research.analysts[0]} parameters={parameters} />}
          {/* VIDEO PLAY BUTTON - M001 */}
          {PlayButton && (
            <tr>
              <td rowSpan="6" style={{ paddingLeft: '10px', width: '5%' }}>
                <div style={{ padding: '0px 0px 5px 0px' }}>
                  <div className="">
                    <Link href={PlayLink} to={PlayLink}>
                      <div className="LatestListPlayDiv"></div>
                    </Link>
                  </div>
                </div>
              </td>
            </tr>
          )}
          {/* VIDEO PLAY BUTTON - M001 */}
          <Tags report={research} />
          <tr>
            <td style={{ paddingLeft: '10px' }}>
              <td>
                {research.hashtags && ShowTag ? (
                  research.hashtags.map(tag => (
                    <span className="hashtags" onClick={() => onTagClick(tag)}>
                      {tag + ' '}
                    </span>
                  ))
                ) : (
                  <></>
                )}
              </td>
              {research.description ? (
                <div
                  className="card-description"
                  dangerouslySetInnerHTML={{
                    __html: `${String(research.description).slice(0, DESCRIPTION_LENGTH).trim()}`,
                  }}
                />
              ) : (
                <div
                  className="card-description"
                  dangerouslySetInnerHTML={{
                    __html: `${String('No Description <br/>').slice(0, DESCRIPTION_LENGTH).trim()}`,
                  }}
                />
              )}
            </td>
            <OnAtLeastTablet>
              <td className="date-section">
                {/* //M002 */}
                <div className="SaveReport">
                  {Bookmark ? (
                    <img
                      title="Add to my Saved list"
                      data-report_id={reportid}
                      data-video_id={reportid} //M001
                      data-doc_id={reportid} //M001
                      src={
                        SavedVideos != null &&
                        SavedVideos != undefined &&
                        SavedVideos.some(saved => saved.id == reportid)
                          ? starFilled
                          : starLine
                      }
                      style={{ height: '20px' }}
                      onClick={() => OnSaveClick(reportid)}
                    />
                  ) : BlogSectionReports ? (
                    <img
                      title="Add to the blog list"
                      data-report_id={reportid}
                      src={
                        ReportsBlog != null &&
                        ReportsBlog != undefined &&
                        ReportsBlog.some(blog => blog == reportid)
                          ? tick
                          : plus
                      }
                      style={{ height: '20px' }}
                      onClick={OnAttachClick}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                {/* //M002 */}
                {research.published_date}
              </td>
            </OnAtLeastTablet>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

Component.propTypes = {
  research: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    published_date: PropTypes.string.isRequired,
    product: PropTypes.string,
    analysts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        image_path: PropTypes.string,
      })
    ).isRequired,
    brand: PropTypes.shape({
      name: PropTypes.string,
    }),
    hashtags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  style: PropTypes.object,
  showAvatar: PropTypes.bool,
  PlayButton: PropTypes.bool, //M001
};

Component.defaultProps = {
  style: {},
  showAvatar: true,
  PlayButton: false, //M001
};

export default Component;
