/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Image, Row, Col } from 'react-bootstrap';
import Avatar from '../../../assets/images/placeholder-company.png';
import Box from '../../../components/Box';
import { Link } from 'react-router-dom';

const Component = ({ data, style }) => {
  if (data) {
    return (
      <Col xs={12} xm={6} md={6} lg={4}>
        <Box
          style={{
            minHeight: '200px',
            maxHeight: '200px',
            margin: '10px',
            padding: 0,
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingRight: '5px',
          }}
        >
          <Row>
            <Col xs={4}>
              <div rowSpan="4" style={{ verticalAlign: 'top' }} className="analyst-avatar-section">
                <div style={{ padding: '0px 0px 5px 0px' }}>
                  <div className="companies-image-container text-center">
                    <Link href={`companies/${data.id}`} to={`companies/${data.id}`}>
                      <Image
                        className={'company-card-image'}
                        alt="analyst image"
                        src={data.sImage_path || Avatar}
                        thumbnail
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={8}>
              <div className="companies-description-section">
                <Link
                  style={{ fontSize: 15 }}
                  href={`companies/${data.id}`}
                  to={`companies/${data.id}`}
                  className="card-title"
                >
                  {data.sName}
                </Link>
                <div>Ticker: {data.sBBG_Code ? data.sBBG_Code : ''}</div>
                <div style={{ paddingTop: 5, fontSize: 12 }}>
                  {data.sDescription ? (
                    <div
                      style={{ fontFamily: 'open_sansregular', paddingBottom: '5px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          String(data.sDescription).length <= 150
                            ? data.sDescription
                            : `${String(data.sDescription)
                                .slice(0, 150)
                                .trim()}...`,
                      }}
                    />
                  ) : (
                    <div className="card-description">No description</div>
                  )}
                </div>
              </div>
              <div style={{ float: 'right', verticalAlign: 'bottom' }}>
                <Link
                  href={`companies/${data.id}`}
                  to={`companies/${data.id}`}
                  className="card-read-more"
                >
                  Read more
                </Link>
              </div>
            </Col>
          </Row>
        </Box>
      </Col>
    );
  }

  return (
    <Box style={style} className="text-center">
      No such analyst exists
    </Box>
  );
};

Component.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  style: PropTypes.object,
};

Component.defaultProps = {
  data: {},
  loading: false,
  style: {},
};

export default Component;
