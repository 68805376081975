/*
**************************************************************************************************************
											R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	: 
	Date	: 
**************************************************************************************************************
											Usage Notes
==============================================================================================================

	Footer code

**************************************************************************************************************
											Amendments
==============================================================================================================
	M002 : 13 June 2023 : Zeeshan Siddique
		 * M-4367: Add a link to the Spyder marketing website on header that reads "Powered by Spyder CRM"
	M001 : 31 July 2020 : Nicho
		 * M-2967: Homepage - Global Footer redesign
**************************************************************************************************************
*/
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Grid, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import qs from 'querystring';
import { withRouter, Link } from 'react-router-dom';

import Logo from '../../assets/images/login-logo.png';
import PoweredBy from '../../assets/images/powered-by-spyder.png';

//====================M001==========================

import { footerLinks } from './config';

const Component = ({ history, research, currentUser }) => {
  const queryParams = qs.parse(history.location.search);
  let externalPdfUrl = null;
  let signUpRoute = null;

  const reportViewerPage = history.location.pathname.includes('reports/');
  const externalPdfViewerPage = history.location.pathname.includes('rfs.htm');
  if (!currentUser.data) {
	externalPdfUrl = queryParams['?rfsUrl'];
	signUpRoute = '/signup';
	if (research.data) {
	  const { spyderUser, userExists } = research.data;
	  if (spyderUser) {
		const {
		  email,
		  name: { display },
		} = spyderUser;
		userExists
		  ? (signUpRoute = `/login?email=${email}&redirectUrl=${history.location.search}`)
		  : (signUpRoute = `/signup?name=${display}&email=${email}`);
	  }
	}
  }

  return (
    <>
      {/*====================M001==========================*/}
      <div className="alethiea-footer-section">
        {externalPdfViewerPage ? null : (
          <Grid>
            <Row className="text-center footer-bar">
              <Col xs={12} sm={12} lg={1} md={1} className="aletheia-footer-links">
                {/*====================M001==========================*/}
                <div>
                  {externalPdfUrl ? (
                    <a href={signUpRoute}>
                      <Image src={Logo} alt="logo" className="logo" style={{ height: 55 }} />
                    </a>
                  ) : (
                    <Link href="/" to="/#">
                      <Image src={Logo} alt="logo" className="logo" style={{ height: 55 }} />
                    </Link>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} lg={9} md={9}>
                <Col xs={12} sm={12} lg={12} md={12}>
                  <div className="alethiea-footer-flex places">
                    Hong Kong
                    <span className="footer-dot" />
                    Singapore
                    <span className="footer-dot" />
                    London
                    <span className="footer-dot" />
                    New York
                  </div>
                </Col>
                {/* <div className="alethiea-footer-section"> */}

                {/*====================M001==========================*/}
                <Col xs={12} sm={12} lg={12} md={12}>
                  <div className="alethiea-footer-flex">
                    {footerLinks.map(({ label, path }, index) =>
                      externalPdfUrl ? (
                        <a href={signUpRoute} key={`footer_link_${index}`}>
                          {label}
                        </a>
                      ) : (
                        <>
                          <Link to={path} href={path} key={`footer_link_${index}`}>
                            {label}
                          </Link>
                          {label === 'Contact Us' ? null : <span className="footer-divider" />}
                        </>
                      )
                    )}
                  </div>
                </Col>
              </Col>

              {/*====================M002==========================*/}
              <Col xs={12} sm={12} lg={2} md={2} className="power-by-footer">
                <a href="https://spyder-crm.com" target="_blank">
                  Powered by
                  <br clear="all" />
                  <Image
                    src={PoweredBy}
                    alt="Spyder"
                    className="logo"
                    style={{
                      width: 60,
                    }}
                  />
                </a>
                <br clear="all" />

                {/*====================M002==========================*/}
              </Col>
              {/* </div> */}
            </Row>
            {/*====================M001==========================*/}
            <div style={{ float: 'right', justifyContent: 'space-between', flex: '1' }}></div>
          </Grid>
        )}
      </div>
    </>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired,
  research: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = ({ research: { research }, user: { currentUser } }) => ({
  research,
  currentUser,
});

export default withRouter(connect(mapStateToProps)(Component));
