import React from 'react';
import '../../assets/sass/aletheia-connect.scss';
import { Col, Row } from 'react-bootstrap';

const Component = ({
    pageName,
    pageDesc
}) => {
    return (
        <>
            <div className="headerContainer">
                <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="headersection1">
                    <span className="headerTitle">{pageName}</span>
                    </div>
                    <div className="headerImagediv"></div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="headersection2">
                    <span>{pageDesc}</span>
                    </div>
                </Col>
                </Row>
            </div>
        </>
    )
}

Component.defaultProps = {
    pageName: "",
    pageDesc: "Asia's First Full Scale Independent Investment Advisory Firm"
};
  
export default Component;
