/*
**************************************************************************************************************
												R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	:	Nick Ng
	Date	:	10 Oct 2022
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************

**************************************************************************************************************
*/

import React from 'react';
import { Link } from 'react-router-dom';

const Component = ({}) => (
  <div className="box-signup-confirm">
    <h3 style={{textAlign:'center'}}>
      Thank you for completing the registration process
    </h3>
    <p>
      Please login with your credentials to access our research products.
    </p>
    <Link href="/login" to="/login">
      Login
    </Link>
  </div>
);

export default Component;
