import React from 'react';
import { Col, Row, Grid } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import Box from '../../components/Box';
import * as configActions from '../../actionCreators/Config';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const Container = ({ getParameter, content }) => {
  const [contactUsHTML, setContactUsHTML] = React.useState('');

  React.useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
    getParameter('sRshWebContactHTML');
  }, []);

  React.useEffect(() => {
    if (content != null && content?.sRshWebContactHTML != null) {
      const parsedHTML = parse(content.sRshWebContactHTML);
      setContactUsHTML(parsedHTML);
    }
  }, [content]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
        <meta name="description" content="Contact us page for Alethiea Capital" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {contactUsHTML != '' ? contactUsHTML : null}
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
        <meta name="description" content="Contact us page for Alethiea Capital" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Grid>
        <Box style={{ borderRadius: 5 }}>
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Row>
              <h2>Contact Us</h2>
              <div>
                <h4>Aletheia Capital Global Offices:</h4>
                <h4 style={{ marginBottom: 0 }}>
                  <b style={{ color: '#6a6767' }}>Asia</b>
                </h4>
                <Col sm={12} lg={4} style={{ paddingLeft: 0 }}>
                  <address className="contact-us-address-section">
                    <h4>Aletheia Capital Limited</h4>
                    <p>
                      Level 24, World-wide House
                      <br />
                      19 Des Voeux Road
                      <br />
                      Central, Hong Kong
                    </p>
                    <b>Tel:</b> +852 3470 0011
                    <br />
                    <b>Fax:</b> +852 3575 9468 <br />
                    <b>Email:</b>{' '}
                    <a href="mailto:info@aletheia-capital.com">info@aletheia-capital.com</a>
                  </address>
                </Col>
                <Col sm={12} lg={4} style={{ paddingLeft: 0 }}>
                  <address className="contact-us-address-section">
                    <h4>Aletheia Analyst Network Limited</h4>
                    <p>
                      Level 24, World-wide House
                      <br />
                      19 Des Voeux Road
                      <br />
                      Central, Hong Kong
                    </p>
                  </address>
                </Col>
                <Col sm={12} lg={4} style={{ paddingLeft: 0 }}>
                  <address className="contact-us-address-section">
                    <h4>Aletheia Capital (Singapore) Pte Ltd</h4>
                    <p>
                      1 Fullerton Road
                      <br />
                      #02-01, One Fullerton
                      <br />
                      Singapore 049213
                    </p>
                  </address>
                </Col>
              </div>
            </Row>
            <Row>
              <h4>
                <b style={{ color: '#6a6767' }}>Europe</b>
              </h4>
              <div>
                <address className="contact-us-address-section">
                  <p>
                    <h4>Aletheia Analyst Network Limited</h4>
                    30 Churchill Place
                    <br />
                    London
                    <br />
                    E14 5EU
                    <br />
                    UNITED KINGDOM
                  </p>
                </address>
              </div>
            </Row>
            <Row>
              <h4>
                <b style={{ color: '#6a6767' }}>Americas</b>
              </h4>
              <div>
                <address className="contact-us-address-section">
                  <p>
                    <h4>Aletheia Analyst Network Limited </h4>
                    12 East 49th Street
                    <br />
                    New York, NY 10017
                    <br />
                    U.S.A.
                  </p>
                </address>
              </div>
            </Row>
          </div>
        </Box>
      </Grid> */}
    </div>
  );
};

function mapStateToProps(state) {
  const content = state.Config.parameters;
  return { content };
}

const mapDispatchToProps = dispatch => ({
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
