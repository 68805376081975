import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { doPost } from '../utils/request';

const forgorPassUrl = '/users/forgotPassword';
class Component extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      email: '',
      error: null,
      success: null,
    };
  }

  sendForgetPasswordRequest = e => {
    e.preventDefault();
    const { email } = this.state;

    if (email) {
      doPost(forgorPassUrl, { email }).then(response => {
        const { data, error } = response;

        if (data) {
          if (String(data.success).toLowerCase() === 'true') {
            this.setState({ success: true });
          } else {
            this.setState({ error: data.message });
          }
        } else if (error) {
          this.setState({
            error,
          });
        }
      });
    }
  };

  render() {
    const { showModal, email, error, success } = this.state;
    const { externalPdfUrl, signUpRoute } = this.props;
    return (
      <>
        {externalPdfUrl ? (
          <span href={signUpRoute} className="forgot-password-button">
            <p>Forgot Password333?</p>
          </span>
        ) : (
          <span
            className="forgot-password-button"
            onClick={() => this.setState({ showModal: true })}
          >
            <p>Forgot Password?</p>
          </span>
        )}
        <Modal
          show={showModal}
          onHide={() => this.setState({ showModal: false, error: '', success: '' })}
          className="logoutModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Forgot Password</Modal.Title>
          </Modal.Header>
          <Modal.Body className="forgot-password-modal">
            {success === true ? (
              <p>
                Email has been sent to your email address with a password reset link. Please follow
                the link to reset your password.
              </p>
            ) : (
              <>
                <p>
                  Please enter your email through which you have registered your account and we will
                  send you an email with your new password
                </p>
                <form>
                  <div className="input-group">
                    <input
                      className="form-control"
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                    <span className="input-group-btn">
                      <Button
                        type="submit"
                        className="btn-info"
                        onClick={this.sendForgetPasswordRequest}
                      >
                        {' '}
                        SEND
                      </Button>
                    </span>
                  </div>
                  {error && <p className="text-danger">{error}</p>}
                </form>
              </>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

Component.propTypes = {
  externalPdfUrl: PropTypes.object,
  signUpRoute: PropTypes.string,
};
Component.defaultProps = {
  externalPdfUrl: null,
  signUpRoute: null,
};
export default Component;
