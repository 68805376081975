import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

const Component = ({
  show,
  loading,
  message,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  subHeading,
}) => {

  return (
    <Modal show={show} className="container-fake">
      <Modal.Body>
        <p style={{ padding: '3%', fontSize: 20, fontWeight: 600 }}>{message}</p>
        <p style={{ paddingLeft: '3%', fontSize: 12 }}>{subHeading}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onCancel} disabled={loading}>
          {cancelText}
        </Button>
        <Button bsStyle="primary" disabled={loading} onClick={onConfirm}>
          {confirmText}
        </Button>
      </Modal.Footer>
      <iframe className="frame-fake" src="about:blank" />
    </Modal>
  );
};

Component.propTypes = {
  message: PropTypes.string,
  subHeading: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Component.defaultProps = {
  message: 'Are you sure?',
  subHeading: 'An email will be sent to @userEmail with the updated password.',
  confirmText: 'Yes',
  cancelText: 'Cancel',
  show: false,
  loading: false,
};

export default Component;
