import { all, put, takeLatest, takeEvery } from 'redux-saga/effects';
import qs from 'querystring';

import {
  getAllSuccess,
  getAllFail,
  getSectorSuccess,
  getSectorFail,
} from '../actionCreators/Sector';
import { doGet } from '../utils/request';

function* getSector({ obj }) {
  try {
    const response = yield doGet(`/sectors?id=${obj.id}`);
    yield put(getSectorSuccess(response.data, obj));
  } catch (err) {
    yield put(getSectorFail(err.message));
  }
}

function* getSectors({ obj }) {
  try {
    const response = yield doGet(`/sectors?${qs.stringify(obj)}`);
    yield put(getAllSuccess(response.data, obj));
  } catch (err) {
    yield put(getAllFail(err.message));
  }
}

export default function* watcher() {
  yield all([takeEvery('GET_SECTOR', getSector), takeLatest('GET_SECTORS', getSectors)]);
}
