const initialState = {
  show: false,
  email: '',
  fullName: '',
  userExists: false,
  showHomePageModal: false,
};

export default (state = initialState, { type, email, fullName, userExists }) => {
  switch (type) {
    case 'SHOW_USER_MODAL':
      return {
        show: true,
        email,
        fullName,
        userExists,
      };

    case 'SHOW_HOMEPAGE_MODAL':
      return {
        showHomePageModal: true,
      };

    case 'HIDE_USER_MODAL':
      return initialState;
    case 'HIDE_HOMEPAGE_MODAL':
      return {
        showHomePageModal: false,
      };
    default:
      return state;
  }
};
