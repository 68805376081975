import store from '../config/store';

import routes from './routes';

const router = routerObj => {
  const state = store.getState();
  const user = state.user.currentUser.data;
  const {
    location: { pathname },
  } = routerObj;

  let currentRoute = null;
  let mainLayout = null;
  let homeRoute = null;
  let errorRoute = null;

  routes.forEach(route => {
    if (!currentRoute) {
      //Scroller Restoration on Route change, Patch
      window.scrollTo(0, 0);
      if (route.path) {
        if (route.path.includes(':')) {
          const routePathArray = route.path.split('/');
          const pathnameArray = pathname.split('/');

          if (routePathArray.length === pathnameArray.length) {
            let chk = true;

            for (const i in routePathArray) {
              if (routePathArray[i].includes(':')) {
                routePathArray[i] = pathnameArray[i];
              } else if (routePathArray[i].toLowerCase() !== pathnameArray[i].toLowerCase()) {
                chk = false;
                break;
              }
            }

            if (chk) {
              currentRoute = {
                ...route,
                path: routePathArray.join('/'),
              };
            }
          }
        } else if (route.path.toLowerCase() === pathname.toLowerCase()) {
          currentRoute = route;
        }
      }
    }

    if (!mainLayout && route.default === true && (!route.meta || !route.meta.auth)) {
      mainLayout = route;
    }

    if (!homeRoute && route.default === true && route.meta && route.meta.auth === true) {
      homeRoute = route;
    }

    if (!errorRoute && route.error === true) {
      if (user) {
        errorRoute = {
          ...route,
          meta: {
            layout: 'main',
          },
        };
      } else {
        errorRoute = route;
      }
    }
  });

  if (currentRoute) {
    if (pathname === '/') {
      return user ? homeRoute : mainLayout;
    }

    if (
      String(pathname).toLowerCase() === '/login' ||
      String(pathname).toLowerCase() === '/signup'
    ) {
      return user ? homeRoute : currentRoute;
    }

    return currentRoute;
  }

  if (pathname === '/') {
    return user ? homeRoute : mainLayout;
  }

  return errorRoute;
};

export default router;
