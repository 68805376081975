/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 13 July 2021 : Aman
    * M-0003467: Fine-tuning of social media tiles from selected PDF text or Blog text
**************************************************************************************************************
*/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, Row, Col, Panel } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import {  XYPlot, XAxis, YAxis, ChartLabel, HorizontalGridLines, VerticalGridLines, LineSeries, MarkSeries, Hint } from 'react-vis';
import '../../../../node_modules/react-vis/dist/style.css';
import Table from '../../../components/Table';
import Pagination from '../../../components/Pagination';

import {PostStatDataLabels} from './PostAdminDataTableLabel';

import * as blogActions from '../../../actionCreators/Blog';

const PostStatForm = ({ showModal, setShowModal, post, getPostStat }) => {
    const [paginatedData, setPaginatedData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [graph1Data, setGraph1Data] = React.useState([])
    const [timeSpent, setTimeSpent] = React.useState(0)
    const [hoverVal, setHoverVal] = React.useState()

    React.useEffect(()=>{
        if(showModal){
            getPostStat(post.data.content.posts[0].id);
        }
    },[showModal])

    React.useEffect(()=>{
        if (post.stat.data) {
            var stat = post.stat.data;
            //Do Data Extraction
            var tempGraph1 = {};
            var tempGraph1Data = []
            var tempSpent = 0;

            for (var i =0; i < stat.length; i++) {
                var diffMins = 2;
                //Cal Time Spent On Post
                if (stat[i].dExitTimeUtc!=null) {
                    var dateTimeIn  = new Date(stat[i].dLogTimeUtc);
                    var dateTimeOut = new Date(stat[i].dExitTimeUtc);
                    diffMins = Math.round((dateTimeOut-dateTimeIn)/1000/60); // minutes
                }
                tempSpent += diffMins;

                //Reform Arr
                var date = stat[i].dLogTimeUtc.split('T')[0];
                if (tempGraph1[date]!=null){
                    tempGraph1[date] = tempGraph1[date]+1
                } else {
                    tempGraph1[date] = 1
                }
            }

            for (var key in tempGraph1) {
                var tempDataObj = {
                    x: key,
                    y: tempGraph1[key]
                }
                tempGraph1Data.push(tempDataObj)
            }
            setGraph1Data(tempGraph1Data);
            setTimeSpent((tempSpent/stat.length).toFixed(2));

            //Paginate Data
            var paginatedArr = [];
            for (var i = 0, len = stat.length; i < len; i += 15) {
                paginatedArr.push(stat.slice(i, i + 15));
            }
            setPaginatedData(paginatedArr);
        }
    }, [post.stat.data])

    const sortData = (key, order, singleData) => {
        var mergeArr = [];
        //Merge Paginated Data Back
        for(var i=0; i < paginatedData.length; i++) {
            for (var j=0; j < paginatedData[i].length; j++) {
                mergeArr.push(paginatedData[i][j])
            }
        }
        mergeArr.sort((a,b)=>{
            if(order=='desc'){
                return(a[key]>b[key]?-1:1);
            } else {
                return(a[key]<b[key]?-1:1);
            }
        })
        var paginatedArr = [];
        for (var i = 0, len = mergeArr.length; i < len; i += 15) {
            paginatedArr.push(mergeArr.slice(i, i + 15));
        }
        setPaginatedData(paginatedArr);
    }

    const searchUser = (key) => {
        var filteredData = post.stat.data.filter(
            val =>
                (val.sName && val.sName.toLowerCase().includes(key)) ||
                (val.sCompanyName && val.sCompanyName.toLowerCase().includes(key))
        );
        var paginatedArr = [];
        for (var i = 0, len = filteredData.length; i < len; i += 15) {
            paginatedArr.push(filteredData.slice(i, i + 15));
        }
        setPaginatedData(paginatedArr);
        setPage(0);
    }



  return (
    <>
      <Modal
        show={showModal}
        dialogClassName="posts-stat-modal"
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Post Statistics</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '20px', textAlign: 'center' }}>
            <Row>
                {/* GRAPH 1 - Time Series*/}
                <Col xs={6}>
                    <Panel >
                        <h3>Post Traffic</h3>
                        <XYPlot xType="ordinal" width={500} height={300}>
                            <HorizontalGridLines />
                            <VerticalGridLines />
                            <XAxis />
                            <YAxis />
                            <LineSeries
                                data={graph1Data}
                            />
                            <MarkSeries
                                onValueMouseOver={val=>{
                                    setHoverVal(val);
                                }}
                                onValueMouseOut={()=>{
                                    setHoverVal()
                                }}
                                data={graph1Data}
                            />
                            {hoverVal ? 
                                <Hint value={hoverVal}>
                                    <div className="rv-hint rv-hint__content">
                                        <p>date: {hoverVal.x}</p>
                                        <p>count: {hoverVal.y}</p>
                                    </div>
                                </Hint>
                            : null}
                        </XYPlot>
                    </Panel>
                </Col>
                {/* GRAPH 1 - Time Series*/}
                {/* GRAPH 2 - Time Spent*/}
                <Col xs={6}>
                    <Panel>
                        <h3>Average Time Spent:</h3>
                        <h4>{timeSpent} Mins</h4>
                    </Panel>
                </Col>
                {/* GRAPH 2 - Time Spent*/}
            </Row>
            {/* Paginated Table */}
            <Row>
                {post.stat.data && (
                    <>
                        <Table
                            title={'Statistics'}
                            labels={PostStatDataLabels()}
                            loading={post.loading}
                            data={paginatedData[page]} 
                            onOrderChange={sortData}
                            searchAble
                            searchUser={searchUser}
                        />
                        <Pagination
                            className="centrePagination"
                            current={page + 1}
                            total={paginatedData.length}
                            onClick={p => setPage(p-1)}
                        />
                    </>
                )}
            </Row>
            {/* Paginated Table */}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ blog: { post }}) => ({
    post,
});

const mapDispatchToProps = dispatch => ({
  getPostStat: bindActionCreators(blogActions.getPostStat, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostStatForm));