/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
  M001 : 27 Apr 2021 Nick
    * M-0003382: Website UI Improve - Aletheia Connect
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Grid } from 'react-bootstrap';

const ConnectHeader = ({ TITLE, DESCRIPTION }) => {
  const [moreinfo, setMoreinfo] = React.useState(false);
  const MoreInfoClick = () => {
    setMoreinfo(!moreinfo);
  };
  return (
    // ========M001===================
    <div style={{ marginTop: '20px' }}>
      <Row>
        {TITLE ? (
          <Col xs={8}>
            <p className="VideoTitleSection">
              NOW PLAYING &nbsp;&nbsp;&nbsp;&nbsp;
              <strong>{TITLE}</strong>
            </p>
          </Col>
        ) : (
          <></>
        )}

        {DESCRIPTION ? (
          <Col xs={4}>
            <p className="VideoMoreInfo" onClick={MoreInfoClick}>
              {moreinfo ? 'SHOW LESS' : 'SHOW MORE'}
            </p>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      {moreinfo ? (
        <Row>
          <Col xs={12}>
            <p className="videodescpara">{DESCRIPTION}</p>
          </Col>
        </Row>
      ) : null}
    </div>
    // ========M001===================
  );
};

ConnectHeader.propTypes = {
  TITLE: PropTypes.string,
  DESCRIPTION: PropTypes.string,
};

ConnectHeader.defaultProps = {
  TITLE: '',
  DESCRIPTION: '',
};

export default ConnectHeader;
