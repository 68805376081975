/*
**************************************************************************************************************
                                                R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
    Author	:	Zeeshan
    Date	:	27 March 2023 
**************************************************************************************************************
                                                Usage Notes
**************************************************************************************************************

**************************************************************************************************************
                                                Amendments
**************************************************************************************************************
    M001 : 18 April 2023 : Zeeshan
         * M-0004377: on invalid token display error message to user    
**************************************************************************************************************
*/

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button, Form, Glyphicon } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import FormField from '../../../components/FormFields';
import { passwordPolicy } from '../../../config/validations';
import { doGet } from '../../../utils/request';


const mapStateToProps = (state) => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('p')
    return {
        initialValues: {
            token: token,
        }
    }
}

const validate = values => {
    const errors = {};
    if (values.newpassword && values.confirmpassword) {
        if (
            values.newpassword.length !== values.confirmpassword.length ||
            values.newpassword !== values.confirmpassword
        ) {
            errors.confirmpassword = 'Create password and confirm password do not match ';
        }
    }
    return errors;
};

export const Component = ({
    message,
    loading,
    handleSubmit,
}) => {
    const [showPassword, setShowPassord] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassord] = React.useState(false);
    const [isValid, setIsValid] = React.useState(true);
    const [userToken, setUserToken] = React.useState('');

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const token = query.get('p')
        setUserToken(token);
        validateResetToken(token);
    }, []);

    const validateResetToken = (token) => {
        doGet(`/users/validateResetToken?token=${token}`)
            .then(response => {
                //=====================M001===========[================

                const { data, error } = response;
                if (data) {
                    if (String(data.success).toLowerCase() === 'true') {
                        setIsValid(true)
                    } else if (data.toLowerCase() === 'expired')
                        setIsValid(false)
                    else {
                        setIsValid(false)
                    }
                } else if (error) {
                    setIsValid(false)
                }
                //=====================M001===========[================

            })
            .catch(error => {
                setIsValid(false)
            })
    }
    return (
        isValid ? (
            <Form className="box-signup box" onSubmit={handleSubmit}>
                <Field
                    name="newpassword"
                    type={showPassword ? 'text' : 'password'}
                    component={FormField}
                    label="New Password"
                    validate={passwordPolicy}
                    disabled={loading}
                    required
                />
                {showPassword ? (
                    <Glyphicon
                        glyph="glyphicon glyphicon-eye-open"
                        className="showHide-icon-login"
                        onClick={() => {
                            setShowPassord(false);
                        }}
                    />
                ) : (
                    <Glyphicon
                        glyph="glyphicon glyphicon-eye-close"
                        className="showHide-icon-login"
                        onClick={() => {
                            setShowPassord(true);
                        }}
                    />
                )}
                <Field
                    name="confirmpassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    component={FormField}
                    label="Confirm Password"
                    validate={passwordPolicy}
                    disabled={loading}
                    required
                />
                {showConfirmPassword ? (
                    <Glyphicon
                        glyph="glyphicon glyphicon-eye-open"
                        className="showHide-icon-login"
                        onClick={() => {
                            setShowConfirmPassord(false);
                        }}
                    />
                ) : (
                    <Glyphicon
                        glyph="glyphicon glyphicon-eye-close"
                        className="showHide-icon-login"
                        onClick={() => {
                            setShowConfirmPassord(true);
                        }}
                    />
                )}
                {message && <p className="text-danger" dangerouslySetInnerHTML={{ __html: message }}></p>}
                {/* SUBMIT BUTTON OR LOGIN OPTION */}
                <div>
                    <Button type="submit" bsStyle="primary" className="btn-block" disabled={loading}>
                        Reset
                    </Button>
                </div>
            </Form>) : <div className="box-signup-footer"> Your reset password link is expired. Please try again </div>
    );
};

Component.propTypes = {
    className: PropTypes.string,
    showSignUpMessage: PropTypes.bool,
    showSignUpMessageForModal: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    goTo: PropTypes.func,
    initialValues: PropTypes.shape({
        token: PropTypes.string,
    }).isRequired,
    message: PropTypes.string,
    loading: PropTypes.bool,
    externalPdfUrl: PropTypes.object,
    signUpRoute: PropTypes.string,
};

Component.defaultProps = {
    className: null,
    showSignUpMessage: false,
    showSignUpMessageForModal: false,
    message: null,
    loading: false,
    externalPdfUrl: null,
    signUpRoute: null,
};
export default connect(mapStateToProps)(reduxForm({
    form: 'resetUserPasswordForm',
    validate,
    enableReinitialize: true
})(Component))