const initialState = {
    selectedBlog: {
        data: {
            title: "INVESTMENT INSIGHTS",
            subtitle: ""
        },
        loading: false
    },
    posts: {
        data: null,
        loading: false,
    },
    post: {
        data: {
            content: null,
            associatedResearches: {
                researches: []
            }
        },
        stat: {
            data: null,
            loading: false,
        },
        loading: false,
    }
}

export default (state = initialState, { type, error, data, obj, index }) => { 
    switch (type) {
        case 'GET_BLOG_INFO': 
            return {
            ...state,
                selectedBlog: {
                    ...state.selectedBlog,
                    loading: true
                }
            };
        
        case 'GET_BLOG_INFO_SUCCESS':
            return {
            ...state,
                selectedBlog: {
                    ...state.selectedBlog,
                    data,
                    loading: false
                }
            };

        case 'GET_BLOG_INFO_FAILURE': 
            return {
            ...state,
                selectedBlog: {
                    ...state.selectedBlog,
                    loading: false
                }
            };

        case 'GET_BLOG_POSTS':
            return {
            ...state,
                posts: {
                    ...state.posts,
                    loading: true,
                },
            };
            
        case 'GET_BLOG_POSTS_SUCCESS':
            return {
                ...state,
                posts: {
                    loading: false,
                    success: true,
                    data: data,
                },
            };
        
        case 'GET_BLOG_POSTS_FAILURE':
            return {
                ...state,
                posts: {
                    ...state.posts,
                    loading: false,
                    error,
                },
            };

        case 'GET_BLOG_POST':
            return {
            ...state,
                post: {
                    ...state.post,
                    loading: true,
                },
            };
            
        case 'GET_BLOG_POST_SUCCESS':
            return {
                ...state,
                post: {
                    ...state.post,
                    loading: false,
                    success: true,
                    data: {
                        ...state.post.data,
                        content: data,
                    }
                },
            };
        
        case 'GET_BLOG_POST_FAIL':
            return {
                ...state,
                post: {
                    ...state.post,
                    loading: false,
                    data: {
                        error: data
                    },
                },
            };

        case 'GET_BLOG_POST_ASSOCIATED_RESEARCHES':
            return {
            ...state,
                post: {
                    ...state.post,
                    loading: true,
                },
            };
            
        case 'GET_BLOG_POST_ASSOCIATED_RESEARCHES_SUCCESS':
            return {
                ...state,
                post: {
                    ...state.post,
                    loading: false,
                    success: true,
                    data: {
                        ...state.post.data,
                        associatedResearches: data,
                    }
                },
            };
        
        case 'GET_BLOG_POST_ASSOCIATED_RESEARCHES_FAILURE':
            return {
                ...state,
                post: {
                    ...state.post,
                    loading: false,
                    error,
                },
            };
        
        case 'GET_BLOG_POST_STAT':
            return {
            ...state,
                post: {
                    ...state.post,
                    stat: {
                        loading: true,
                    }
                },
            };
            
        case 'GET_BLOG_POST_STAT_SUCCESS':
            return {
                ...state,
                post: {
                    ...state.post,
                    success: true,
                    stat: {
                        loading: false,
                        data: data
                    }
                },
            };
        
        case 'GET_BLOG_POST_STAT_FAILURE':
            return {
                ...state,
                post: {
                    ...state.post,
                    error,
                    stat: {
                        loading: false,
                    }
                },
            };

        default:
            return state;
    }
    
}