import React from 'react';
import { Tab, ButtonToolbar, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
import UserTable from './usersTable';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as adminActions from '../../../actionCreators/Admin';

const WebsiteUser = ({getAllUsers, users}) => {
    const [tab, setTab] = React.useState('suggestions');
    React.useEffect(()=>{
        if (tab=='suggestions') {
            getAllUsers('website', 'suggestion=1')
        } else {
            getAllUsers('website', '')
        }
    },[tab])

    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey={tab}>
                <Tab.Content animation>
                    <Tab.Pane eventKey="suggestions">
                        <div className="button-group-box favourites">
                            <ButtonToolbar>
                            <ToggleButtonGroup type="radio" name="options" defaultValue={tab}>
                                <ToggleButton value="suggestions" onClick={() => setTab('suggestions')}>
                                    Import Suggestions
                                </ToggleButton>
                                <ToggleButton value="all_users" onClick={() => setTab('all_users')}>
                                    All Website Users
                                </ToggleButton>
                            </ToggleButtonGroup>
                            </ButtonToolbar>
                        </div>
                    </Tab.Pane>
                    <UserTable userType={'website'} userData={users.data} loading={users.loading}></UserTable>
                </Tab.Content>
            </Tab.Container>
        </>
    )
};

const mapStateToProps = state => {
    const {
        admin: { users }
    } = state;
    return { users };
};

const mapDispatchToProps = dispatch => ({
    getAllUsers: bindActionCreators(adminActions.getAllUsers, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WebsiteUser));