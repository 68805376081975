const initialState = {
    users: {
        data: [],
        loading: false,
        error: null,
    },
    firms: {
        data: [],
        loading: false,
        error: null,
    },
    spyderInsertStatus: {
        message: null,
        loading: false,
        success: null,
    },
    loading: false,
    error: null
};

export default (state = initialState, { type, error, data, obj }) => {
    switch (type) {
        case 'GET_USERS':
            return {
                ...state,
                users: {
                ...state.users,
                loading: true,
                },
            };

        case 'GET_USERS_SUCCESS':
            return {
                ...state,
                users: {
                ...state.users,
                loading: false,
                data,
                },
            };

        case 'GET_USERS_FAIL':
            return {
                ...state,
                users: {
                ...state.users,
                loading: false,
                error,
                },
            };
        
        case 'SEND_NEW_PASSWORD':
        case 'SEND_VERIFY_EMAIL':
        case 'CHANGE_USER_STATUS':
            return {
                ...state,
                loading: true,
            }

        case 'SEND_NEW_PASSWORD_SUCCESS':
        case 'SEND_VERIFY_EMAIL_SUCCESS':
        case 'CHANGE_USER_STATUS_SUCCESS':
            return {
                ...state,
                loading: false,
            }

        case 'SEND_NEW_PASSWORD_FAIL':
        case 'SEND_VERIFY_EMAIL_FAIL':
        case 'CHANGE_USER_STATUS_FAIL':
            return {
                ...state,
                loading: false,
                error
            }

        case 'SEARCH_SPYDER_FIRMS':
            return {
                ...state,
                firms: {
                ...state.firms,
                loading: true,
                },
            };

        case 'SEARCH_SPYDER_FIRMS_SUCCESS':
            return {
                ...state,
                firms: {
                ...state.firms,
                loading: false,
                data,
                },
            };

        case 'SEARCH_SPYDER_FIRMS_FAIL':
            return {
                ...state,
                firms: {
                ...state.firms,
                loading: false,
                error,
                },
            };
        
        case 'INSERT_USER_TO_SPYDER':
            return {
                ...state,
                spyderInsertStatus: {
                    success: null,
                    message: null,
                    loading: true,
                },
            };

        case 'INSERT_USER_TO_SPYDER_SUCCESS':
            return {
                ...state,
                spyderInsertStatus: {
                    ...state.spyderInsertStatus,
                    loading: false,
                    success: obj.success,
                    message: obj.message,
                },
            };

        case 'INSERT_USER_TO_SPYDER_FAIL':
            return {
                ...state,
                spyderInsertStatus: {
                    ...state.spyderInsertStatus,
                    success: false,
                    message: error,
                    loading: false,
                },
            };


        default:
            return state;
    }
}