/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 05 August 2020 : Aman
		* M-0002939: Our People page should allow sort ordering of staff from the admin page.
**************************************************************************************************************
*/

export const getAll = obj => ({
  type: 'GET_ANALYSTS',
  obj,
});

export const getAllSuccess = (data, obj) => ({
  type: 'GET_ANALYSTS_SUCCESS',
  data,
  obj,
});

export const getAllFail = error => ({
  type: 'GET_ANALYSTS_FAIL',
  error,
});

export const getAnalyst = obj => ({
  type: 'GET_ANALYST',
  obj,
});

export const getAnalystSuccess = (data, obj) => ({
  type: 'GET_ANALYST_SUCCESS',
  data,
  obj,
});

export const getAnalystFail = error => ({
  type: 'GET_ANALYST_FAIL',
  error,
});

export const getOurPeople = obj => ({
  type: 'GET_OUR_PEOPLE',
  obj,
});

export const getOurPeopleSuccess = ourPeople => ({
  type: 'GET_OUR_PEOPLE_SUCCESS',
  ourPeople,
});

export const getOurPeopleFail = error => ({
  type: 'GET_OUR_PEOPLE_FAIL',
  error,
});

export const updateAnalyst = obj => ({
  type: 'UPDATE_ANALYST',
  obj,
});
export const updateAnalystSuccess = obj => ({
  type: 'UPDATE_ANALYST_SUCCESS',
  obj,
});

export const updateAnalystFail = error => ({
  type: 'UPDATE_ANALYST_FAIL',
  error,
});

export const showEditProfileModal = () => ({
  type: 'SHOW_EDIT_PROFILE_MODAL',
});

export const hideEditProfileModal = () => ({
  type: 'HIDE_EDIT_PROFILE_MODAL',
});

export const updateOurTeam = obj => ({
  type: 'UPDATE_OUR_TEAM',
  obj,
});
export const updateOurTeamSuccess = obj => ({
  type: 'UPDATE_OUR_TEAM_SUCCESS',
  obj,
});

export const updateOurTeamFail = error => ({
  type: 'UPDATE_OUR_TEAM_FAIL',
  error,
});

//====================M001=====[====================
export const sortOurTeam = obj => ({
  type: 'SORT_OUR_TEAM',
  obj,
});
export const sortOurTeamSuccess = obj => ({
  type: 'SORT_OUR_TEAM_SUCCESS',
  obj,
});

export const sortOurTeamFail = error => ({
  type: 'SORT_OUR_TEAM_FAIL',
  error,
});
//====================M001=====[====================
