export const show = (email, fullName, userExists = false) => ({
  type: 'SHOW_USER_MODAL',
  email,
  fullName,
  userExists,
});

export const hide = () => ({
  type: 'HIDE_USER_MODAL',
});

export const showHomePageModal = () => ({
  type: 'SHOW_HOMEPAGE_MODAL',
});

export const hideHomePageModal = () => ({
  type: 'HIDE_HOMEPAGE_MODAL',
});
