/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 5 August 2020 : Nicho
		* M-0002970: Homepage - Vision section redesign
**************************************************************************************************************
*/

import React from 'react';
import {Col} from "react-bootstrap";

const visionText =
  '" To be a client centric, technology driven company with market leading, independent and original Asian investment research as the key product. "';

const Container = () => (
    //====================M001==========================
  <Col xs={12} sm={4} md={4}>
      <div className="our-vision-section">
        <div className="vision-block">
            <h1 className="section-title">Our Vision</h1>
          <p>{visionText}</p>
        </div>
      </div>
  </Col>
    //====================M001==========================
);

export default Container;
