
/*
**************************************************************************************************************
                                                R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
    Author	:	
    Date	  :	
**************************************************************************************************************
                                                Usage Notes
**************************************************************************************************************

**************************************************************************************************************
                                                Amendments
**************************************************************************************************************
   M001 : 09 May 2023 : Zeeshan Siddique
       * M-4372: Public registration to have separate First and Last Name fields instead of single name field and add optional Company Field   
**************************************************************************************************************
*/



import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Glyphicon } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import FormField from '../../../components/FormFields';

import {
  alphaSpecial,
  emailPolicy,
  firstNamePolicy,
  lastNamePolicy,
  passwordPolicy,
} from '../../../config/validations';

const validate = values => {
  const errors = {};

  if (values.password && values.confirmPassword) {
    if (
      values.password.length !== values.confirmPassword.length ||
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = 'Create password and confirm password do not match';
    }
  }

  return errors;
};

export const Component = ({
  className,
  message,
  loading,
  handleSubmit,
  showSignUpMessageForModal,
  goTo,
  nameRequired
}) => {
  const [showPassword, setShowPassord] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassord] = React.useState(false);
  return (
    <Form className={className} onSubmit={handleSubmit}>

      {/* =====================M001===========[================ */}

      <Field
        name="firstName"
        type="text"
        component={FormField}
        label="First Name"
        validate={firstNamePolicy}
        normalize={alphaSpecial}
        disabled={loading}
        required
      />
      <Field
        name="lastName"
        type="text"
        component={FormField}
        label="Last Name"
        validate={lastNamePolicy}
        normalize={alphaSpecial}
        disabled={loading}
        required
      />
      <Field
        name="companyName"
        type="text"
        component={FormField}
        label="Company Name"
        normalize={alphaSpecial}
        disabled={loading}
      />
      {/* =====================M001===========[================ */}
      <Field
        name="email"
        type="text"
        component={FormField}
        label="Email"
        validate={emailPolicy}
        disabled={loading}
        required
      />
      {showPassword ? (
        <Glyphicon
          glyph="glyphicon glyphicon-eye-open"
          className="showHide-icon"
          onClick={() => {
            setShowPassord(false);
          }}
        />
      ) : (
        <Glyphicon
          glyph="glyphicon glyphicon-eye-close"
          className="showHide-icon"
          onClick={() => {
            setShowPassord(true);
          }}
        />
      )}
      <Field
        name="password"
        type={showPassword ? 'text' : 'password'}
        component={FormField}
        label="Password"
        validate={passwordPolicy}
        disabled={loading}
        required
      />
      {showConfirmPassword ? (
        <Glyphicon
          glyph="glyphicon glyphicon-eye-open"
          className="showHide-icon"
          onClick={() => {
            setShowConfirmPassord(false);
          }}
        />
      ) : (
        <Glyphicon
          glyph="glyphicon glyphicon-eye-close"
          className="showHide-icon"
          onClick={() => {
            setShowConfirmPassord(true);
          }}
        />
      )}
      <Field
        name="confirmPassword"
        type={showConfirmPassword ? 'text' : 'password'}
        component={FormField}
        label="Confirm Password"
        validate={passwordPolicy}
        disabled={loading}
        required
      />
      <Field
        name="acceptTandC"
        type="checkbox"
        style={{ padding: '40px 0px 15px 0px' }}
        component={FormField}
        label={<p>By signing in I am agreeing to the <a href="/terms">Terms of use</a> and <a href="/privacy">Privacy Policy</a> of the company</p>}
        disabled={loading}
        required
      />

      {message && <p className="text-danger">{message}</p>}

      {/* SUBMIT BUTTON OR LOGIN OPTION */}
      <div>
        <Button type="submit" bsStyle="primary" className="btn-block" disabled={loading}>
          Signup
        </Button>
        <div style={{ textAlign: 'center', marginTop: 10 }}>
          {showSignUpMessageForModal ? (
            <span className="signup-already-account-section">
              Already have an account.{' '}
              <span className="modal-navigator" onClick={() => goTo('signin')}>
                Login
              </span>
            </span>
          ) : (
            <span className="signup-already-account-section">
              Already have an account.{' '}
              <Link href="/login" to="/login">
                Login
              </Link>
            </span>
          )}
        </div>
      </div>
      {/* SUBMIT BUTTON OR LOGIN OPTION */}

    </Form>
  );
};

Component.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  message: PropTypes.string,
  loading: PropTypes.bool,
  showSignUpMessageForModal: PropTypes.bool,
  goTo: PropTypes.func.isRequired,
};

Component.defaultProps = {
  className: '',
  handleSubmit: () => { },
  message: null,
  loading: false,
  showSignUpMessageForModal: false,
  nameRequired: true,
};

export default reduxForm({
  form: 'Signup', // a unique identifier for this form
  validate,
})(Component);
