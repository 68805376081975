/*
**************************************************************************************************************
												R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	:	Nick Ng
	Date	:	10 Oct 2022
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************
	M005 : 10 Oct 2022 : Nick Ng : Core
		 * M-4180: Mirgrate research portal to use public module api and deprecate Node.js
  M004 : 19 May 2022 : Adil
		* M-3944: Deleting a homepage slider sometimes deletes 2 instead of the one selected
  M003 : 1 June 2021 : Nick
		* M-3426: Add markets and stocks coverage menu under latest research
  M002 : 21 April 2021 : Aman
		* M-0003347: Allow upload of Our People photos to be uploaded via Team Management page
  M001 : 2 Sept 2020 : Nicholas
		* M-0003008: Increase the number of homepage banners
**************************************************************************************************************
*/

const initialState = {
  uploadImage: {
    loading: false,
    error: null,
    success: null,
    data: [],
  },
  headerImage: {
    loading: false,
    error: null,
    data: [],
  },
  favourites: {
    loading: false,
    error: null,
    data: [],
  },
  posts: {
    loading: false,
    error: null,
    data: [],
  },
  //====================M002==========================
  analystImage: {
    loading: false,
    error: null,
    success: null,
    data: [],
  },
  events: {
    data: [],
    loading: false,
    approvalList: [],
  },
  //====================M002==========================
  sliderState: null,
  currentTab: null,
  showEdit: false,
  homePageSliders: null,
  fullScreenMode: false,
  suggestionBox: false,
  sliderSettingsTab: 0,
  disableSaveButton: true,
  //====================M004============[=============
  deleteButton: {
    disabled: false,
  },
  //====================M004============]=============
  navitems: {
    loading: false,
    error: null,
    data: [],
  },
  parameters: null,
  customPageParameter: null,
};

export default (state = initialState, { type, error, data, obj, index }) => {
  switch (type) {
    case 'UPLOAD_HEADER_IMAGE':
      return {
        ...state,
        uploadImage: {
          ...state.uploadImage,
          loading: true,
        },
        headerImage: {
          loading: true,
        },
      };

    case 'UPLOAD_HEADER_IMAGE_SUCCESS':
      return {
        ...state,
        uploadImage: {
          ...state.uploadImage,
          loading: false,
          success: true,
          data,
        },
        homePageSliders: obj,
      };

    case 'UPLOAD_HEADER_IMAGE_FAILURE':
      return {
        ...state,
        uploadImage: {
          ...state.uploadImage,
          loading: false,
          error,
        },
      };
    //====================M002==========================
    case 'UPLOAD_ANALYST_IMAGE':
      return {
        ...state,
        analystImage: {
          ...state.analystImage,
          loading: true,
        },
      };

    case 'UPLOAD_ANALYST_IMAGE_SUCCESS':
      return {
        ...state,
        analystImage: {
          ...state.analystImage,
          loading: false,
          success: true,
          data,
        },
      };

    case 'UPLOAD_ANALYST_IMAGE_FAILURE':
      return {
        ...state,
        analystImage: {
          ...state.analystImage,
          loading: false,
          error,
        },
      };
    //====================M002==========================
    case 'GET_HEADER_IMAGE':
      return {
        ...state,
        headerImage: {
          ...state.headerImage,
          loading: true,
        },
        uploadImage: {
          loading: true,
        },
      };

    case 'GET_HEADER_IMAGE_SUCCESS':
      return {
        ...state,
        headerImage: {
          ...state.headerImage,
          loading: false,
        },
        homePageSliders: data.headerImage, //M005
        uploadImage: {
          loading: false,
        },
      };

    case 'GET_HEADER_IMAGE_FAILURE':
      return {
        ...state,
        headerImage: {
          ...state.headerImage,
          loading: false,
          error,
        },
      };
    //====================M004============[=============
    case 'SAVE_SLIDER_ORDER':
      return {
        ...state,
        deleteButton: {
          disabled: true,
        },
      };

    case 'SAVE_SLIDER_ORDER_SUCCESS':
      return {
        ...state,
        deleteButton: {
          disabled: false,
        },
      };

    case 'SAVE_SLIDER_ORDER_FAILURE':
      return {
        ...state,
        deleteButton: {
          disabled: false,
        },
      };
    //====================M004============]=============

    case 'SET_LAYOUT':
      return {
        ...state,
        fullScreenMode: obj,
      };

    case 'SET_HEADER_IMAGE_DEFAULT':
      return {
        ...state,
        headerImage: {
          loading: true,
        },
      };

    case 'SET_HEADER_IMAGE_DEFAULT_SUCCESS':
      return {
        ...state,
        headerImage: {
          ...state.headerImage,
          loading: false,
          ...data,
        },
      };

    case 'GET_FAVOURITES':
      return {
        ...state,
        favourites: {
          ...state.favourites,
          loading: true,
        },
      };

    case 'GET_FAVOURITES_SUCCESS':
      return {
        ...state,
        favourites: {
          ...state.favourites,
          loading: false,
          success: true,
          data,
        },
      };

    case 'GET_FAVOURITES_FAILURE':
      return {
        ...state,
        favourites: {
          ...state.favourites,
          loading: false,
          error,
        },
      };
    case 'SET_FAVOURITES':
      return {
        ...state,
        favourites: {
          ...state.favourites,
          loading: true,
        },
      };

    case 'SET_FAVOURITES_SUCCESS':
      return {
        ...state,
        favourites: {
          ...state.favourites,
          loading: false,
          success: true,
        },
      };

    case 'SET_FAVOURITES_FAILURE':
      return {
        ...state,
        favourites: {
          ...state.favourites,
          loading: false,
          error,
        },
      };
    case 'SET_SUGGESTION_BOX':
      return {
        ...state,
        suggestionBox: obj,
      };
    case 'SET_SLIDER_STATE':
      return {
        ...state,
        homePageSliders: obj,
        disableSaveButton: false,
      };

    case 'SET_ACTIVE_TAB_STATE':
      return {
        ...state,
        sliderSettingsTab: index,
      };
    case 'CREATE_POST':
    case 'GET_POSTS':
    case 'DELETE_POST':
    case 'PUBLISH_POST':
    case 'UNPUBLISH_POST':
    case 'UPDATE_POST':
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: true,
        },
      };

    case 'GET_POSTS_SUCCESS':
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: false,
          success: true,
          data: obj,
        },
      };

    case 'GET_POSTS_FAILURE':
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: false,
          error,
        },
      };
    case 'CREATE_POST_SUCCESS':
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: false,
          success: true,
          data: obj,
        },
      };

    case 'CREATE_POST_FAILURE':
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: false,
          error,
        },
      };

    case 'DELETE_POST_SUCCESS':
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: false,
          success: true,
          data: obj,
        },
      };

    case 'DELETE_POST_FAILURE':
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: false,
          error,
        },
      };
    case 'UPDATE_POST_SUCCESS':
      return {
        ...state,
        showEdit: false,
        posts: {
          ...state.posts,
          loading: false,
          success: true,
          data: {
            posts: state.posts.data.posts.map(item =>
              item.id === obj.post.id
                ? {
                    ...item,
                    html: obj.post.html,
                    draft: obj.post.draft,
                  }
                : item
            ),
          },
        },
      };

    case 'UPDATE_POST_FAILURE':
      return {
        ...state,
        showEdit: false,
        posts: {
          ...state.posts,
          loading: false,
          error,
        },
      };
    case 'SHOW_EDIT_POST':
      return {
        ...state,
        showEdit: obj,
      };
    case 'PUBLISH_POST_SUCCESS':
    case 'UNPUBLISH_POST_SUCCESS':
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: false,
          success: true,
          data: {
            posts: obj.posts,
          },
        },
      };

    case 'PUBLISH_POST_FAILURE':
    case 'UNPUBLISH_POST_FAILURE':
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: false,
          error,
        },
      };
    //====================M001==========================
    case 'SET_NEW_SLIDER':
      return {
        ...state,
        homePageSliders: obj,
        headerImage: {
          loading: false,
        },
        uploadImage: {
          loading: false,
        },
      };
    //====================M001==========================
    case 'SET_NEW_KEYWORD':
      return {
        ...state,
        favourites: {
          ...state.favourites,
          loading: false,
          success: true,
          data: {
            favourites: obj,
          },
        },
      };
    case 'DELETE_KEYWORD':
      return {
        ...state,
        favourites: {
          ...state.favourites,
          loading: true,
          error,
        },
      };
    case 'DELETE_KEYWORD_SUCCESS':
      return {
        ...state,
        favourites: {
          ...state.favourites,
          loading: false,
          success: true,
          data: {
            favourites: obj.favourites,
          },
        },
      };

    case 'DELETE_KEYWORD_FAILURE':
      return {
        ...state,
        favourites: {
          ...state.favourites,
          loading: false,
          error,
        },
      };
    case 'GET_EVENTS_APPROVAL_EVENTS':
      return {
        ...state,
        events: {
          ...state.events,
          loading: true,
          error,
        },
      };
    case 'GET_EVENTS_APPROVAL_EVENTS_SUCCESS':
      return {
        ...state,
        events: {
          data: obj,
          loading: false,
        },
      };
    case 'GET_EVENTS_APPROVAL_EVENTS_FAIL':
      return {
        ...state,
        events: {
          data: [],
          loading: false,
        },
      };

    case 'GET_EVENT_APPROVAL_LIST':
      return {
        ...state,
        events: {
          ...state.events,
          loading: true,
          error,
        },
      };
    case 'GET_EVENT_APPROVAL_LIST_SUCCESS':
      return {
        ...state,
        events: {
          ...state.events,
          approvalList: obj,
          loading: false,
        },
      };
    case 'GET_EVENT_APPROVAL_LIST_FAIL':
      return {
        ...state,
        events: {
          ...state.events,
          approvalList: [],
          loading: false,
        },
      };
    //====================M003==========================
    case 'GET_NAVITEMS':
      return {
        ...state,
        navitems: {
          ...state.navitems,
          loading: true,
        },
      };

    case 'GET_NAVITEMS_SUCCESS':
      return {
        ...state,
        navitems: {
          ...state.navitems,
          loading: false,
          success: true,
          data,
        },
      };

    case 'GET_NAVITEMS_FAILURE':
      return {
        ...state,
        navitems: {
          ...state.navitems,
          loading: false,
          error,
        },
      };

    case 'GET_PARAMETER_SUCCESS':
      return {
        ...state,
        parameters: data,
      };

    case 'GET_CUSTOMPAGE_PARAMETER_SUCCESS':
      return {
        ...state,
        customPageParameter: data,
      };
    //====================M003==========================
    default:
      return state;
  }
};
