export const adminMenuConfig = () => 
[
  {
    label: 'Homepage Slider',
    path: '/admin/site-management',
  },
  {
    label: 'Report Management',
    path: '/admin/reports',
  },
  {
    label: 'Our People Ordering',
    path: '/admin/team-management',
  },
  {
    label: 'Our Firm Management',
    path: '/admin/OurFirmManagement',
  },
  {
    label: 'Blog Management',
    path: '/admin/favorites',
  },
  {
    label: 'CONNECT Approval',
    path: '/admin/connect/approval',
  },
]