/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
  M001 : 20 January 2020 : Nick
    * M-0003232: Email verification message on website should have a login button
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Button, Form, Row, Image, Col, Tab, Tabs } from 'react-bootstrap';
import FormField from '../../../components/FormFields';
import { loginEmailPolicy,passwordPolicy } from '../../../config/validations';
import Box from '../../../components/Box'
import Logo from '../../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { doPost } from '../../../utils/request';
import './EmailCookieForm.scss'; //M001

const forgorPassUrl = '/users/forgotPassword';

//==================M001====================
const validate = values => {
  const errors = {};
  if (values.password) {
    errors.password = values.password && values.password.length < 7 ? 'Password should be at least 7 characters long' : undefined;
  }
  return errors;
};
//==================M001====================
export const Component = ({
  loading,
  message,
  handleSubmit,
  details,
  userMessage
}) => {
  const [usePassword, setUsePassord] = React.useState(false);
  const [passwordMsg, setPasswordMsg] = React.useState('');

  React.useEffect(() => {
    if (userMessage) {
      setPasswordMsg(userMessage);
    }
  },[userMessage]);

  //Forget Password Request
  const sendForgetPasswordRequest = email => {
    if (email) {
      doPost(forgorPassUrl, { email }).then(response => {
        const { data, error } = response;
          if (data) {
            if (String(data.success).toLowerCase() === 'true') {
              setPasswordMsg('Email has been sent to your email address with a password reset link. Please follow the link to enter your password.')
            } else {
              setPasswordMsg(data.message);
            }
          } else if (error) {
            setPasswordMsg('Internal Error. Please try again later');
          }
        });
    };
  }

  //Hide Submit Button
  //==================M001====================
  const hideSubmitButton = () => {
    try {
      if(message.includes('check your email')) {
        return (
          <>
            <Button style={{width:'100%'}} bsStyle="primary" disabled>
              Verify
            </Button>
            <br/>
            <br/>
          </>
        )
      } else {
        return (
          <>
            <Button style={{width:'100%'}} bsStyle="primary" onClick={handleSubmit((obj,dispatch,props)=>{
              obj.type = 'verify'
              props.onSubmit(obj);
            })}>
              Verify
            </Button>
            <br/>
            <br/>
          </>
        )
      }
    } catch (e) {
      return (
        <></>
      )
    }
  }

  const hidePasswordButton = () => {
    try {
      if(document.getElementById("password").value=="") {
        return (
          <>
            <Button style={{width:'100%'}} bsStyle="primary" disabled>
              Login
            </Button>
            <br/>
            <br/>
          </>
        )
      } else {
        return (
          <>
            <Button style={{width:'100%'}} bsStyle="primary" onClick={handleSubmit((obj,dispatch,props)=>{
                obj.type = 'login'
                props.onSubmit(obj);
              })}>
              Login
            </Button>
            <br/>
            <br/>
          </>
        )
      }
    } catch (e) {
      return (
        <></>
      )
    }
  }

  //==================M001====================
  return (
      <Col xs={12} mdOffset={1} md={10} lgOffset={3} lg={6} >
        <Box className="box-signup">
          <Row className="form-container-logo">
              <Link href="/" to="/#">
                  <Image src={Logo} className="box-signup-logo" />
              </Link>
          </Row>
          <Form className="form-cookie">
              <span>Dear {details.user.sName},</span>
              <br/>
              <br/>
              <span>Please enter your email address and an authentication link will be emailed to you shortly to register this device for Aletheia research access.</span>
              <br/>
              <br/>
              <span>Note that this requires your browser on this device to have cookies enabled. You will be required to verify each device and browser you use to access our reports.</span>
              <br/>
              <br/>
              <Row>
                <Col xs={12} sm={9}>
                  <Field name="email" type="text" component={FormField} label="Email" validate={loginEmailPolicy} defaultValue={details.user.sEmail} required/>
                </Col>
                <Col xs={12} sm={3}>
                  {hideSubmitButton()}
                </Col>
                <Col xs={12}>
                  {message && <span className="text-danger">{message}</span>}
                </Col>
              </Row>
              <span>OR you can login using your password below if you you prefer.</span>
              <br/>
              <br/>
              <Row>
                <Col xs={12} sm={9}>
                  <Field name="password" type={'password'} component={FormField} label="Password"/>
                </Col>
                <Col xs={12} sm={3}>
                  {hidePasswordButton()}
                </Col>
                <Col xs={12}>
                { passwordMsg && <p className="text-danger pull-left">{passwordMsg}</p> }
                </Col>
              </Row>
              <Row className="forget-password" >
                <a onClick={()=>{window.location.href="/signup"}}>
                    {'Sign Up'}
                </a>
                |
                <a onClick={()=>{sendForgetPasswordRequest(details.user.sEmail)}}>
                    {'Forgot Password?'}
                </a>
              </Row>
              <br/>
              <br/>
              <br/>
          </Form>
        </Box>
      </Col>
  );
};

Component.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  loading: PropTypes.bool,
};

Component.defaultProps = {
  className: null,
  message: null,
  loading: false,
};

export default reduxForm({
  form: 'EmailCookie',  //a unique identifier for this form
  validate,
  enableReinitialize: true,
})(Component);
