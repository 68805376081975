/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 05 Nov 2020 : Nick
    * M-0003109: Various issue with searching reports
**************************************************************************************************************
*/

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as configActions from '../actionCreators/Config';
import * as researchActions from '../actionCreators/Research';
import { ProgressBar } from 'react-bootstrap';

const Component = ({
  list,
  onSelect,
  onSelectValue,
  show,
  showSuggestionBox,
  suggestionBox,
  emptySuggestions,
  suggestions,
}) => {
  const wrapperRef = useRef(null);
  // const [isVisible, setIsVisible] = useState(false);
  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      // setIsVisible(false);
      showSuggestionBox(false);
      emptySuggestions();
    }
  };
  const parseCategories = val => {
    switch (val) {
      case 'analysts':
        return 'Analyst';
      case 'companies':
        return 'Company';
      case 'countries':
        return 'Geography';
      case 'sectors':
        return 'Sector';
      default:
        return '';
    }
  };
  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    if (list) {
      // setIsVisible(true);
      showSuggestionBox(true);
    }
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [list]);
  if (suggestions?.loading) {
    return (
      <section className="aletheia-auto-suggesion hi" id="suggestion-box" ref={wrapperRef}>
        <ProgressBar active style={{ height: '10px' }} now={100} />
      </section>
    );
  }
  if (suggestionBox && show && list && Object.keys(list).length) {
    return (
      <section className="aletheia-auto-suggesion hi" id="suggestion-box" ref={wrapperRef}>
        {Object.keys(list).map((key, i) => (
          <section key={`section${i}_${key}`}>
            {list[key].length > 0 && <h4>{key}</h4>}
            <ul className="aletheia-auto-result">
              {/* //====================M001=====]==================== */}
              {key == 'reports'
                ? list[key].map((item, i) => (
                    <li key={`item_${i}_${item.sName}`}>
                      <a
                        href="/"
                        onClick={e => {
                          e.preventDefault();
                          onSelect(item.sName);
                          onSelectValue(
                            parseCategories(key)
                              ? `${parseCategories(key)}: ${item.sName}`
                              : item.sName,
                            // item,
                            true
                          );
                        }}
                      >
                        {
                          <>
                            <span>{item.sName}</span>
                            <span style={{ float: 'right' }}>{item.date}</span>
                          </>
                        }
                      </a>
                    </li>
                  ))
                : list[key].map((item, i) => (
                    <li key={`item_${i}_${item}`}>
                      <a
                        href="/"
                        onClick={e => {
                          e.preventDefault();
                          onSelect(item);
                          onSelectValue(
                            parseCategories(key) ? `${parseCategories(key)}: ${item}` : item,
                            // item,
                            true
                          );
                        }}
                      >
                        {item}
                      </a>
                    </li>
                  ))}
              {/* //====================M001=====]==================== */}
            </ul>
          </section>
        ))}
      </section>
    );
  }

  return null;
};

Component.propTypes = {
  list: PropTypes.object,
  onSelect: PropTypes.func,
  onSelectValue: PropTypes.func,
  showSuggestionBox: PropTypes.func.isRequired,
  emptySuggestions: PropTypes.func.isRequired,
  show: PropTypes.bool,
  suggestionBox: PropTypes.bool.isRequired,
};

Component.defaultProps = {
  onSelect: () => {},
  onSelectValue: () => {},
  show: false,
  list: {},
};
const mapStateToProps = ({ Config: { suggestionBox }, research: { suggestions } }) => ({
  suggestionBox,
  suggestions,
});

const mapDispatchToProps = dispatch => ({
  showSuggestionBox: bindActionCreators(configActions.showSuggestionBoxDropDown, dispatch),
  emptySuggestions: bindActionCreators(researchActions.emptySuggestions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
