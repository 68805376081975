export const footerLinks = [
  {
    label: 'About Us',
    path: '/about',
  },
  {
    label: 'Team',
    path: '/people',
  },
  {
    label: 'Careers',
    path: '/careers',
  },
  {
    label: 'Regulatory Disclosures',
    path: '/regulatory',
  },
  {
    label: 'Privacy Policy',
    path: '/privacy',
  },
  {
    label: 'Terms of Use',
    path: '/terms',
  },
  {
    label: 'Contact Us',
    path: '/contact',
  },
];

export const footerText =
  'The terms "Aletheia", "Aletheia Research" and "Aletheia Capital" refer to the entities of Aletheia Capital Ltd, a Hongkong incorporated entity. Aletheia Capital’s wholly owned subsidiaries are licensed corporations with the Hong Kong Securities and Futures Commission for Type 4 regulated activities (advising on securities) (CE No. AVD212)';
