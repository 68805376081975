import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import Card from '../../components/Card';
import Box from '../../components/Box';

import { sanitizeText } from '../../utils';

import * as analystActions from '../../actionCreators/Analyst';
import * as sectorActions from '../../actionCreators/Sector';
import * as configActions from '../../actionCreators/Config';

const Container = ({ ourPeople, getOurPeople, getSectors, sectors, getParameter, parameters }) => {
  React.useEffect(() => {
    getParameter('rw_CNTBkTalkToThe,bEnableConnectBookings');
    getOurPeople();
    getSectors();
  }, []);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');

  const [sectorsList, setSectorsList] = useState([]);
  const [selectedSector, setSelectedSector] = useState(0);

  const [catSectors, setCatSectors] = useState({});

  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    extractCategoriesAndSectors();
  }, [ourPeople.data]);

  const extractCategoriesAndSectors = () => {
    if (ourPeople.data?.length > 0) {
      setFilteredResults([...ourPeople.data]);
      let tempCategs = [];
      let tempCatSectors = {};

      // Extracting Categories and Sectors
      ourPeople.data.forEach(p => {
        if (p.sJobCat && p.sJobId) {
          if (tempCategs.findIndex(c => c.id === p.sJobId) < 0) {
            tempCategs.push({ id: p.sJobId, label: p.sJobCat });
          }
        }

        if (p.sectors?.length > 0) {
          if (tempCatSectors[p.sJobId]) {
            tempCatSectors[p.sJobId] = [...tempCatSectors[p.sJobId], ...p.sectors];
          } else {
            tempCatSectors[p.sJobId] = p.sectors;
          }
        }
      });

      Object.keys(tempCatSectors).forEach(k => {
        // Removing Duplicates
        let secList = [...new Set(tempCatSectors[k])];

        // Removing null values
        secList = secList.filter(s => s != null);

        // Adding Labels
        secList = secList.map(s => {
          const sName = sectors.data.find(sec => sec.id === s)?.sName;
          return { id: s, label: sName };
        });

        // Adding All Option
        if (secList.findIndex(sl => sl.id === 0) < 0) {
          secList.push({ id: 0, label: 'All' });
        }

        tempCatSectors[k] = secList;
      });

      setCatSectors(tempCatSectors);

      // Adding 'All' option to categories
      if (tempCategs.findIndex(c => c.id === 0) < 0) {
        tempCategs.push({ id: 0, label: 'All' });
      }
      setCategories(tempCategs);
    }
  };

  const onCategoryClick = cat => {
    let tempFiltered;

    // All category is selected, so remove the filter
    if (cat.id === 0) {
      tempFiltered = [...ourPeople.data];
    } else {
      tempFiltered = ourPeople.data.filter(d => d.sJobId === cat.id);
    }

    setSectorsList(catSectors[cat.id]);

    setSelectedCategory(cat.id);
    setFilteredResults(tempFiltered);
  };

  const onSectorClick = sector => {
    setSelectedSector(sector.id);
    let tempFiltered;

    // When 'All' option is selected
    if (sector.id === 0) {
      tempFiltered = ourPeople.data.filter(d => d.sJobId === selectedCategory);
    } else {
      tempFiltered = ourPeople.data.filter(
        d => d.sJobId === selectedCategory && d.sectors?.includes(sector.id)
      );
    }

    setFilteredResults(tempFiltered);
  };

  return (
    <div className="our-people">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our People</title>
        <meta name="description" content="Team of Aletheia Capital" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Grid>
        <Box className="our-people-heading-bar">
          <h2>
            {' '}
            Meet the <span>team.</span>{' '}
          </h2>
        </Box>

        <div className="categories-div">
          {categories.map((category, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  onCategoryClick(category);
                }}
                className={
                  'single-category' + (category.id === selectedCategory ? ' selected' : '')
                }
              >
                {category.label}
              </div>
            );
          })}
        </div>
        {selectedCategory !== 'All' && (
          <div className="sectors-div">
            {sectorsList?.map((sector, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    onSectorClick(sector);
                  }}
                  className={'single-sector' + (sector.id === selectedSector ? ' selected' : '')}
                >
                  {sector.label}
                </div>
              );
            })}
          </div>
        )}
        <div>
          {ourPeople.loading || !ourPeople.data.length
            ? Array.from({ length: 20 }).map((v, i) => (
                <Card key={`our_people_card_${i}`} loading />
              ))
            : filteredResults
                // .sort((a, b) => a.order - b.order)
                .sort((a, b) => {
                  return (
                    (a.order === null) - (b.order === null) ||
                    +(a.order > b.order) ||
                    -(a.order < b.order)
                  );
                })
                .filter(i => i.is_our_people)
                .map(item => (
                  <Card
                    key={`research_${item.id}`}
                    data={item}
                    type="ourpeople"
                    image={item.image_path}
                    imageTitle={item.name}
                    imageTitleLink={`analysts/${item.id}_${sanitizeText(item.name)}`}
                    imageSubtitle={item.designation}
                    description={item.description}
                    descriptionLength={375}
                    descriptionFontSize="12.5px"
                    showReadMore="block"
                    imageClass="team-member-image img-circle"
                    talkToAnalyst
                    parameters={parameters}
                    AnalystId={item.id}
                    style={{ margin: '5px 0px', borderRadius: '5px' }}
                  />
                ))}
        </div>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({
  analyst: { ourPeople },
  sector: { sectors },
  Config: { parameters },
}) => ({
  ourPeople,
  sectors,
  parameters,
});

const mapDispatchToProps = dispatch => ({
  getOurPeople: bindActionCreators(analystActions.getOurPeople, dispatch),
  getSectors: bindActionCreators(sectorActions.getAll, dispatch),
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
});

Container.propTypes = {
  ourPeople: PropTypes.object.isRequired,

  getOurPeople: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
