import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Stack } from '@mui/material';

import Avatar from '../../../../../assets/images/login-logo-small.png';

import { sanitizeText } from '../../../../../utils';

const Component = ({ analyst, parameters }) => (
  <tr>
    <td rowSpan="6" className="card-avatar-section">
      <div style={{ padding: '0px 0px 5px 0px' }}>
        <div className="">
          <Image
            height="60"
            width="60"
            className="team-member-report-image img-circle"
            src={(analyst && analyst.image_path) || Avatar}
          />
        </div>
        {analyst ? (
          <>
            <Link
              href={`/analysts/${analyst.id}_${sanitizeText(analyst.name)}`}
              to={`/analysts/${analyst.id}_${sanitizeText(analyst.name)}`}
              className="card-title"
            >
              <p className="sub-heading">{analyst.name}</p>
            </Link>
            {parameters?.bEnableConnectBookings == '1' ? (
              <Link
                to={`/connect/appointment/${analyst.id}`}
                href={`/connect/appointment/${analyst.id}`}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  style={{ fontSize: '12px', color: '#00b0f0' }}
                >
                  {parameters?.rw_CNTBkTalkToThe}
                  {/* <ChatBubbleOutlineIcon style={{ marginLeft: '5px' }} fontSize="12px" /> */}
                </Stack>
                {/* <p style={{ fontSize: '12px', color: '#00b0f0' }}>
                </p> */}
              </Link>
            ) : null}
          </>
        ) : (
          <p className="sub-heading">Unknown</p>
        )}
      </div>
    </td>
  </tr>
);

Component.propTypes = {
  analyst: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image_path: PropTypes.string,
  }),
};

Component.defaultProps = {
  analyst: {},
};

export default Component;
