import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as videoActions from '../../../actionCreators/Videos';
import Card from '../../../components/Card';
import { Modal, Row, Col, Grid } from 'react-bootstrap';
import FilterBox from '../../../components/FilterBox';
import { getStandardDateTime } from '../../../utils/dateTime';

const BlogLists = ({ posts }) => {
  const textToHTML = text => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(text, 'text/html');
    return doc.body;
  };

  const getCardImageFromContent = content => {
    var HTML = textToHTML(content);
    try {
      return HTML.getElementsByTagName('img')[0].src;
    } catch (c) {
      return '';
    }
  };

  const getParaFromContent = content => {
    var HTML = textToHTML(content);
    return HTML.innerText;
  };

  const getTimeRead = content => {
    var text = getParaFromContent(content);
    const wordsPerMinute = 300; // Average case.
    let result;
    let textLength = text.split(' ').length;
    if (textLength > 0) {
      let value = Math.ceil(textLength / wordsPerMinute);
      result = `${value} min read`;
    }
    return result;
  };

  const getDateTimeBox = dateTime => {
    var standardDateTime = getStandardDateTime(dateTime, { format: 'D MMM YYYY' });
    var obj = <div style={{ textAlign: 'left', paddingTop: '10px' }}>{standardDateTime}</div>;
    return obj;
  };

  return (
    <Grid className={'blogs-landing'} style={{ paddingLeft: '5%', paddingRight: '5%' }}>
      {/* <FilterBox></FilterBox> */}
      <br />

      {posts.data ? (
        posts.data.posts.filter((item)=>{if (item.publish) {return item}}).map((item, index) => (
          <>
            {item.publish && (
              <Col md={index < 2 ? 6 : 4} style={{ marginBottom: '20px' }}>
                <Card
                  style={{
                    maxHeight: index < 2 ? '500px' : '600px',
                    minHeight: index < 2 ? '500px' : '600px',
                  }}
                  imageLocation="top"
                  image={getCardImageFromContent(item.html)}
                  imageClass="blogs-landing-card-image"
                  title={item.title}
                  descriptionLength="200"
                  description={getParaFromContent(item.html)}
                  descriptionFontSize="14px"
                  titleLink={'/post/' + item.id}
                  readMoreLink={'/post/' + item.id}
                  component={getDateTimeBox(item.created_at)}
                  imageSubtitle={getTimeRead(item.html)}
                  imageTitleLink={'/post/' + item.id}
                />
              </Col>
            )}
          </>
        ))
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default withRouter(connect()(BlogLists));
