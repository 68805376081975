import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import qs from 'querystring';

import FormContainer from '../containers/FormContainer';
import LoginForm from '../pages/Login/components/Login';
import SignupForm from '../pages/Signup/components/SignupForm';
import SignupSuccess from '../pages/Signup/components/SignupSuccess';
import { doPost, doGet } from '../utils/request';

import * as userActions from '../actionCreators/User';
import * as userModalActions from '../actionCreators/UserModal';

const Component = ({
  show,
  email,
  fullName,
  userExists,
  signUp,
  logIn,
  hideModal,
  user,
  history,
}) => {
  const [showSuccessBox, setShowSuccessBox] = React.useState(false);
  React.useEffect(() => {
    if (user.success === true) {
      hideModal();
    }
    if (user && user.signUpSuccess === true) {
      setShowSuccessBox(true);
    }
  }, [user]);
  // const queryParams = qs.parse(history.location.search);
  // const url = queryParams['redirectUrl'];
  // let redirectUrl = null;
  // if (url) {
  //   redirectUrl = `/rfs.htm${url}`;
  // } else {
  //   redirectUrl = `/rfs.htm${history.location.search}`;
  // }
  // const queryParams = qs.parse(history.location.search);
  // const url = queryParams['redirectUrl'];
  // let redirectUrl = null;
  // if (url) {
  //   redirectUrl = `/rfs.htm${url}`;
  // } else {
  //   redirectUrl = `/rfs.htm${history.location.search}`;
  // }
  const { search } = history.location;
  const urlParams = new URLSearchParams(search);
  let externalUrl = urlParams.get('redirectUrl');
  externalUrl = externalUrl ? `/rfs.htm${externalUrl}` : null;
  const redirectUrl =
    history.location.state && history.location.state.redirectUrl
      ? history.location.state && history.location.state.redirectUrl
      : externalUrl;
  const [showSignUp, setshowSignUp] = React.useState(true);

  const checkIfSafariOrFirefox = () => {
    // Detecting Browser by duck typing
    let isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    if (isSafari) {
      if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
        isSafari = false;
      }
    }
    const isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox || isSafari) {
      return true;
    }
    return false;
  };

  return (
    <Modal show={show} className="logoutModal">
      <Modal.Body className="forgot-password-modal">
        <FormContainer>
          {showSignUp ? (
            showSuccessBox ? (
              <SignupSuccess
                backToHome={v => {
                  if (v === 'hide') {
                    // hideHomePageModal();
                  }
                }}
              />
            ) : (
              <SignupForm
                onSubmit={obj => signUp(obj, history)}
                message={user.error}
                initialValues={{
                  email,
                  full_name: fullName,
                }}
                showSignUpMessageForModal
                goTo={value => {
                  if (value === 'signin') {
                    setshowSignUp(false);
                  }
                }}
              />
            )
          ) : (
            <LoginForm
              onSubmit={obj => {
                if (checkIfSafariOrFirefox() && redirectUrl.includes('/reports/')) {
                  const newTab = window.open();
                  doPost('/users/login', obj)
                    .then(() => {
                      const id = redirectUrl.split('/')[2].split('_')[0];
                      doGet(`/reports/${id}`)
                        .then(report => {
                          newTab.location = report.data.report.path;
                        })
                        .catch(() => {
                          newTab.close();
                          history.push(`/reports/${id}`);
                        });
                    })
                    .catch(() => {
                      newTab.close();
                    });
                  logIn(obj, history, '/');
                } else {
                  logIn(obj, history, redirectUrl);
                }
              }}
              message={user.error}
              initialValues={{
                email,
              }}
              showSignUpMessageForModal
              goTo={value => {
                if (value === 'signup') {
                  setshowSignUp(true);
                }
              }}
            />
          )}
        </FormContainer>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ userModal: { show, email, fullName, userExists }, user }) => ({
  show,
  email,
  fullName,
  userExists,
  user,
});

const mapDispatchToProps = dispatch => ({
  signUp: bindActionCreators(userActions.signUp, dispatch),
  logIn: bindActionCreators(userActions.logIn, dispatch),
  hideModal: bindActionCreators(userModalActions.hide, dispatch),
});

Component.propTypes = {
  show: PropTypes.bool.isRequired,
  email: PropTypes.string,
  fullName: PropTypes.string,
  userExists: PropTypes.bool,
  signUp: PropTypes.func.isRequired,
  logIn: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

Component.defaultProps = {
  email: '',
  fullName: '',
  userExists: false,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
