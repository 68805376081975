/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
  M002 : 09 May 2023 : Zeeshan Siddique
	     * M-4372: Public registration to have separate First and Last Name fields instead of single name field and add optional Company Field
  M001 : 3 Dec 2020 : Aman
    * M-0003180: Users with particular email address format cannot register, sign in, or verify email address
**************************************************************************************************************
*/

const BLACKLISTED_DOMAINS = [
  'aol.com',
  'gmail.com',
  'hotmail.com',
  'live.com',
  'outlook.com',
  'yahoo.com',
  'ymail.com',
];

export const email = value =>
  //====================M001==========================
  value && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(value)
    ? 'Please enter a valid email address'
    : undefined;
//====================M001==========================
// export const number = (value) => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
export const emailRequired = value => (value ? undefined : 'Email is required');
export const emailMaxLength = max => value =>
  value && value.length > max ? 'Please enter a valid email' : undefined;
export const emailMaxLength250 = emailMaxLength(250);
export const emailNonCommercial = value => {
  const domain = String(value)
    .toLowerCase()
    .replace(/.*@/, '');

  if (BLACKLISTED_DOMAINS.find(item => item === domain)) {
    return 'Only non-commercial emails are allowed to register';
  }

  return undefined;
};
export const passwordRequired = value => (value ? undefined : 'Password is required');
export const passwordMinLength = min => value =>
  value && value.length < min ? 'Password should be at least 7 characters long' : undefined;
export const passwordMinLength7 = passwordMinLength(7);
export const passwordMaxLength = max => value =>
  value && value.length > max ? 'Please enter a valid password' : undefined;
export const passwordMaxLength25 = passwordMaxLength(25);
export const userNameRequired = value => (value ? undefined : 'Full name is required');
export const userNameMinLength = min => value =>
  value && value.length < min ? 'Please enter a valid full name' : undefined;
export const userNameMinLength2 = userNameMinLength(2);
export const userNameMaxLength = max => value =>
  value && value.length > max ? 'Please enter a valid full name' : undefined;
export const userNameMaxLength50 = userNameMaxLength(50);
export const alphaNumericSpecial = value =>
  value && /[^a-zA-Z0-9 .,'-]/i.test(value) ? value.replace(/[^a-zA-Z0-9 .,'-]/g, '') : value;

export const alphaSpecial = value =>
  value && /[^a-zA-Z ]/i.test(value) ? value.replace(/[^a-zA-Z ]/g, '') : value;

export const numSpecial = value =>
  value && /[^0-9-+()]*/i.test(value) ? value.replace(/[^0-9-+()]*/g, '') : value;

export const acceptTermsRequired = value =>
  value ? undefined : 'You must agree to terms and conditions before signing up';
export const countryRequired = value => (value ? undefined : 'Country name is required');
export const phoneRequired = value => (value ? undefined : 'Phone number is required');
export const investmentRequired = value =>
  value ? undefined : 'You must select investment net worth';
      {/* =====================M001===========[================ */}

  export const firstNameRequired = value => (value ? undefined : 'First name is required');
  export const firstNameMaxLength = max => value =>
  value && value.length > max ? 'Please enter a valid first name' : undefined;
  export const lastNameRequired = value => (value ? undefined : 'Last name is required');
  export const lastNameMaxLength = max => value =>
  value && value.length > max ? 'Please enter a valid last name' : undefined;
      {/* =====================M001===========[================ */}


export const fullNamePolicy = [userNameMinLength2, userNameMaxLength50];
export const emailPolicy = [emailRequired, email, emailMaxLength250];
export const loginEmailPolicy = [emailRequired, email, emailMaxLength250];
export const passwordPolicy = [passwordRequired, passwordMinLength7, passwordMaxLength25];
export const firstNamePolicy = [firstNameRequired]; //M002
export const lastNamePolicy = [lastNameRequired];   //M002


