import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

const Component = ({ text, className, ...props }) => (
  <div className={className} {...props}>
    <table style={{ width: '20%', margin: 'auto' }}>
      <tbody>
        <tr>
          <td>
            <ClipLoader color="#1e6ca6" />
          </td>
          {text && <td>{text}</td>}
        </tr>
      </tbody>
    </table>
  </div>
);

Component.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

Component.defaultProps = {
  text: null,
  className: 'text-center',
};

export default Component;
