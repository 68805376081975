import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AOIInvestor from './components/AOIInvestor';
import AOIAnalyst from './components/AOIAnalyst';
import '../../assets/sass/aletheia-aoi.scss';

import * as userModalActions from '../../actionCreators/UserModal';

const Component = ({ location: { pathname } }) => {
  const type = pathname.split('/')[2];
  if (type=='Investor') {
    return (
      <AOIInvestor></AOIInvestor>
    )
  } else {
    return (
      <AOIAnalyst></AOIAnalyst>
    );
  }
};

const mapDispatchToProps = dispatch => ({
  showUserModal: bindActionCreators(userModalActions.show, dispatch),
});

const mapStateToProps = ({
  research: { research },
  user: {
    currentUser: { data: user },
  },
}) => ({ research, user });

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
