import React from 'react';
import { getStandardDateTime } from '../../../utils/dateTime';

export const PostStatDataLabels = () => [
    {
        width: '20%',
        key: 'sName',
        label: 'Name',
    },
    {
        width: '30%',
        key: 'sCompanyName',
        label: 'Root Company',
    },
    {
      width: '30%',
      key: 'dLogTimeUtc',
      label: 'Date Accessed',
      render: (value, obj) => (
        <>
           <span>{getStandardDateTime(obj.dLogTimeUtc)}</span>
        </>
      ),
    },
    {
      width: '20%',
      key: 'dLogTimeUtc',
      label: 'Time Spent',
      render: (value, obj) => {
        var diffMins = (2.00).toFixed(2);
        if (obj.dExitTimeUtc!=null) {
          var dateTimeIn  = new Date(obj.dLogTimeUtc);
          var dateTimeOut = new Date(obj.dExitTimeUtc);
          diffMins = ((dateTimeOut-dateTimeIn)/1000/60).toFixed(2); // minutes
        }
        return (
          <>
            <span>{diffMins} Mins</span>
          </>
        )
      },
    },
]