import { all, put, takeLatest } from 'redux-saga/effects';

import {
  getAllVideosSuccess,
  getAllVideosFail,
  getMostWatchedSuccess,
  getMostWatchedFail,
  AccessVideoSuccess,
  AccessVideoFail,
  getSavedVideosSuccess,
  getSavedVideosFail,
  SaveVideoSuccess,
  SaveVideoFail,
  setCurrentVideoTimeSuccess,
  setCurrentVideoTimeFail
} from '../actionCreators/Videos';
import { doGet, doPost } from '../utils/request';

function* getVideos({ obj }) {
  try {
    const response = yield doGet(`/videos`);
    yield put(getAllVideosSuccess(response.data, obj));
  } catch (err) {
    yield put(getAllVideosFail(err.message));
  }
}

function* getMostWatched({ obj }) {
  try {
    const response = yield doGet(`/videos/most_watch`);
    yield put(getMostWatchedSuccess(response.data, obj));
  } catch (err) {
    yield put(getMostWatchedFail(err.message));
  }
}

function* getSavedVideos({ obj }) {
  try {
    const response = yield doGet(`/videos/saved`);
    yield put(getSavedVideosSuccess(response.data, obj));
  } catch (err) {
    yield put(getSavedVideosFail(err.message));
  }
}

function* SaveVideo({ obj, id }) {
  try {
    const response = yield doPost(`/videos/save/${id}`);
    yield put(SaveVideoSuccess(response.data, obj));
  } catch (err) {
    yield put(SaveVideoFail(err.message));
  }
}

function* AccessVideo({ obj, id }) {
  try {
    const response = yield doPost(`/videos/${id}`);
    yield put(AccessVideoSuccess(response.data, obj));
  } catch (err) {
    yield put(AccessVideoFail(err.message));
  }
}

function* SetVideoCurrentTime({obj}) {
  try {
    yield put(setCurrentVideoTimeSuccess( obj ));
  } catch (err) {
    yield put(setCurrentVideoTimeFail(err.message));
  }
}

export default function* watcher() {
  yield all([
    takeLatest('GET_ALL_VIDEOS', getVideos),
    takeLatest('GET_MOST_WATCHED', getMostWatched),
    takeLatest('GET_SAVED_VIDEOS', getSavedVideos),
    takeLatest('SAVE_VIDEO', SaveVideo),
    takeLatest('ACCESS_VIDEO', AccessVideo),
    takeLatest('SET_CURRENT_VIDEO_TIME', SetVideoCurrentTime),
  ]);
}
