/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
    M005 : 1 Jun 2021 : Nick
		* M-0003426: Add markets and stocks coverage menu under latest research
    M004 : 4 May 2021 : Nick
		* M-0003384: Website UI Improve - Inner Blog Page
    M003 : 26 February 2021 : Nick
		* M-0003289: Multilevel of menu bar
    M002 : 29 July 2020 : Nicho
		* M-0002943: Homepage - Global header redesign
    M001 : 21 July 2020 : Aman
      * M-0002935: User has to login in twice after clicking on a distribution RFS proxy link.
**************************************************************************************************************
*/

/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'querystring';
import { Nav, NavItem, MenuItem, NavDropdown } from 'react-bootstrap';
import { OnAtLeastDesktop, OnAtMostTablet } from '../../ReactBreak';
import AImodal from '../../../pages/AletheiaAOI/components/AIModal';

import { preloginNavItems, TempUserNavItems } from '../config';

import * as configActions from '../../../actionCreators/Config';

const Component = ({
  className,
  history,
  history: {
    location: { pathname, search },
  },
  currentUser,
  research,
  favourites,
  getFavourites,
  navItems,
  getNavItems,
}) => {
  React.useEffect(() => {
    getNavItems();
    getFavourites();
  }, []);
  const user = currentUser && currentUser.data;
  const navigate = path => history.push(path);
  const [dropDownOpen, setdropDownOpen] = React.useState({ key: null, open: false });
  const [showAImodal, setShowAImodal] = React.useState(false);
  const showActiveNav = !(pathname === '/' && !user);
  const queryParams = qs.parse(search);
  let externalPdfUrl = null;
  let signUpRoute = null;
  if (!user) {
    externalPdfUrl = queryParams['?rfsUrl'];
    signUpRoute = '/signup';
    if (research.data) {
      const { spyderUser, userExists } = research.data;
      if (spyderUser) {
        const {
          email,
          name: { display },
        } = spyderUser;
        //====================M001=====[====================
        spyderUser
          ? (signUpRoute = `/login?email=${email}&redirectUrl=${history.location.search}`)
          : (signUpRoute = `/signup?name=${display}&email=${email}`);
        //====================M001=====[====================
      }
    }
  }

  // Checks if user is on reports page
  const reportPage = history.location.pathname.includes('reports/');

  // checks the browser
  const userAgnt = window.navigator.userAgent;
  const isIE = /MSIE|Trident\//.test(userAgnt);

  // detects the User Scroll and if scrolled , and dropdown is opened its closes it

  window.addEventListener('scroll', () => {
    if (dropDownOpen) {
      setdropDownOpen(false);
    }
  });

  const marketClick = value => {
    sessionStorage.setItem('TagsInput', value);
  };

  //=============M003===========]====================
  function createMultiLeveMenu(item, index, depth) {
    return (
      <>
        <OnAtLeastDesktop>
          <NavDropdown
            bsClass={`multilevel_menu_${depth}`}
            open={true}
            title={item.label}
            onClick={() => {
              marketClick('');
              navigate(item.path);
            }}
            style={{
              position: 'relative',
              zIndex: 999,
            }}
          >
            {item.items.map((item2, index2) =>
              item2.type === 'dropdown' ? (
                <>{createMultiLeveMenu(item2, index2, depth + 1)}</>
              ) : (
                <MenuItem
                  onClick={() => {
                    if (item2.tag) {
                      marketClick(item2.tag);
                    } else {
                      marketClick('');
                    }
                    navigate(item2.path);
                  }}
                  style={{
                    position: 'relative',
                    zIndex: '90000 !important',
                  }}
                >
                  {item2.label}
                </MenuItem>
              )
            )}
          </NavDropdown>
        </OnAtLeastDesktop>
        <OnAtMostTablet>
          <NavDropdown
            bsClass={`multilevel_menu_${depth}`}
            open={true}
            title={item.label}
            onClick={() => {
              marketClick('');
              navigate(item.path);
            }}
            style={{
              position: 'relative',
              zIndex: 999,
            }}
          >
            {item.items.map((item2, index2) => (
              <MenuItem
                onClick={() => {
                  if (item2.tag) {
                    marketClick(item2.tag);
                  } else {
                    marketClick('');
                  }
                  navigate(item2.path);
                }}
                style={{
                  position: 'relative',
                  zIndex: '90000 !important',
                }}
              >
                {item2.label}
              </MenuItem>
            ))}
          </NavDropdown>
        </OnAtMostTablet>
      </>
    );
  }
  //=============M003===========]====================

  if (!navItems) {
    return null;
  }

  return (
    <>
      <AImodal history={history} show={showAImodal} onCancel={setShowAImodal}></AImodal>
      <Nav className={className}>
        {/* THIS IS TO USE SPY PARAMETER TO REPLACE ALL PREVIOUS CONFIG*/}
        {
          navItems.map((item, index) =>
          item.type === 'dropdown' ? (
              <>
                <NavDropdown
                  eventKey={index}
                  open={dropDownOpen.key === index ? true : false}
                  key={`navItem_${index}`}
                  title={item.label}
                  id="basic-nav-dropdown"
                  onClick={() => {
                    marketClick('');
                    navigate(item.path);
                    setdropDownOpen({ key: index, open: true });
                    if (dropDownOpen.open) {
                      setdropDownOpen(false);
                    }
                  }}
                  className={`temp_${item.label}`}
                  style={{
                    position: 'relative',
                    zIndex: 999,
                  }}
                >
                  {item.items.map((item2, index2) =>
                    item2 === '' ? (
                      <MenuItem key={`navItem_${index},${index2}`} divider />
                    ) : item2.type === 'dropdown' ? (
                      <>
                        {createMultiLeveMenu(item2, index2, 0)} {/* M003 */}
                      </>
                    ) : (
                      <>
                        <MenuItem
                          eventKey={`${index},${index2}`}
                          key={`navItem_${index},${index2}`}
                          onClick={() => {
                            marketClick('');
                            setdropDownOpen(false);
                            navigate(item2.path);
                          }}
                          style={{
                            position: 'relative',
                            zIndex: '90000 !important',
                          }}
                        >
                          {item2.label}
                        </MenuItem>
                      </>
                    )
                  )}
                </NavDropdown>
              </>
            ) : //=============M004===========]====================
            item.type && item.type === 'favourites' ? (
              favourites &&
              favourites.length !== 0 && (
                <>
                  <NavDropdown
                    title={item.label}
                    className="favourites-menue"
                    id="favourites-menue"
                    onClick={() => {
                      navigate(item.path);
                      setdropDownOpen({ key: index, open: true });
                      if (dropDownOpen.open) {
                        setdropDownOpen(false);
                      }
                    }}
                  >
                    {favourites.map(
                      item =>
                        item.topic && (
                          <NavItem
                            className="favourites-menue"
                            style={{ position: 'relative' }}
                            onClick={() => history.push(`/blogs/${item.key}`)}
                            active={
                              showActiveNav &&
                              String(item.keyword).toLowerCase() ===
                                String(pathname).toLowerCase()
                            }
                          >
                            {item.topic ? item.topic : item.keyword}
                          </NavItem>
                        )
                    )}
                  </NavDropdown>
                </>
              )
            ) : (
              //=============M004===========]====================
              item && (
                <NavItem
                  key={`navItem_${index}`}
                  eventKey="1"
                  onClick={() => {
                    if (item.type == 'modal') {
                      setShowAImodal(true);
                    } else {
                      marketClick('');
                      navigate(item.path ? item.path : '/');
                    }
                  }}
                  active={
                    showActiveNav && String('/').toLowerCase() === String(pathname).toLowerCase()
                  }
                >
                  {item.label}
                </NavItem>
              )
            )
          )
        }



        {/* THIS IS TO USE SPY PARAMETER TO REPLACE ALL PREVIOUS CONFIG*/}




        
        {reportPage && isIE && (
          <iframe title="fake-iframe" className="frame-fake" src="about:blank" />
        )}
      </Nav>
      {reportPage && isIE && (
        <iframe title="fake-iframe" className="frame-fake" src="about:blank" />
      )}
    </>
  );
};

const mapStateToProps = ({
  user: { currentUser },
  research: { research },
  Config: {
    favourites: {
      data: { favourites },
    },
    navitems: {
      data: { navItems },
    },
  },
}) => ({
  currentUser,
  research,
  favourites,
  navItems,
});
const mapDispatchToProps = dispatch => ({
  getFavourites: bindActionCreators(configActions.getFavourites, dispatch),
  getNavItems: bindActionCreators(configActions.getNavItems, dispatch),
});
Component.propTypes = {
  className: PropTypes.string,
  research: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  currentUser: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  favourites: PropTypes.array
};

Component.defaultProps = {
  className: '',
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
