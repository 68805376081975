/*
**************************************************************************************************************
											                      R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
==============================================================================================================

**************************************************************************************************************
					                                  	Amendments
==============================================================================================================
  M003 : 10 Oct 2022 : Nick
    * M-4210: Fix the campaign approve endpoints
	M002 : 24 Jun 2021 : Aman
    * M-3453: Event registration Waiting for Approval table should not show the columns Approved By and Approved Date
	M001 : 02 Jun 2021 : Nick
    * M-3428: Event registration - enhancement on the approval process
**************************************************************************************************************
*/


import React from 'react';
import { getStandardDateTime } from '../../../utils/dateTime';
import { doPost } from '../../../utils/request';
import Throbber from '../../../components/Throbber';

export const approveTableConfig = (data) => [
  {
    key: '',
    width: '5%',
    render: (value, obj) => (
      <>
        <input className="admin-checkbox" id={obj.iPSN_Id?`S_${obj.iPSN_Id}`:`T_${obj.iTempUserId}`} 
        onClick={()=>{ obj.isChecked = !obj.isChecked }} type="checkbox" value={obj.iPSN_Id?`S_${obj.iPSN_Id}`:`T_${obj.iTempUserId}`} />
      </>
    ),
  },
  {
    key: 'sRegistrantName',
    label: 'Registrant Name',
    width: '40%', //M001
    render: (value, obj) => (
      <>
        <span>
          {value} (<a href={'mailto:' + obj.sEmail}>{obj.sEmail}</a>)
        </span>
      </>
    ),
  },
  {
    key: 'sCompanyName',
    label: 'Company',
  },
  {
    key: 'dUTC_Registered',
    label: 'Registered Time',
    render: (value, obj) => (
      <>
        <span>{getStandardDateTime(obj.dUTC_Registered)}</span>
      </>
    ),
  },
  //=====================M001,M003===========[====================
  {
    key: 'sApprovedByFullName',
    label: 'Approved By',
  },
  {
    key: 'dUTC_Approved',
    label: 'Approved Time',
    render: (value, obj) => (
      <>{obj.dUTC_Approved ? <span>{getStandardDateTime(obj.dUTC_Approved)}</span> : <></>}</>
    ),
  },
  {
    key: 'bSentMeetingLink',
    label: 'Link Sent (Y/N)',
    render: (value, obj) => <>{obj.bSentMeetingLink ? <span>Yes</span> : <span>No</span>}</>,
  },
  {
    key: 'sMeetingLink',
    label: 'Unique Meeting Link',
    render: (value, obj) => (
      <span>
        <a onClick={(evt)=>
          {
            doPost(`/connect/approvalEvent/1/1/uniqueLink`,obj).then(response => {
              navigator.clipboard.writeText(response.data).then(()=>{
                alert("Copied");
              })
            }).catch(e=>{
              alert('Internal Error');
            })
          }
        }>
          Copy Link
        </a>
      </span>
    ),
  },
  //=====================M001,M003===========]====================
];

//=====================M002===========[====================
export const pendingApprovalTableConfig = () => [
  {
    key: '',
    sortable: false,
    width: '5%',
    render: (value, obj) => (
      <>
        <input className="admin-checkbox" type="checkbox" value={obj.iPSN_Id?`S_${obj.iPSN_Id}`:`T_${obj.iTempUserId}`} 
          onClick={()=>{ obj.isChecked = !obj.isChecked }} id={obj.iPSN_Id?`S_${obj.iPSN_Id}`:`T_${obj.iTempUserId}`} />
      </>
    ),
  },
  {
    key: 'sRegistrantName',
    label: 'Registrant Name',
    width: '40%',
    render: (value, obj) => (
      <>
        <span>
          {value} (<a href={'mailto:' + obj.sEmail}>{obj.sEmail}</a>)
        </span>
      </>
    ),
  },
  {
    key: 'sCompanyName',
    label: 'Company',
  },
  {
    key: 'dUTC_Registered',
    label: 'Registered Time',
    render: (value, obj) => (
      <>
        <span>{getStandardDateTime(obj.dUTC_Registered)}</span>
      </>
    ),
  },
];
//=====================M002===========[====================
export const confirmTableConfig = () => [
  {
    key: 'sRegistrantName',
    label: 'Registrant Name',
    render: (value, obj) => (
      <>
        <span>
          {value} (<a href={'mailto:' + obj.sEmail}>{obj.sEmail}</a>)
        </span>
      </>
    ),
  },
  {
    key: 'sCompanyName',
    label: 'Company',
  },
];
