/*
**************************************************************************************************************
												R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	:	Nick Ng
	Date	:	10 Oct 2022
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************
	M006 : 18 Oct 2022 : Nick Ng : Core
		 * M-4172: Fix Must Read and Free Access buttons
  M005 : 10 Oct 2022 : Nick Ng : Core
		 * M-4180: Mirgrate research portal to use public module api and deprecate Node.js
  M004 : 01 Mar 2021 : Nick
    * M-3292: Periodic free access to reports - Website
  M003 : 03 Feb 2021 : Aman
    * M-3220: Allow users to save documents
  M002 : 24 Nov 2020 : Nick
    * M-3042: Email verification and cookie to authenticate users for email based report links
  M001 : 15 Jul 2020 : Aman
    * M-2936: Clicking on RFS proxy link should check to see if recipient has rights to access document.
**************************************************************************************************************
*/

import { all, put, takeLatest, takeEvery, throttle } from 'redux-saga/effects';
import qs from 'querystring';

import {
  getLatestSuccess,
  getLatestFail,
  getHighlightedSuccess,
  getHighlightedFail,
  getResearchSuccess,
  getResearchesSuccess,
  getResearchFail,
  getResearchesFail,
  getAllSuccess,
  getAllFail,
  highlightReportSuccess,
  highlightReportFail,
  tempReportSuccess,
  tempReportFail,
  getTempReportsSuccess,
  getTempReportsFail,
  getSuggestionsSuccess,
  getSuggestionsFailure,
  getRelatedReportsSuccess,
  getRelatedReportsFail,
  getHomePageLatestSuccess,
  getHomePageLatestFail,
  fetchLatestDocSuccess,
  getViewReportCookieSuccess,
  getViewReportCookieFail,
  SaveReportSuccess,
  SaveReportFail,
  GetSavedReportsSuccess,
  GetSavedReportsFail,
  GetBlogReportsSuccess,
  GetBlogReportsFail,
  GetMostViewedReportsSuccess,
  GetMostViewedReportsFail,
  reportManagementGetAllSuccess,
  reportManagementGetAllFail,
} from '../actionCreators/Research';
import { doGet, doPost } from '../utils/request';

//====================M005============[==============
function* getLatestResearches({ obj }) {
  try {
    //Format Start Date and End Date Obj
    let dStart;
    let dEnd;
    if (obj.q && obj.q.length) {
      let q = [...obj.q];
      for (let [index, w] of q.entries()) {
        if (w.indexOf('Date Range: ') === 0) {
          if (dStart && dEnd) {
            obj.q[0] = obj.q[0].replace(w, '');
          } else {
            let dateRange = [];
            dateRange = w.replace('Date Range: ', '').replace(' - ', ',').split(',');
            dStart = dateRange[0];
            dEnd = dateRange[1];
            obj.q[index] = obj.q[index].replace(w, '');
            break;
          }
        }
      }
    }

    if (dStart && dEnd) {
      obj.dStart = dStart;
      obj.dEnd = dEnd;
    }

    const response = yield doGet(`/reports?${qs.stringify(obj)}`);
    yield put(getLatestSuccess(response.data.researches, response.data.pagination, obj));
    const retrieveddata = response.data.researches;
    retrieveddata.map(item => {
      const date = new Date(item.published_date + 'Z');
      item.published_date = date;
    });
    yield put(getLatestSuccess(retrieveddata, response.data.pagination, obj));
  } catch ({ message }) {
    yield put(getLatestFail(message));
  }
}
//====================M005============]==============

function* fetchLatestReport({ obj }) {
  try {
    const response = yield doGet(`/reports?${qs.stringify(obj)}`); //M005
    yield put(fetchLatestDocSuccess(response.data, obj));
    // yield put(getLatestSuccess(response.data.researches, response.data.pagination, obj));
  } catch ({ message }) {
    yield put(fetchLatestDocSuccess(message, obj));
    // yield put(getLatestFail(message));
  }
}

function* getLatestResearchesForHomePage({ obj }) {
  try {
    const response = yield doGet(`/reports?${qs.stringify(obj)}`); //M005
    yield put(getHomePageLatestSuccess(response.data.researches, response.data.pagination, obj));
    const retrieveddata = response.data.researches;
    retrieveddata.map(item => {
      const date = new Date(item.published_date + 'Z');
      item.published_date = date;
    });
    yield put(getHomePageLatestSuccess(retrieveddata, response.data.pagination, obj));
  } catch ({ message }) {
    yield put(getHomePageLatestFail(message));
  }
}

function* getRelatedReports({ obj }) {
  try {
    const response = yield doGet(`/reports?type=related&${qs.stringify(obj)}`); //M005
    yield put(getRelatedReportsSuccess(response.data.researches, response.data.pagination, obj));
    const retrieveddata = response.data.researches;
    retrieveddata.map(item => {
      const date = new Date(item.published_date + 'Z');
      item.published_date = date;
    });
    yield put(getRelatedReportsSuccess(retrieveddata, response.data.pagination, obj));
  } catch ({ message }) {
    yield put(getRelatedReportsFail(message));
  }
}

function* getHighlightedResearches({ obj }) {
  try {
    const response = yield doGet(`/reports?type=highlighted&${qs.stringify(obj)}`); //M005
    yield put(getHighlightedSuccess(response.data.researches, response.data.pagination));
    const retrieveddata = response.data.researches;
    retrieveddata.map(item => {
      const date = new Date(item.published_date + 'Z');
      item.published_date = date;
    });
    yield put(getHighlightedSuccess(retrieveddata, response.data.pagination));
  } catch ({ message }) {
    yield put(getHighlightedFail(message));
  }
}

function* getAllResearches({ obj }) {
  try {
    const response = yield doGet(`/reports?type=all&${qs.stringify(obj)}`); //M005
    yield put(getAllSuccess(response.data.researches, response.data.pagination));
    const retrieveddata = response.data.researches;
    retrieveddata.map(item => {
      const date = new Date(item.published_date + 'Z');
      item.published_date = date;
    });
    yield put(getAllSuccess(retrieveddata, response.data.pagination));
  } catch ({ message }) {
    yield put(getAllFail(message));
  }
}

function* getResearch({ obj }) {
  try {
    const response = yield doGet(
      obj.id
        ? //====================M001,M005=====[====================
          `/report/${obj.id}`
        : `/report?accessKey=${obj.accessKey}`
      //====================M001,M005=====]====================
    );
    yield put(getResearchSuccess(response.data.report, response.data)); //M004
  } catch (err) {
    yield put(
      getResearchFail(
        (err.response.data && err.response.data.message) || err.message,
        err.response.data.report
      )
    );
  }
}

function* getResearches({ obj }) {
  try {
    const response = yield doGet(`/overall/research?${qs.stringify(obj)}`);
    yield put(getResearchesSuccess(response.data));
  } catch (err) {
    yield put(getResearchesFail((err.response.data && err.response.data.message) || err.message));
  }
}
//====================M003=====[====================
function* SaveReport({ obj }) {
  try {
    const response = yield doPost('/reports/save/', obj);
    yield put(SaveReportSuccess(response.data));
  } catch (err) {
    yield put(SaveReportFail(err.message));
  }
}

function* GetSavedReports({ obj }) {
  try {
    const response = yield doGet(`/reports?type=saved`); //M005
    yield put(GetSavedReportsSuccess(response.data));
  } catch (err) {
    yield put(GetSavedReportsFail(err.message));
  }
}

function* GetBlogReports({ obj }) {
  try {
    const response = yield doGet(`/post/associatedResearch/${obj.id}`);
    yield put(GetBlogReportsSuccess(response.data));
  } catch (err) {
    yield put(GetBlogReportsFail((err.response.data && err.response.data.message) || err.message));
  }
}

function* GetMostViewedReports() {
  try {
    const response = yield doGet(`/reports?type=mostviewed`); //M005
    yield put(GetMostViewedReportsSuccess(response.data));
  } catch (err) {
    yield put(
      GetMostViewedReportsFail((err.response.data && err.response.data.message) || err.message)
    );
  }
}
//====================M003=====[====================
function* highlightReport({ obj }) {
  try {
    const id = obj.id.replace('S-', ''); //M006
    const response = yield doPost(`/report/${id}/access/dAcsHighligted`); //M006
    yield put(
      highlightReportSuccess(
        response.data.researches.find(report => report.id === obj.id),
        obj
      )
    );
  } catch (err) {
    yield put(highlightReportFail(err.message));
  }
}

function* tempReport({ obj }) {
  try {
    const id = obj.id.replace('S-', ''); //M006
    const response = yield doPost(`/report/${id}/access/dAcsFree`); //M006
    yield put(
      tempReportSuccess(
        response.data.researches.find(report => report.id === obj.id),
        obj
      )
    );
  } catch (err) {
    yield put(tempReportFail(err.message));
  }
}

function* getTempReports({ obj }) {
  try {
    const response = yield doGet(`/reports?type=temp&${qs.stringify(obj)}`); //M005
    yield put(getTempReportsSuccess(response.data.researches, response.data.pagination, obj));
    const retrieveddata = response.data.researches;
    retrieveddata.map(item => {
      const date = new Date(item.published_date + 'Z');
      item.published_date = date;
    });
    yield put(getTempReportsSuccess(retrieveddata, response.data.pagination, obj));
  } catch ({ message }) {
    yield put(getTempReportsFail(message));
  }
}

function* getSuggestions({ obj }) {
  try {
    const response = yield doGet(`/reports/suggestions?${qs.stringify(obj)}`);
    yield put(getSuggestionsSuccess(response.data));
  } catch (error) {
    yield put(getSuggestionsFailure(error.message));
  }
}

//====================M002=====]====================
function* getViewReportCookies({ obj, link }) {
  try {
    const data = new FormData();
    data.email = obj.email;
    data.link = link;
    const response = yield doPost('/getCookie', { data });
    yield put(getViewReportCookieSuccess(response.data, obj));
  } catch (error) {
    yield put(getViewReportCookieFail(error, obj));
  }
}

function* reportManagementGetAll({ obj }) {
  try {
    const response = yield doGet(`/report/reportManagementAll`);
    yield put(reportManagementGetAllSuccess(response.data.researches, response.data.pagination));
    const retrieveddata = response.data.researches;
    retrieveddata.map(item => {
      const date = new Date(item.published_date + 'Z');
      item.published_date = date;
    });
    yield put(reportManagementGetAllSuccess(retrieveddata, response.data.pagination));
  } catch ({ message }) {
    yield put(reportManagementGetAllFail(message));
  }
}
//====================M002=====]====================

export default function* watcher() {
  yield all([
    takeLatest('GET_LATEST_RESEARCHES', getLatestResearches),
    takeLatest('GET_HOMEPAGE_LATEST_RESEARCHES', getLatestResearchesForHomePage),
    takeLatest('GET_RELATED_RESEARCHES', getRelatedReports),
    takeLatest('GET_HIGHLIGHTED_RESEARCHES', getHighlightedResearches),
    takeLatest('GET_ALL_RESEARCHES', getAllResearches),
    takeLatest('REPORT_MANAGEMENT_GET_ALL_RESEARCHES', reportManagementGetAll),
    takeLatest('GET_RESEARCH', getResearch),
    takeLatest('GET_RESEARCHES', getResearches),
    takeLatest('GET_VIEWREPORTCOOKIE', getViewReportCookies), //M002
    takeEvery('HIGHLIGHT_RESEARCH', highlightReport),
    takeEvery('TEMP_RESEARCH', tempReport),
    takeEvery('GET_TEMP_REPORTS', getTempReports),
    takeEvery('FETCH_LATEST_RESEARCHES', fetchLatestReport),
    takeEvery('SAVE_REPORT', SaveReport),
    takeEvery('GET_SAVED_REPORTS', GetSavedReports),
    takeEvery('GET_BLOG_REPORTS', GetBlogReports),
    takeEvery('GET_MOST_VIEWED_REPORTS', GetMostViewedReports),
    throttle(1000, 'GET_SUGGESTIONS', getSuggestions),
  ]);
}
