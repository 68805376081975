import React from 'react';
import {Row, Col} from 'react-bootstrap';
import AOIForm from './AOIForm';
import { OnAtLeastTablet, OnAtMostMobile  } from '../../../components/ReactBreak';

const AOIInvestor = ({}) => {
    return (
        <>
        {/* INTRO */}
        <Row>
            <Col xs={2}></Col>
            <Col xs={8}>
                <div className='aoi-intro'>
                    <p>
                        <i>Aletheia Capital is Asia's first fully independent content firm built to scale. We are focused
                        purely on providing insightful content and ideas to our clients and are fiercely independent in our views
                        </i>
                    </p>
                </div> 
            </Col>
            <Col xs={2}></Col>
        </Row>
        {/* INTRO */}
        {/* QUESTION */}
            <div className='aoi-question-div-head-tablet'>
                <Row className='aoi-question-head'>
                    <Col xs={2}></Col>
                    <Col xs={8}>
                        <div className='aoi-question-head-sub'>are you an</div>
                        <div>investor?</div>
                    </Col>
                    <Col xs={2}></Col>
                </Row>
                <Row>
                    <Col xs={2}></Col>
                    <Col xs={8}>
                        <div className='aoi-question-body'>
                            <p>Are you looking for something different in your relationship with your research providers?</p>
                            <br></br>
                            <p>Would you like to partner with a team of industry leading, experienced, analysts, strategists and economists 
                                who are singularly focused on alpha generation?
                            </p>
                            <br></br>
                            <p>Do you want complete transparency PLUS flexibility around research packaging and pricing?</p>
                            <br></br>
                            <p>If you answer 'YES' to any of the above, please fill in the attached form and we will contact you 
                                to discuss our full range of research products.
                            </p>
                        </div>
                    </Col>
                    <Col xs={2}></Col>
                </Row>
            </div>
            <div className='aoi-question-div-head-mobile'>
                <Row>
                    <Col xs={2}></Col>
                    <Col xs={4} className='aoi-question-head-mobile'>
                        <div className='aoi-question-head-sub'>are you an</div>
                        <div>investor?</div>
                    </Col>
                    <Col xs={4}>
                        <div className='aoi-question-body'>
                            <p>Are you looking for something different in your relationship with your research providers?</p>
                            <br></br>
                            <p>Would you like to partner with a team of industry leading, experienced, analysts, strategists and economists 
                                who are singularly focused on alpha generation?
                            </p>
                            <br></br>
                            <p>Do you want complete transparency PLUS flexibility around research packaging and pricing?</p>
                            <br></br>
                            <p>If you answer 'YES' to any of the above, please fill in the attached form and we will contact you 
                                to discuss our full range of research products.
                            </p>
                        </div>
                    </Col>
                    <Col xs={2}></Col>
                </Row>
            </div>
        {/* QUESTION */}
        <AOIForm type={'Investor'}></AOIForm>
        </>
    );
};

export default AOIInvestor;
