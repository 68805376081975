/*
**************************************************************************************************************
                        R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
  Author	:	Nick Ng
  Date	:	02 Nov 2022
**************************************************************************************************************
                        Usage Notes
**************************************************************************************************************

**************************************************************************************************************
                        Amendments
**************************************************************************************************************
  M001 : 02 Nov 2022 : Nick Ng : Core
     * M-4240: Incorrect time for CONNECT event registration
**************************************************************************************************************
*/

import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { getStandardDateTime } from '../../../utils/dateTime';

export const signupTableConfig = () => [
  {
    key: '',
    sortable: false,
    width: '5%',
    render: (value, obj) => (
      <>
        <input
          className="admin-checkbox"
          type="checkbox"
          value={obj.iCAE_Id ? obj.iCAE_Id : 0}
          disabled={!!obj.bRegistered}
        />
      </>
    ),
  },
  {
    key: 'sEvtName',
    label: 'Event Name',
    width: '30%',
    render: (value, obj) => {
      return (
        <>
          <Link
            href={`/connect/registration/${obj.iCAE_Id}`}
            to={`/connect/registration/${obj.iCAE_Id}`}
          >
            {value}
          </Link>
        </>
      );
    },
  },
  {
    key: 'sSpeakerName',
    label: 'Speaker',
    render: (value, obj) => {
      let array = obj.speaker
        .filter((ele, ind) => ind === obj.speaker
          .findIndex(elem => elem?.sName === ele?.sName && elem?.id === ele?.id));
      return (
        <>
          <span>
            {[...array].map(val => (
              <>
                <p>
                  {val.sName} {val.sTitle ? `(${val.sTitle})` : ``}
                </p>
              </>
            ))}
          </span>
        </>
      );
    },
  },
  {
    key: 'dLocale_EvtDate',
    label: 'Date',
    render: (value, obj) =>
      obj.iEvtType_PikId === 2118400009 ? (
        <span>Awaiting Confirmation</span>
      ) : (
        <>
          <span>{getStandardDateTime(obj.dLocale_EvtDate, { format: 'DD MMMM (ddd)' })}</span> {/*M001*/}
        </>
      ),
  },
  {
    key: '',
    label: 'Time',
    render: (value, obj) =>
      obj.iEvtType_PikId === 2118400009 ? (
        <span>TBC</span>
      ) : (
        <>
          <span>{`${getStandardDateTime(obj.dLocale_EvtDate, { format: 'HH:mm' })} (${obj.sCity})`}</span> {/*M001*/}
        </>
      ),
  },
];

export const confirmTableConfig = () => [
  {
    key: 'sCmpTitle',
    label: 'Campaign Name',
    width: '30%',
  },
  {
    key: 'sEvtName',
    label: 'Event Name',
    width: '30%',
  },
  {
    key: 'sSpeakerName',
    label: 'Speaker',
    render: (value, obj) => {
      return (
        <>
          <span>
            {obj.speaker.map(val => (
              <>
                <p>
                  {val.sName} {val.sTitle ? `(${val.sTitle})` : ``}
                </p>
              </>
            ))}
          </span>
        </>
      );
    },
  },
  {
    key: 'dLocale_EvtDate',
    label: 'Date',
    render: (value, obj) =>
      obj.iEvtType_PikId === 2118400009 ? (
        <span>Awaiting Confirmation</span>
      ) : (
        <>
          <span>{getStandardDateTime(obj.dLocale_EvtDate, { format: 'DD MMMM (ddd)' })}</span> {/*M001*/}
        </>
      ),
  },
  {
    key: '',
    label: 'Time',
    render: (value, obj) =>
      obj.iEvtType_PikId === 2118400009 ? (
        <span>TBC</span>
      ) : (
        <>
          <span>{`${getStandardDateTime(obj.dLocale_EvtDate, { format: 'HH:mm' })} (${obj.sCity})`}</span> {/*M001*/}
        </>
      ),
  },
];
