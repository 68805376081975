const initialState = {
  loading: false,
  events: [],
  event: null,
  openSlotDetails: [],
  bookOpenSlot: null,
  cancelConnectEvent: null,
};

export default (state = initialState, { type, error, data, obj, index }) => {
  switch (type) {
    case 'GET_EVENTS':
      return {
        ...state,
        loading: true,
      };
    case 'GET_EVENTS_SUCCESS':
      return {
        ...state,
        loading: false,
        events: data,
      };
    case 'GET_EVENTS_FAIL':
      return {
        ...state,
        loading: false,
        events: [],
      };
    case 'GET_EVENT':
      return {
        ...state,
        loading: true,
      };
    case 'GET_EVENT_SUCCESS':
      return {
        ...state,
        loading: false,
        event: data,
      };
    case 'GET_EVENT_FAIL':
      return {
        ...state,
        loading: false,
        event: null,
      };
    case 'GET_OPEN_SLOT_DETAILS':
      return {
        ...state,
        loading: true,
      };
    case 'GET_OPEN_SLOT_DETAILS_SUCCESS':
      return {
        ...state,
        loading: false,
        openSlotDetails: data,
      };
    case 'GET_OPEN_SLOT_DETAILS_FAIL':
      return {
        ...state,
        loading: false,
        openSlotDetails: [],
      };
    case 'BOOK_CONNECT_OPEN_SLOT':
      return {
        ...state,
        loading: true,
      };
    case 'BOOK_CONNECT_OPEN_SLOT_SUCCESS':
      return {
        ...state,
        loading: false,
        bookOpenSlot: data,
      };
    case 'BOOK_CONNECT_OPEN_SLOT_FAIL':
      return {
        ...state,
        loading: false,
        bookOpenSlot: null,
      };
    case 'CANCEL_CONNECT_EVENT_BOOKING':
      return {
        ...state,
        loading: true,
      };
    case 'CANCEL_CONNECT_EVENT_BOOKING_SUCCESS':
      return {
        ...state,
        loading: false,
        cancelConnectEvent: data,
      };
    case 'CANCEL_CONNECT_EVENT_BOOKING_FAIL':
      return {
        ...state,
        loading: false,
        cancelConnectEvent: null,
      };
    default:
      return state;
  }
};
