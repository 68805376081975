/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
  M001 : 24 November 2020 : Nick
    * M-0003042: Email verification and cookie to authenticate users for email based report links\
  M002 : 3rd Feb 2021 : Aman
    * M-0003220: Allow users to save documents
  M003 : 1 mar 2021 : Nick
    * M-0003292: Periodic free access to reports - Website
**************************************************************************************************************
*/

const initialState = {
  filtersState: {},
  latestResearches: {
    loading: false,
    data: [],
    pagination: {},
    filters: {},
    error: null,
  },
  homePageLatestResearches: {
    loading: false,
    data: [],
    pagination: {},
    filters: {},
    error: null,
  },
  relatedResearches: {
    loading: false,
    data: [],
    pagination: {},
    filters: {},
    error: null,
  },
  highlightedResearches: {
    loading: false,
    data: [],
    pagination: {},
    error: null,
  },
  tempResearches: {
    loading: false,
    data: [],
    pagination: {},
    error: null,
  },
  researches: {
    loading: false,
    fetching: false,
    data: [],
    pagination: {},
    error: null,
  },
  research: {
    loading: false,
    data: null,
    message: null, //M003
    error: null,
  },
  suggestions: {
    loading: false,
    data: {},
    error: null,
  },
  //====================M002=====]====================
  savedReports: {
    loading: false,
    data: [],
    error: null,
  },

  BlogReports: {
    loading: false,
    data: [],
    error: null,
  },
  MostViewedReports: {
    loading: false,
    data: [],
    error: null,
  },
  //====================M002=====]====================
  //====================M001=====]====================
  viewCookie: {
    loading: false,
    message: '',
    error: null,
  },
  reportManagementResearches: {
    loading: false,
    data: [],
    pagination: {},
    filters: {},
    error: null,
  },
  //====================M001=====]====================
};

export default (state = initialState, { type, error, payload, data, pagination, obj }) => {
  switch (type) {
    case 'GET_ALL_RESEARCHES':
      return {
        ...state,
        researches: {
          ...state.researches,
          loading: true,
        },
      };

    case 'GET_ALL_RESEARCHES_SUCCESS':
      return {
        ...state,
        researches: {
          ...state.researches,
          loading: false,
          data,
          pagination,
        },
      };

    case 'GET_ALL_RESEARCHES_FAIL':
      return {
        ...state,
        researches: {
          ...state.researches,
          loading: false,
          error,
        },
      };

    case 'GET_LATEST_RESEARCHES':
    case 'GET_TEMP_REPORTS':
      return {
        ...state,
        latestResearches: {
          ...state.latestResearches,
          loading: true,
        },
      };

    case 'GET_LATEST_RESEARCHES_SUCCESS':
    case 'GET_TEMP_REPORTS_SUCCESS':
      return {
        ...state,
        latestResearches: {
          ...state.latestResearches,
          loading: false,
          data,
          pagination,
          filters: {
            ...obj,
          },
        },
      };

    case 'GET_LATEST_RESEARCHES_FAIL':
    case 'GET_TEMP_REPORTS_FAIL':
      return {
        ...state,
        latestResearches: {
          ...state.latestResearches,
          loading: false,
          error,
        },
      };

    case 'GET_HOMEPAGE_LATEST_RESEARCHES':
      return {
        ...state,
        homePageLatestResearches: {
          ...state.homePageLatestResearches,
          loading: true,
        },
      };

    case 'GET_HOMEPAGE_LATEST_RESEARCHES_SUCCESS':
      return {
        ...state,
        homePageLatestResearches: {
          ...state.homePageLatestResearches,
          loading: false,
          data,
          pagination,
          filters: {
            ...obj,
          },
        },
      };

    case 'GET_HOMEPAGE_LATEST_RESEARCHES_FAIL':
      return {
        ...state,
        homePageLatestResearches: {
          ...state.homePageLatestResearches,
          loading: false,
          error,
        },
      };

    case 'GET_RELATED_RESEARCHES':
      return {
        ...state,
        relatedResearches: {
          ...state.relatedResearches,
          loading: true,
        },
      };

    case 'GET_RELATED_RESEARCHES_SUCCESS':
      return {
        ...state,
        relatedResearches: {
          ...state.relatedResearches,
          loading: false,
          data,
          pagination,
          filters: {
            ...obj,
          },
        },
      };

    case 'GET_RELATED_RESEARCHES_FAIL':
      return {
        ...state,
        relatedResearches: {
          ...state.relatedResearches,
          loading: false,
          error,
        },
      };

    case 'GET_HIGHLIGHTED_RESEARCHES':
      return {
        ...state,
        highlightedResearches: {
          ...state.highlightedResearches,
          loading: true,
        },
      };

    case 'GET_HIGHLIGHTED_RESEARCHES_SUCCESS':
      return {
        ...state,
        highlightedResearches: {
          ...state.highlightedResearches,
          loading: false,
          data,
          pagination,
        },
      };

    case 'GET_HIGHLIGHTED_RESEARCHES_FAIL':
      return {
        ...state,
        highlightedResearches: {
          ...state.highlightedResearches,
          loading: false,
          error,
        },
      };

    case 'GET_RESEARCH':
      return {
        ...state,
        research: {
          ...state.research,
          loading: true,
        },
      };

    case 'GET_RESEARCH_SUCCESS':
      return {
        ...state,
        research: {
          ...state.research,
          loading: false,
          data: payload,
          message: obj.message, //M003
          error: null,
        },
      };

    case 'GET_RESEARCH_FAIL':
      return {
        ...state,
        research: {
          ...state.research,
          loading: false,
          data: obj,
          error,
        },
      };

    case 'HIGHLIGHT_RESEARCH':
      return {
        ...state,
        researches: {
          ...state.researches,
          data: state.researches.data.map(item =>
            item.id === obj.id
              ? {
                  ...item,
                  loading: true,
                }
              : item
          ),
        },
      };

    case 'HIGHLIGHT_RESEARCH_SUCCESS':
      return {
        ...state,
        highlightedResearches: {
          ...state.highlightedResearches,
          data:
            obj.type === 'latest'
              ? state.highlightedResearches.data.filter(item => item.id !== obj.id)
              : [data, ...state.highlightedResearches.data].sort(
                  (a, b) => new Date(b.published_date) - new Date(a.published_date)
                ),
        },
        researches: {
          ...state.researches,
          data: state.researches.data.map(item =>
            item.id === obj.id
              ? {
                  ...item,
                  type: obj.type,
                  loading: false,
                }
              : item
          ),
        },
      };

    case 'HIGHLIGHT_RESEARCH_FAIL':
      return {
        ...state,
        researches: {
          ...state.researches,
          data: state.researches.data.map(item =>
            item.id === obj.id
              ? {
                  ...item,
                  loading: false,
                }
              : item
          ),
          error,
        },
      };

    case 'GET_SUGGESTIONS':
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          loading: true,
        },
      };

    case 'SUGGESTIONS_SUCCESS':
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          loading: false,
          data,
        },
      };

    case 'SUGGESTIONS_FAILURE':
      return {
        ...state,
        suggestions: {
          ...state,
          loading: false,
          error,
        },
      };

    case 'EMPTY_SUGGESTIONS':
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          loading: false,
          data: {},
        },
      };

    case 'GET_RESEARCHES':
      return {
        ...state,
        research: {
          ...state.research,
          loading: true,
        },
      };

    case 'GET_RESEARCHES_SUCCESS':
      return {
        ...state,
        research: {
          ...state.research,
          loading: false,
          data: payload,
        },
      };

    case 'GET_RESEARCHES_FAIL':
      return {
        ...state,
        research: {
          ...state.research,
          loading: false,
          error,
        },
      };
    case 'FETCH_LATEST_RESEARCHES':
      return {
        ...state,
        researches: {
          ...state.researches,
          loading: false,
          data: state.researches.data.map(item =>
            item.id === obj.id
              ? {
                  ...item,
                  fetching: true,
                }
              : item
          ),
        },
      };
    case 'FETCH_LATEST_RESEARCHES_SUCCESS':
      return {
        ...state,
        researches: {
          ...state.researches,
          loading: false,
          data: state.researches.data.map(item =>
            item.id === obj.id
              ? {
                  ...item,
                  fetching: false,
                }
              : item
          ),
        },
      };
    case 'APPLIED_FILTERS':
      return {
        ...state,
        filtersState: {
          ...state.filtersState,
          key: obj,
        },
      };

    case 'CLEAR_RESEARCHES':
      return initialState;

    case 'CLEAR_APPLIED_FILTERS':
      return {
        ...state,
        filtersState: {
          ...state.filtersState,
          key: null,
        },
      };

    //====================M001=====1====================
    case 'GET_VIEWREPORTCOOKIE':
      return {
        ...state,
        viewCookie: {
          loading: true,
        },
      };

    case 'GET_VIEWREPORTCOOKIE_SUCCESS':
      return {
        ...state,
        viewCookie: {
          loading: false,
          message: data.message,
        },
      };

    case 'GET_VIEWREPORTCOOKIE_FAIL':
      return {
        ...state,
        viewCookie: {
          loading: false,
          message: data.message,
        },
      };
    //====================M002=====]====================
    case 'GET_SAVED_REPORTS':
      return {
        ...state,
        savedReports: {
          ...state.savedReports,
          loading: true,
        },
      };

    case 'GET_SAVED_REPORTS_SUCCESS':
      return {
        ...state,
        savedReports: {
          ...state.savedReports,
          loading: false,
          data,
        },
      };

    case 'GET_SAVED_REPORTS_FAILURE':
      return {
        ...state,
        savedReports: {
          ...state.savedReports,
          loading: false,
          error,
        },
      };

    case 'GET_BLOG_REPORTS':
      return {
        ...state,
        BlogReports: {
          ...state.BlogReports,
          loading: true,
        },
      };

    case 'GET_BLOG_REPORTS_SUCCESS':
      return {
        ...state,
        BlogReports: {
          ...state.BlogReports,
          loading: false,
          data,
        },
      };

    case 'GET_BLOG_REPORTS_FAILURE':
      return {
        ...state,
        BlogReports: {
          ...state.BlogReports,
          loading: false,
          error,
        },
      };

    case 'GET_MOST_VIEWED_REPORTS':
      return {
        ...state,
        MostViewedReports: {
          ...state.MostViewedReports,
          loading: true,
        },
      };

    case 'GET_MOST_VIEWED_REPORTS_SUCCESS':
      return {
        ...state,
        MostViewedReports: {
          ...state.MostViewedReports,
          loading: false,
          data,
        },
      };

    case 'GET_MOST_VIEWED_REPORTS_FAILURE':
      return {
        ...state,
        MostViewedReports: {
          ...state.MostViewedReports,
          loading: false,
          error,
        },
      };
    //====================M002=====]====================
    //====================M001=====]====================
    case 'REPORT_MANAGEMENT_GET_ALL_RESEARCHES':
      return {
        ...state,
        reportManagementResearches: {
          ...state.reportManagementResearches,
          loading: true,
        },
      };

    case 'REPORT_MANAGEMENT_GET_ALL_RESEARCHES_SUCCESS':
      return {
        ...state,
        reportManagementResearches: {
          ...state.reportManagementResearches,
          loading: false,
          data,
          pagination,
        },
      };

    case 'REPORT_MANAGEMENT_GET_ALL_RESEARCHES_FAIL':
      return {
        ...state,
        reportManagementResearches: {
          ...state.reportManagementResearches,
          loading: false,
          error,
        },
      };
    default:
      return state;
  }
};
