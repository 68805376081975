import { call, all, put, select, takeLatest, takeEvery } from 'redux-saga/effects';

import {
    getAllUsersuccess,
    getAllUsersFail,
    sendNewPasswordSuccess,
    sendNewPasswordFail,
    sendVerifyEmailSuccess,
    sendVerifyEmailFail,
    changeUserStatusSuccess,
    changeUserStatusFail,
    searchSpyderFirmsSuccess,
    searchSpyderFirmsFail,
    insertUserToSpyderSuccess,
    insertUserToSpyderFail
} from '../actionCreators/Admin';
import { doGet, doPost } from '../utils/request';

function* getUsers({usersType,q}) {
  try {
    const response = yield doGet('/admin/users/'+ usersType + '?' + q);
    yield put(getAllUsersuccess(response.data));
  } catch (err) {
    yield put(getAllUsersFail(err.message));
  }
}

function* sendNewPassword({usersType, id, email}) {
  try {
    var postData = {
      email: email
    }
    const response = yield doPost(`/admin/users/${usersType}/${id}/newPassword`, postData);
    yield put(sendNewPasswordSuccess(response.data));
  } catch (err) {
    yield put(sendNewPasswordFail(err.message));
  }
}

function* sendVerifyEmail({usersType, id, email, name}) {
  try {
    var postData = {
      email: email,
      name: name
    }
    const response = yield doPost(`/admin/users/${usersType}/${id}/verifyEmail`, postData);
    yield put(sendVerifyEmailSuccess(response.data));
  } catch (err) {
    yield put(sendVerifyEmailFail(err.message));
  }
}

function* changeUserStatus({usersType, id, email, status}) {
  try {
    var postData = {
      email: email,
      status: status
    }
    const response = yield doPost(`/admin/users/${usersType}/${id}/activateUser`, postData);
    yield put(changeUserStatusSuccess(response.data));
  } catch (err) {
    yield put(changeUserStatusFail(err.message));
  }
}

function* searchSpyderFirms({sMatch}) {
  try {
    const response = yield doGet(`/admin/firms?sMatch=${sMatch}`);
    yield put(searchSpyderFirmsSuccess(response.data));
  } catch (err) {
    yield put(searchSpyderFirmsFail(err.message));
  }
}

function* insertUserToSpyder({usersType, id, obj}) {
  try {
    var postData = obj;
    const response = yield doPost(`/admin/users/${usersType}/${id}`, postData);
    yield put(insertUserToSpyderSuccess(response.data));
  } catch (err) {
    yield put(insertUserToSpyderFail(err.message));
  }
}

export default function* watcher() {
  yield all([
    takeLatest('GET_USERS', getUsers),
    takeLatest('SEND_NEW_PASSWORD', sendNewPassword),
    takeLatest('SEND_VERIFY_EMAIL', sendVerifyEmail),
    takeLatest('CHANGE_USER_STATUS',changeUserStatus),
    takeLatest('SEARCH_SPYDER_FIRMS',searchSpyderFirms),
    takeLatest('INSERT_USER_TO_SPYDER', insertUserToSpyder)
  ]);
}
