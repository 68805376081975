import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Modal, Col, Row, Grid, Image } from 'react-bootstrap';
import Avatar from '../../../assets/images/login-logo-small.png';
import TeamProfileForm from './teamProfileForm';
import Throbber from '../../../components/Throbber';

import * as analystActions from '../../../actionCreators/Analyst';

const Component = ({ show, loading, data, editable, onCancel, updateAnalystProfile }) => {
  const [profilePic, setProfilePic] = React.useState(data && data.image_path);
  const [profilePicBuffer, setProfilePicBuffer] = React.useState(data && data.image_path);
  const [error, setError] = React.useState(false);
  const [validationError, setValidationError] = React.useState(false);
  React.useEffect(() => {
    if (data) {
      setProfilePic(data.image_path);
      setProfilePicBuffer(data.image_path);
    }
  }, [data]);

  const onChangeHandler = event => {
    const file = event.target.files[0];
    if (file.type.match('image/jpeg|image/png')) {
      setValidationError(null);
      let width = '';
      let height = '';

      const img = document.createElement('img');
      img.src = URL.createObjectURL(file);
      // eslint-disable-next-line func-names
      setProfilePic(URL.createObjectURL(file));
      // eslint-disable-next-line func-names
      img.onload = function() {
        // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
        // The natural size is the actual image size regardless of rendering.
        // The 'normal' width/height are for the **rendered** size.

        width = img.naturalWidth || img.width;
        height = img.naturalHeight || img.height;

        // Do something with the width and height
        // Setting the source makes it start downloading and eventually call `onload`

        // warning for low dimension Images Upload
        if (height >= 203 && height <= 210 && (width >= 253 && width <= 253)) {
          setProfilePicBuffer(file);
          setError(null);
        } else {
          setError(true);
        }
      };
    } else {
      setValidationError(true);
    }
  };
  const clearAll = () => {
    setValidationError(null);
    setError(null);
    setProfilePicBuffer(null);
    setProfilePic(data && data.image_path);
  };
  return (
    <Modal show={show} className="container-fake">
      <Modal.Header>
        <Modal.Title>{`${data && data.name}'s Profile`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Throbber loading style={{ position: 'relative', top: '40%' }} />
        ) : (
          <>
            <Grid>
              {editable ? (
                <Row className="show-grid">
                  <Col xs={12} md={4}>
                    <div className="change-photo-div">
                      <Image className="team-profile-image" src={profilePic || Avatar} rounded />
                      <div className="upload-btn-wrapper">
                        <Button className="change-photo-button">Upload Photo</Button>
                        <input type="file" name="myfile" onChange={file => onChangeHandler(file)} />
                      </div>
                    </div>
                    <span className="instruction-class-edit-profile">
                      Instructions : Image should be of '253x203' Resolution{' '}
                    </span>
                  </Col>
                  <Col xs={8} md={8} className="edit-form-column">
                    <h4>Edit Profile</h4>
                    <TeamProfileForm
                      handleSubmit={obj => {
                        obj.id = data && data.id;
                        obj.image_path = profilePicBuffer;
                        updateAnalystProfile(obj);
                      }}
                      className="profile-edit-form"
                      initialValues={{
                        name: data && data.name,
                        designation: data && data.designation,
                        email: data && data.email,
                        phone: data && data.phone,
                        country: data && data.country,
                        image: data && data.image_path,
                        description: data && data.description,
                      }}
                      handleCancel={() => {
                        clearAll();
                        onCancel();
                      }}
                      disableSubmit={error || validationError}
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="show-grid">
                  <Col xs={12} md={4}>
                    <Image
                      className="team-profile-image"
                      src={(data && data.image_path) || Avatar}
                      rounded
                    />
                  </Col>
                  <Col xs={6} md={8} className="card-column">
                    <h4 className="card-title" style={{ fontSize: 15.5 }}>
                      {data && data.name}
                    </h4>
                    <h4 className="sector-title" style={{ fontSize: 13.5, fontStyle: 'normal' }}>
                      {data && data.designation}
                    </h4>
                    <h4 style={{ fontSize: 12.5 }}>Email: {data && data.email}</h4>
                    <h4 style={{ fontSize: 12.5 }}>Phone: {data && data.phone}</h4>
                    <h4 style={{ fontSize: 12.5 }}>Sector: {data && data.sector}</h4>
                    <h4 style={{ fontSize: 12.5 }}>Country: {data && data.country}</h4>
                  </Col>
                </Row>
              )}
            </Grid>
            <div className="edit-profile-error-box">
              {error && (
                <div style={{ color: 'red', fontSize: 12 }}>
                  {' '}
                  Image should be of '253x203' Resolution{' '}
                </div>
              )}
              {validationError && (
                <div style={{ color: 'red', fontSize: 12 }}>
                  {' '}
                  Image should be in PNG or JPEG format only.{' '}
                </div>
              )}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!editable && (
          <Button
            onClick={() => {
              clearAll();
              onCancel();
            }}
            disabled={loading}
          >
            CLOSE
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = ({
  analyst: {
    ourPeople: { loading },
  },
}) => ({ loading });

const mapDispatchToProps = dispatch => ({
  updateAnalystProfile: bindActionCreators(analystActions.updateAnalyst, dispatch),
});

Component.propTypes = {
  data: PropTypes.object,
  ourPeople: PropTypes.object,
  show: PropTypes.bool,
  updateAnalystProfile: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  editable: PropTypes.bool,
};

Component.defaultProps = {
  show: false,
  loading: false,
  data: null,
  ourPeople: null,
  editable: false,
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
